import React, { ReactElement } from 'react';
import { Table } from 'antd';
import { BUDGET_FIELDS_CONFIG } from '../Agreements.constants';

const TaxTab = (props: any): ReactElement => {  
  const { data, editHandler, removehandler } = props;

return (
    <Table
        dataSource={data}
        columns={BUDGET_FIELDS_CONFIG(editHandler, removehandler)}
        rowKey={'id'}
        className="agreementsTable"
        pagination={false}
      />
    )
}

export default TaxTab;