import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { sendAcceptStart, sendAcceptSucces, sendAcceptError, 
          getAcceptStart, getAcceptSucces, getAcceptError,
          getGroupedAcceptStart, getGroupedSucces, getGroupedError,
          getAcceptAgreementsStart, getAcceptAgreementsSuccess, getAcceptAgreementsError,
          sendAcceptAgreementsStart, sendAcceptAgreementsSuccess, sendAcceptAgreementsError } from './AcceptSlice';

function* getAccept (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'AcceptActions',
          payload: action.payload
        });
      yield put(getAcceptSucces(response))
  } catch (error) {
      console.log(error)
      yield put(getAcceptError(error))
  }
}

function* getGroupedAccept (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'AcceptActionsGrouped',
          payload: action.payload
        });
      yield put(getGroupedSucces(response))
  } catch (error) {
      console.log(error)
      yield put(getGroupedError(error))
  }
}

function* sendAccept (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
            apiMethod: 'AcceptActions',
            payload: action.payload
          });
        yield put(sendAcceptSucces(response))
        if (action.payload.actionType === 2 || action.payload.actionType === 3) yield put(getAcceptStart({actionType: action.payload.actionType}));
        if (action.payload.actionType === 1 || action.payload.actionType === 4) yield put(getGroupedAcceptStart({actionType: action.payload.actionType}));
    } catch (error) {
        console.log(error)
        yield put(sendAcceptError(error))
    }
}


function* getAcceptAgreements (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'AcceptAgreements',
          payload: action.payload
        });
      yield put(getAcceptAgreementsSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(getAcceptAgreementsError(error))
  }
}

function* sendAcceptAgreements (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
            apiMethod: 'AcceptAgreements',
            payload: action.payload.accept
          });
        yield put(sendAcceptAgreementsSuccess(response))
        yield put(getAcceptAgreementsStart(action.payload.fetch));
    } catch (error) {
        console.log(error)
        yield put(sendAcceptAgreementsError(error))
    }
}


function* sendAcceptWatcher () {
    yield takeLatest(sendAcceptStart.type, sendAccept)
}

function* getAcceptWatcher () {
  yield takeLatest(getAcceptStart.type, getAccept)
}

function* getGroupedAcceptWatcher () {
  yield takeLatest(getGroupedAcceptStart.type, getGroupedAccept)
}

function* getAcceptAgreementsWatcher () {
  yield takeLatest(getAcceptAgreementsStart.type, getAcceptAgreements)
}

function* sendAcceptAgreementsWatcher () {
  yield takeLatest(sendAcceptAgreementsStart.type, sendAcceptAgreements)
}


export const AcceptSagas = [fork(sendAcceptWatcher), fork(getAcceptWatcher), fork(getGroupedAcceptWatcher), fork(getAcceptAgreementsWatcher), fork(sendAcceptAgreementsWatcher)];
//не забываем добавлять сагу в рут- сагу