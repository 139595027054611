import React, { useState, useEffect } from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { Table, Checkbox } from 'antd';
import { useHistory } from "react-router";
import { DeleteOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Notification from '../../components/Notification';
import './licensingHistory.css';
import { teamsSelector, getUsersStart } from "../../store/appSlice";
import { getLicenseHistoryStart, historyDataSelector } from "../../pages/Licensing/redux/LicensingSlice";
import { errorSelector, deleteLicensingItemStart, updateLicensingItemStart, closeModalSlice, sendedSuccessSelector  } from './redux/LicensingHistorySlice';

const LicensingHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const error = useSelector(errorSelector);
  const teams = useSelector(teamsSelector) || [];
  const data = useSelector(historyDataSelector) || [];
  const sendedSuccess = useSelector(sendedSuccessSelector);

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  
  useEffect(()=>{
    dispatch(getLicenseHistoryStart());
  }, [dispatch])

  const editHandler = (row: any) => {
    setEditedRow(row);
    setShowEditModal(true);
  }

  const removehandler = (row: any) => {
    setDeletedId(row.id);
    setShowDeleteModal(true);
  }

  const goBackHandler = () => {
    history.push("/licensing");
  }

  


  //Обработчики данных для таблицы
  const formattedData = data.map((el: any) => ({
    ...el,
    owner: el?.teamName || teams.find((element: any) => element.id === el.ownerId)?.teamName || 'Админ'}))

  const LICENSING_HISTORY_MODAL_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'roundNumber',
      title: 'Раунд',
    },
    {
      dataIndex: 'name',
      title: 'Решение'
    },
    { 
      dataIndex: 'owner',
      title: 'Инициатор'
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <>
          <Button variant="outline-info" onClick={() => editHandler(row)} title="Редактировать">
            <EditOutlined className="licensingHistory--icons"/>
          </Button>
          <Button variant="outline-info" onClick={() => removehandler(row)} title="Удалить">
            <DeleteOutlined className="licensingHistory--icons"/>
          </Button>
        </>)},
  ]

  const SUB_TABLE_FIELDS_CONFIG = [
    {
      dataIndex: 'actionId',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'userId',
      title: 'Команда',
      render: (_: any, row: any) => (<p>{teams.find((el: any) => el.id === row.userId).teamName}</p>)
    },
    {
      dataIndex: 'percent',
      title: 'Процент',
    },
    {
      dataIndex: 'price',
      title: 'Цена заявки',
    }
  ]


  //Обработчики для модалки удаления
  const [deletedId, setDeletedId] = useState<null | number>(null);

  const hideDeleteModal = () => {
    setDeletedId(null);
    setShowDeleteModal(false);
  }

  const onOkDeleteModal = () => {
    dispatch(deleteLicensingItemStart({id: deletedId}))
    setDeletedId(null);
    setShowDeleteModal(false);
  }


  //Обработчики для модалки редактирования
  const [editedRow, setEditedRow] = useState<any>(null);
  const [fullPrice, setFullPrice] = useState(0);
  const [startGRR, setStartGRR] = useState(false);

  const hideEditModal = () => {
    setEditedRow(null);
    setFullPrice(0);
    setShowEditModal(false);
    setStartGRR(false);
  }

  useEffect(()=> {
    if (sendedSuccess) {
      setEditedRow(null);
      setShowEditModal(false);
      dispatch(closeModalSlice());
    }
  }, [sendedSuccess])

  useEffect(()=>{
    if (showEditModal && editedRow) {
      let full = 0;
      editedRow.items.forEach((el: any) => full += el.price);
      setFullPrice(full)
      setStartGRR(editedRow.startGrrNow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditModal])

  const onOkEditModal = () => {
    const payload = {
      id: editedRow.id,
      assetId: editedRow.assetId,
      items: editedRow.items,
      startGrrNow: startGRR
    }
    dispatch(updateLicensingItemStart(payload))
    //setEditedRow(null);
    //setShowEditModal(false);
  }

  const fullPriceHandler = (e: React.BaseSyntheticEvent) => {
    setFullPrice(+e.target.value)
    const result = cloneDeep(editedRow);
    result.items.forEach((el: any) => {el.price = +e.target.value*el.percent/100});
    setEditedRow(result);
  }

  const checkboxHandler = (e: CheckboxChangeEvent) => {
    setStartGRR(e.target.checked);
    const result = cloneDeep(editedRow);
    result.startGrrNow = e.target.checked;
    setEditedRow(result);
}

  const percentChangeHandler = (row: any) => {
    return function (e: React.BaseSyntheticEvent) {
      const result = cloneDeep(editedRow)
      const item = result.items.find((el: any) => el.userId === row.userId);
      if (item) {
        item.percent = +e.target.value;
        item.price = +e.target.value*fullPrice/100
      }
      setEditedRow(result);
  }}

  return (
    <div className="licensingHistory">
      <div className='licensingHistory--back'>
        <Button variant="outline-secondary" onClick={goBackHandler}>
          <RollbackOutlined  className="licensingHistory--icons"/>
          Назад
        </Button>
      </div>
      <Table 
        dataSource={formattedData} 
        columns={LICENSING_HISTORY_MODAL_FIELDS_CONFIG} 
        rowKey='id'
        className='LicensingHistoryModalTable'
        pagination={false}
        expandable={{
        expandedRowRender: record => 
          <Table 
            dataSource={record.items} 
            columns={SUB_TABLE_FIELDS_CONFIG} 
            rowKey='userId'
            className='LicensingHistoryModalSubTable'
            pagination={false}
          />,
          //rowExpandable: record => record.name !== 'Not Expandable',
          }}
      />
      

      <Modal show={showDeleteModal} onHide={hideDeleteModal} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Подтвердите</Modal.Title>
        </Modal.Header>
        <Modal.Body>Подтвердите удаление</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkDeleteModal}>
            Подтвердить
          </Button>
          <Button variant="secondary" onClick={hideDeleteModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={hideEditModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <p>Цена заявки, млн.$</p>
            </Col>
            <Col xs={5}>
              <Form.Control 
                onChange={fullPriceHandler}
                type='number'
                value={fullPrice}
              />
            </Col>
            <Col xs={4}>
                <Checkbox onChange={checkboxHandler} checked={startGRR}>
                  Начать ГРР сразу
                </Checkbox>
              </Col>
          </Row>
          {editedRow && 
            <Row>
              <Col xs={4}>
                Команда
              </Col>
              <Col xs={4}>
                Процент
              </Col>
              <Col xs={4}>
                Стоимость
              </Col>
            </Row>
          }
          {editedRow && editedRow.items.map((el:any)=> (
            <Row key={el.userId}>
              <Col xs={4}>
                <Form.Control 
                  onChange={()=>{}}
                  type='text'
                  disabled={true}
                  value={teams.find((item: any) => item.id === el.userId)?.teamName || ""}
                />
              </Col>
              <Col xs={4}>
                <Form.Control 
                  onChange={percentChangeHandler(el)}
                  type='number'
                  disabled={editedRow.items.length === 1}
                  value={el.percent}
                />
              </Col>
              <Col xs={4}>
                <Form.Control 
                  onChange={()=>{}}
                  type='number'
                  disabled={true}
                  value={fullPrice*el.percent/100}
                />
              </Col>
            </Row>
          ))
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkEditModal}>
            Сохранить
          </Button>
          <Button variant="secondary" onClick={hideEditModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Notification success={false} error={error} />
    </div>
  );
};

export default LicensingHistory;
