import React from "react";
import { Alert, Col, Form as BootstrapForm, Row } from "react-bootstrap";
import styled from "styled-components";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
      return { hasError: true }
    }
  
    componentDidCatch(error, errorInfo) {
      // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
      //logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // Можно отрендерить запасной UI произвольного вида
        return (
            <StyledDiv>
              <BootstrapForm.Group as={Row}>
                <Col>
                  <Alert variant="danger">{`Что-то сломалось. Напишите разработчикам.`}</Alert>
                </Col>
              </BootstrapForm.Group>
            </StyledDiv>
          );
      }
  
      return this.props.children 
    }
  }

  const StyledDiv = styled.div`
    margin-top: 1rem;
    text-align: center;
`;
