import React, { ReactElement } from "react";
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router";

export default function MiningHistoryPanel (props: any): ReactElement {
  const history = useHistory();

  const goToHistoryHandler = () => {
    history.push("/mining_history");
  }

  return (
    <>
        <Button variant="primary" style={{backgroundColor: '#5F8AB6', border: 'none'}} onClick={goToHistoryHandler}>
          Принятые решения
        </Button>
      </>
  );
}