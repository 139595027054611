import { Table } from 'antd';
import React, { ReactElement, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ColumnsType } from 'antd/es/table';
import { IData } from '../Users.interfaces';
import UserEditModal from './UserEditModal';

const UsersTable = (data: any): ReactElement => {

  const [showModal, setShowModal] = useState(false);
  const [editedRow, setEditedRow] = useState<any>(null);

  const editHandler = (row: any) => {
    setShowModal(true);
    setEditedRow(row)
  }
  const closeModal = () => {
    setShowModal(false);
    setEditedRow(null);
  }

  const USERS_FIELDS_CONFIG: ColumnsType<IData> = [
    {
      dataIndex: "id",
      title: "id",
      className: 'hiddenCol'
    },
    {
      dataIndex: "teamName",
      title: "Отображаемое имя",
    },
    {
      dataIndex: "userName",
      title: "Имя авторизации",
    },
    /*{
      dataIndex: "password",
      title: "Пароль",
    },
    {
      dataIndex: "type",
      title: 'роль'
    },*/
    {
      dataIndex: '',
      title: '',
      render: (_: any, row: any) => (
        <>
        <Button variant="outline-info" onClick={() => editHandler(row)}>
          Редактировать
        </Button>{' '}
        {/*<Button variant="outline-warning" onClick={() => removeHandler(row)}>
          Удалить
        </Button>*/}
        </>),
    }
  ]

return (
  <>
    <Table 
        dataSource={data.data} 
        columns={USERS_FIELDS_CONFIG} 
        rowKey='id'
        className='usersTable'
        pagination={false}
    />
    {/*<Row className="usersTable--addButton">
      <Col xs={2}>
        <Button variant="outline-success" onClick={removeHandler}>
          Добавить
        </Button>
      </Col>
    </Row>*/}
    <UserEditModal showModal={showModal} closeModal={closeModal} row={editedRow} />
  </>
)}

export default UsersTable;