import React from "react";
import { IData } from "./GRR.interfaces";
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const GRR_ITEM_KEY: string = "id";

export const GRR_STORE_PATH: string = "grr";

export function Grr_Fields_Config ({teams, user}: any): ColumnsType<IData>  {
  return ([
  {
    dataIndex: "id",
    title: "ID",
    className: "hiddenCol"
  },
  {
    dataIndex: "name",
    title: "Актив",
    align: "center",
  },
  {
    dataIndex: "product",
    title: "Тип УВ",
    align: "center",
  },
  {
    dataIndex: "astage",
    title: "Стадия актива",
    align: "center",
  },
  {
    dataIndex: "oper",
    title: "Оператор",
    align: "center",
    render: (_: any, row: any) => (
      +row.ownerId === user.teamId ? 
      <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p> 
      :
      <div className="d-flex">
        <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p>
        <Tooltip placement="right" title='Принимать решения по данному активу может только его оператор' >
          <QuestionCircleOutlined />
        </Tooltip>
        </div>
      )
  },
  {
    dataIndex: "ur",
    title: "Диапазон ресурсов, млн. тнэ.",
    align: "center",
  },
  {
    dataIndex: "edc",
    title: "Затраты ГРР",
    align: "center",
    className: "th--mlnd"
  },
  {
    dataIndex: "semv",
    title: "EMV",
    align: "center",
    className: "th--mlnd"
  },
  {
    dataIndex: "lc",
    title: "Стоимость лицензии",
    align: "center",
    className: "th--mlnd"
  },
  {
    dataIndex: "ly",
    title: "Год приобретения лицензии",
    align: "center",
  },
  {
    dataIndex: "as",
    title: "Доля владения",
    align: "center",
    className: "th--percent"
  },
])};

