import { Table } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { Grr_Fields_Config, GRR_ITEM_KEY } from '../GRR.constants';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { teamsSelector, getUsersStart, userSelector } from "../../../store/appSlice";

const GRRTable = (props: any): ReactElement => {
  const dispatch = useDispatch();

  const { data, selectedRowKeys, setSelectedRowKeys } = props;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const element = data.find((el: any) => el.id === newSelectedRowKeys[0])
    if (element.ownerId === user.teamId) setSelectedRowKeys(newSelectedRowKeys);
  };

  const teams = useSelector(teamsSelector);
  const user = useSelector(userSelector);

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    type: 'radio' as 'radio', //это связано с плохой типизацией antd - это странно выглядит но работает
    onChange: onSelectChange,
  };

return (
  <>
    <Table 
        dataSource={data} 
        columns={Grr_Fields_Config({teams, user})} 
        rowKey={GRR_ITEM_KEY}
        className='GRRTable'
        size='middle'
        rowSelection={rowSelection}
        rowClassName={record => record.ownerId !== user.teamId ? "grr-disabled-row" : ""}
        onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                const element = data.find((el: any) => el.id === record.id)
                if (element.ownerId === user.teamId) setSelectedRowKeys([record.id])
              }, 
            };
          }}
    />
  </>
)}

export default GRRTable;