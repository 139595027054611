import React, {useEffect} from "react";
import { notification } from 'antd';
import { isEmpty } from "lodash";
import "./notification.css";

  export default function Notification(props: any) {

    const [api, contextNotification] = notification.useNotification();
    const {success, error} = props;

    const openNotification = () => {
        api.open({
        message: success ? 'Успешно' : 'Ошибка',
        description: success ? 'Выполнено' : error ? error : 'Не выполнено' ,
        placement: 'bottomRight',
        //duration: 0,
        style: {
            width: 300,
            backgroundColor: success ? '#91fac6' : '#f8cfcf',
            borderRadius: '10px'
        },
        });
    };

    useEffect(()=>{
        if (success || !isEmpty(error)) openNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, error])

  return (
    <>
        {contextNotification}
    </>
  );
}