import { countries } from "../../constants";
import { sortBy } from 'lodash';

const getCountryName = (id: number) => {
    return countries.find((el: any) => el.id === id)?.name || ''
  }

const taxesTypes = [
' ',
'Эксп. пошлина (нефть, ДНС), Гардарика',
'НДПИ (нефть, ДНС), Гардарика',
'Эксп. пошлина (нефть, НДД), Гардарика',
'НДД (нефть, НДД), Гардарика',
'НДПИ (нефть, НДД), Гардарика',
'Экспортная пошлина (газ, ДНС), Гардарика',
'НДПИ (газ, ДНС), Гардарика',
'Налог на имущество, Гардарика',
'НДД, Валгалла',
'Отчисления в пользу государства, Ассирия',
'Отчисления в пользу государства, Парфия',
'Роялти, Парфия',
'Отчисления в пользу государства, Шривиджайя',
'Роялти, Шривиджайя',
];

export function dataFormatter (data: any) {
    if (data && data?.production) {
      data.production.forEach((el: any) => el.regionName = getCountryName(el.regionId));
      data.production = sortBy(data.production, ['regionId', 'year'])
    }
    if (data && data?.capex) {
      data.capex.forEach((el: any) => el.regionName = getCountryName(el.regionId));
      data.capex = sortBy(data.capex, ['regionId', 'year'])
    }
    if (data && data?.revenue) {
      data.revenue.forEach((el: any) => el.regionName = getCountryName(el.regionId));
      data.revenue = sortBy(data.revenue, ['regionId', 'year'])
    }
    if (data && data?.ebit) {
      data.ebit.forEach((el: any) => el.regionName = getCountryName(el.regionId));
      data.ebit = sortBy(data.ebit, ['regionId', 'year'])
    }
    if (data && data?.taxes) {
      data.taxes.forEach((el: any) => el.regionName = getCountryName(el.regionId));
      data.taxes = sortBy(data.taxes, ['regionId', 'year'])
    }
    if (data && data?.finReg) {
      data.finReg.forEach((el: any) => el.regionName = getCountryName(el.regionId));
      data.finReg = sortBy(data.finReg, ['regionId', 'year'])
    }
    if (data && data?.cO2) {
      data.cO2.forEach((el: any) => el.regionName = getCountryName(el.regionId));
      data.cO2 = sortBy(data.cO2, ['regionId', 'year'])
    }

    if (data && data?.regTaxes) {
      data.regTaxes.forEach((el: any) => el.name = taxesTypes[el.itemType]);
      data.regTaxes = sortBy(data.regTaxes, ['regionId', 'year'])
    }
    
    return data
}
  