import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../constants";
import { get } from "lodash";

export const APP_STORE_PATH = "app";

const now = new Date();

const menuData = {
    user: [
      {
        menuid: 5001,
        menuname: "Пользовательские"
      },
    ],
    admin: [
     {
        menuid: 5002,
        menuname: "Админские"
      },
    ]
};

const appSlice = createSlice({
    name: 'app',
    initialState: {
        user: null,
        menuData: [],
        error: null,
        status: APP_STATE.IDLE,
        timerTimeStamp: now.setSeconds(now.getSeconds() + 0),
        teams: []
    } as any,
    reducers: {
        appInitStart (state) {
          const role = localStorage.getItem('sozvezdie_game_role');
          const user = JSON.parse(localStorage.getItem('sozvezdie_game_user') as string);
          return {...state, 
            error: null,
            menuData: menuData[role as 'user' | 'admin'],
            user: user,
            }
        },

        loginStart (state) {
          return {...state, error: null}
        },
        loginSuccess (state, action) {
          return {...state, error: null,  
            user: action.payload.result.user, 
            menuData: menuData[action.payload.result.role as 'user' | 'admin'], 
          }
        },
        loginError (state, action) {
          return {...state, status: APP_STATE.IDLE, 
            error: 
                action.payload.errors ? action.payload.errors.toString() : 
                action.payload ? action.payload : 
                'Ошибка сервера'}
        },


        logoutStart (state) {
          return {...state, error: null, user: null, menuData: null}
        },


        getUsersStart (state) {
          return {...state, error: null}
        },
        getUsersSuccess (state, action) {
            return {...state, teams: action.payload, error: null}
        },
        getUsersError (state, action) {
          return {...state, teams: action.payload, error: 'Ошибка загрузки данных'}
        },


        getStatusStart (state) {
          return {...state, error: null}
        },
        getStatusRoundSuccess (state, action) {
            return {...state, timerTimeStamp: action.payload.timer, round: action.payload.roundNumber, year: action.payload.year, error: null}
        },
        getStatusNoRoundSuccess (state) {
          return {...state, timerTimeStamp: now.setSeconds(now.getSeconds() + 0), round: null, year: null}
        },
        getStatusError (state, action) {
          return {...state, timerTimeStamp: now.setSeconds(now.getSeconds() + 0), teams: action.payload, error: 'Ошибка загрузки данных'}
      }
    }
})

export const {  appInitStart, getUsersStart, getUsersSuccess, getUsersError, 
                loginStart, loginSuccess, loginError, logoutStart,
                getStatusStart, getStatusRoundSuccess, getStatusNoRoundSuccess,  getStatusError } = appSlice.actions;
export default appSlice.reducer;

export function errorSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.error`);
}

export function statusSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.status`);
}

export function userSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.user`);
}

export function timerSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.timerTimeStamp`);
}

export function roundSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.round`);
}

export function yearSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.year`);
}

export function menuDataSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.menuData`, []);
}

export function teamsSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.teams`, []);
}

export function tokenSelector(state: any) {
  return get(state, `${APP_STORE_PATH}.token`, []);
}