import React, { ReactElement } from "react";
import { Row, Col } from 'react-bootstrap';
import Loader from "../../components/Loader";
//import PageHeader from "../../components/PageHeader";
import LicensingTable from "./components/LicensingTable";
import LicensingHeader from './components/LicensingHeader';
import LicensingActionPanel from './components/LicensingActionPanel';
import './components/licensing.css'
import LicenseTypePanel from "../../components/LicenseTypePanel/LicenseTypePanel";
import LicensingHistoryPanel from "./components/LicensingHistoryPanel";
import Notification from "../../components/Notification";

export default function View(props: any): ReactElement {
  const {
    isLoading,
    data,
    selectedRowKeys, 
    setSelectedRowKeys,
    error,
    setFieldType,
    setRegionId,
    //editorRoute,
  } = props;

  return (
    <>
      <LicensingHeader 
        setRegionId={setRegionId}
        setFieldType={setFieldType}
      />
      
      {/* TODO: move to separate component? */}
      <div className="assets-content">
        <Row className="licensing--header__stageBlock">
          <Col xs={9}>
            <Row className="justify-content-between align-items-center">
              <Col xs={6}>
                <h4>Выберите лицензию для участия в торгах</h4>
              </Col>
              <Col xs={2}>
                <LicenseTypePanel setFieldType={setFieldType} />
              </Col>
              <Col xs={3} className="licensing--header__historyButton">
                <LicensingHistoryPanel />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm={9}>
            <LicensingTable  
              data={data}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              />
          </Col>
          <Col sm={3}>
            <LicensingActionPanel 
              selectedRowKeys={selectedRowKeys}
              tableData={data}
            />
          </Col>
        </Row>
      </div>

      <Loader isLoading={isLoading} />
      <Notification success={false} error={error} />
    </>
  );
}
