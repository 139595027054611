import HTTPAsync from "./HTTPAsync";
//({ apiMethod, payload }: { apiMethod: any; payload: any; }) => Promise<any>

// Обертка для HTTPAsync. Формирует url и body из метода API и переданного объекта.
const HTTPAsyncAPIMethodWrapper = {
  post: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    const { requestUrl, requestBody } = getRequestConfigByMethod({
      apiMethod,
      payload
    });
    return HTTPAsync.post(requestUrl, requestBody);
  },
  get: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    const { requestUrl, requestBody } = getRequestConfigByMethod({
      apiMethod,
      payload
    });

    return HTTPAsync.get(requestUrl, requestBody);
  },

  getById: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
    const requestUrl = payload?.id ? `${backAddress}${apiMethod}/${payload.id}` : `${backAddress}${apiMethod}`

    return HTTPAsync.get(requestUrl, {});
  },
  
  put: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
    const { requestBody }: {requestBody: any} = getRequestConfigByMethod({
      apiMethod,
      payload
    });
    const requestUrl = `${backAddress}${apiMethod}/${payload.id}` //тут id перенесется из боди запроса в квери- параметры
    delete requestBody.id;
    return HTTPAsync.put(requestUrl, requestBody);
  },

  delete: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
    const requestUrl = `${backAddress}${apiMethod}/${payload.id}`
    return HTTPAsync.delete(requestUrl);
  },

  login: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    //const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
    const { requestUrl, requestBody } = getRequestConfigByMethod({
      apiMethod,
      payload
    });
    return HTTPAsync.login(requestUrl, requestBody);
  },

  filesend: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
      const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
      const requestUrl = `${backAddress}${apiMethod}`;
      const files = payload.files;
      const formData = new FormData();
      [...files].forEach(el => formData.append('file', el))
      if (payload?.data?.userId) formData.append('userId', payload.data.userId)
      if (payload?.data?.reportType) formData.append('reportType', payload.data.reportType)
    return HTTPAsync.file_send(requestUrl, formData);
  },

  fileget: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
    let requestUrl = `${backAddress}${apiMethod}`;
    if (payload?.id) requestUrl += `/${payload.id}`;
    if (payload?.reportType) requestUrl += `?reportType=${payload.reportType}`;
    return HTTPAsync.file_get(requestUrl);
  },

  fileview: ({ apiMethod, payload }: { apiMethod: string; payload: any; }) => {
    const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
    let requestUrl = `${backAddress}${apiMethod}`;
    if (payload?.id) requestUrl += `/${payload.id}`;
    if (payload?.reportType) requestUrl += `?reportType=${payload.reportType}`;
    return HTTPAsync.file_view(requestUrl);
  },

};

export default HTTPAsyncAPIMethodWrapper;

// Функция формирующая ссылку из метода API и payload объекта и ссылку для отправки на бек
function getRequestConfigByMethod({ apiMethod, payload = {} }: { apiMethod: string; payload: any; }) {
  const backAddress = localStorage.getItem('GPN_GAME_BACK_ADDRESS')
  const requestUrl = `${backAddress}${apiMethod}`
  const requestBody = { ...payload };

  return { requestUrl, requestBody };
}