import { sortBy } from 'lodash';

const productionTypes = ['', 'Нефть', 'Газ'];
const capexTypes = ['', 'Инвестиции в добычу', 'Инвестиции в улучшения', 'Инвестиции в диверсификацию']

export function dataFormatter (data: any) {
    if (data && data?.production) {
      data.production.forEach((el: any) => el.name = productionTypes[el.itemType]);
      data.production = sortBy(data.production, ['regionId', 'year'])
    }

    if (data && data?.fcf) {
      data.fcf.forEach((el: any) => el.name = ' ');
      data.fcf = sortBy(data.fcf, ['regionId', 'year'])
    }
    
    if (data && data?.capex) {
      data.capex.forEach((el: any) => el.name = capexTypes[el.itemType]);
      data.capex = sortBy(data.capex, ['regionId', 'year'])
    }

    if (data && (data?.reservesoil || data?.reservesoil === 0) && (data?.reservesgas || data?.reservesgas === 0)) { 
      data.donut1 = [
        {name: 'Нефть', value: data.reservesoil},
        {name: 'Газ', value: data.reservesgas}
      ]
    }

    return data
}
  