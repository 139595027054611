import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Form as BootstrapForm } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loginStart, userSelector, errorSelector } from "../../store/appSlice";
/*import {
  appActionCreators,
  APP_STORE_PATH,
  errorSelector,
  isLoadingSelectorFactory,
  userSelector
} from "../../store/appReducer";*/
import "./Login.css";

export default function Login(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const user = useSelector(userSelector);
  const [showAlert, setShowAlert] = useState(false);
  //const isLoading = useSelector(isLoadingSelectorFactory(APP_STORE_PATH));

  const from = get(props, "location.state.from.pathname", "/");

  // После появления userData происходит переход на предыдущую страницу или на главную
  useEffect(() => {
    if (user) {
      history.push(from);
    }
  }, [user, history, from]);

  useEffect(()=>{
    if (!isEmpty(error) && !showAlert) {
        setShowAlert(true);
        setTimeout(()=>setShowAlert(false), 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  
  function onSubmit(values) {
    dispatch(loginStart(values));
  }

  return (
    <Container className="h-100 loginPage__container">
        <Card className="loginPage__card">
          <Card.Header as="h5">Авторизация</Card.Header>
          <Card.Body>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="loginPage__form">
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Имя</BootstrapForm.Label>
                    <Field
                      name="userName"
                      type="text"
                      //disabled={isLoading}
                      component={InputField}
                      placeholder="Введите имя"
                    />
                  </BootstrapForm.Group>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Пароль</BootstrapForm.Label>
                    <Field
                      name="password"
                      type="password"
                      //disabled={isLoading}
                      component={InputField}
                      placeholder="Введите пароль"
                    />
                  </BootstrapForm.Group>
                  <Button /*disabled={isLoading}*/ variant="primary" type="submit">
                    Войти
                  </Button>
                </form>
              )}
            />
            {/*error && (
              <Alert className="loginPage__errorAlert" variant="danger">
                {error}
              </Alert>
            )*/}
          </Card.Body>
        </Card>
        {(showAlert && !isEmpty(error)) &&
        <Alert key={0} variant='warning' className="alert-block">
            {error}
        </Alert>}
    </Container>
  );
}

function InputField(props) {
  const { input, type, placeholder, disabled } = props;

  return (
    <BootstrapForm.Control
      disabled={disabled}
      type={type}
      placeholder={disabled ? "*****" : placeholder}
      {...input}
    />
  );
}
