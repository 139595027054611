import React, { useState, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router';
import { DeleteOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';
import Notification from '../../components/Notification';
import { actionTypes } from '../../constants';
import './technologiesHistory.css';
import { IData } from './TechnologiesHistory.interfaces';
import { teamsSelector, getUsersStart, userSelector } from '../../store/appSlice';
import {
  getTechnologiesHistoryStart,
  historyTechnologiesSelector,
  errorSelector,
  deleteTechnologiesItemStart,
  updateTechnologiesItemStart,
  sendedSuccessSelector,
  closeModalSlice
} from './redux/TechnologiesHistorySlice';

const TechnologiesHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const error = useSelector(errorSelector);

  const teams = useSelector(teamsSelector);
  const data = useSelector(historyTechnologiesSelector);

  useEffect(() => {
    if (isEmpty(teams)) dispatch(getUsersStart());
  }, [dispatch, teams]);

  useEffect(() => {
    dispatch(getTechnologiesHistoryStart());
  }, [dispatch]);


  const removehandler = (row: any) => {
    setDeletedId(row.id);
    setShowDeleteModal(true);
  };

  const goBackHandler = () => {
    history.push('/Technology');
  };

  //Обработчики данных для таблицы
  const formattedData = data.map((el: any) => ({
    ...el,
    owner: el?.teamName || teams.find((element: any) => element.id === el.ownerId)?.teamName || 'Админ',
  }));

  const TECHNOLOGY_HISTORY_FIELDS_CONFIG: ColumnsType<IData> = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol',
    },
    {
      dataIndex: 'roundNumber',
      title: 'Раунд',
    },
    {
      dataIndex: 'name',
      title: 'Решение',
    },
    {
      dataIndex: 'owner',
      title: 'Инициатор',
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <>
          {row.actionType !== actionTypes.StartDevelopment && (
            <Button variant="outline-info" onClick={() => editHandler(row)} title="Редактировать">
              <EditOutlined className="licensingHistory--icons" />
            </Button>
          )}
          <Button variant="outline-info" onClick={() => removehandler(row)} title="Удалить">
            <DeleteOutlined className="licensingHistory--icons" />
          </Button>
        </>
      ),
    },
  ];

  /*const TECHNOLOGY_HISTORY_YEARS_MODAL_FIELDS_CONFIG: ColumnsType<IData> = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol',
    },
    {
      dataIndex: 'year',
      title: 'Год',
    },
    {
      dataIndex: 'amount',
      title: 'Инвестированно',
    },
  ];*/

  const TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG: ColumnsType<IData> = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol',
    },
    {
      dataIndex: 'userId',
      title: 'Команда',
      render: (_: any, row: any) => (
        <>
          <p>{teams.find((el: any) => el.id === row.userId)?.teamName || ''}</p>
        </>
      ),
    },
    {
      dataIndex: 'percent',
      title: 'Процент',
    },
  ];

  //Обработчики для модалки удаления
  const [deletedId, setDeletedId] = useState<null | number>(null);

  const hideDeleteModal = () => {
    setDeletedId(null);
    setShowDeleteModal(false);
  };

  const onOkDeleteModal = () => {
    dispatch(deleteTechnologiesItemStart({ id: deletedId }));
    setDeletedId(null);
    setShowDeleteModal(false);
  };

  //Обработчики для модалки редактирования
  const [activeTab, setActiveTab] = useState("1");
  const [selectedTech, setSelectedTech] = useState<any>(null);

  const user = useSelector(userSelector);
  const sendedSuccess = useSelector(sendedSuccessSelector);
  const myUserId = user?.id || 0;

  const [comandsData, setComandsData] = useState<any[]>([]);

  const [actionType, setActionType] = useState(0);
  const [percentValue, setPercentValue] = useState<number | string>('');
  const [myPercentValue, setMyPercentValue] = useState<number | string>('');
  const [selectedComandId, setSelectedComandId] = useState(-1);
  const [yearStartValue, setYearStartValue] = useState<number | string>('');
  const [yearEndValue, setYearEndValue] = useState<number | string>('');
  const [priceValue, setPriceValue] = useState<number | string>('');

  const editHandler = (row: any) => {
    setActiveTab(row.teamItems.length > 1 ? '2' : '1');
    const {minYear, maxYear} = getYears(row.yearItems);
    setYearStartValue(minYear);
    setYearEndValue(maxYear);
    setPriceValue(row.yearItems[0].amount)
    setActionType(row.actionType)
    setMyPercentValue(row.teamItems.find((el: any) => el.userId === user.id)?.percent || '');
    setComandsData(row.teamItems
        .filter((el: any)=> el.userId !== user.id)
        .map((el: any, i: number) => ({id: i, userId: el.userId, percent: el.percent, name: teams.find((item: any) => item.id === el.userId)!.teamName})))
    setSelectedTech(row)
    setShowEditModal(true);
  };

useEffect(()=> {
  if (sendedSuccess) {
    setShowEditModal(false);
    dispatch(closeModalSlice());
  }
}, [sendedSuccess])

const getYears = (years: any) => {
    let minYear = years[0].year;
    let maxYear = years[0].year;
    years.forEach((el: any) => {
      if (el.year < minYear) minYear = el.year;
      if (el.year > maxYear) maxYear = el.year;
    })
    return ({minYear, maxYear})
}

const requestData = useMemo(()=> {
    const teamItems = activeTab === '1' ? [{"userId": user?.id,"percent": 100}] : [{"userId": user?.id,"percent": myPercentValue}, ...comandsData.map((el: any) => ({"userId": el.userId, "percent": el.percent}))]
    let years = [];
        for (let i = yearStartValue as number; i <= yearEndValue; i++) { 
          years.push(i);
        };
    const yearItems = years.map((el: any) => ({"year": el,"amount": priceValue}));
    return {
      id: selectedTech?.id,
      teamItems,
      yearItems,
      actionType
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, activeTab, comandsData, myPercentValue, selectedTech, yearStartValue, yearEndValue])

  const usedComands = useMemo(()=> {
    return [myUserId, ...comandsData.map(el => el.userId)]
  }, [comandsData, myUserId])

  const sendTechnologiesHandler = () => {
    dispatch(updateTechnologiesItemStart(requestData));
    //setShowEditModal(false);
  }

  const addComandHandler = () => {
    const newItem = {
      id: isEmpty(comandsData) ? 0 : comandsData[comandsData.length - 1].id +1,
      userId: selectedComandId,
      name: teams.find((el: any) => el.id === selectedComandId)!.teamName,
      percent: percentValue,
    }
    setSelectedComandId(-1);
    //setPercentValue(100 - percentValue);
    setComandsData([...comandsData, newItem])
  }

  const removeComandhandler = (row: any) => {
    const newComands = comandsData.filter(el => el.id !== row.id);
    setComandsData(newComands);
  }

  /*const removeYearhandler = (row: any) => {
    //const newYears = yearsData.filter(el => el.id !== row.id);
    //setYearsData(newYears);
  }*/

  /*const addYearHandler = () => {
    const newItem = {
      id: isEmpty(yearsData) ? 0 : yearsData[yearsData.length - 1].id +1,
      year: yearValue,
      price: priceValue,
    }
    setSelectedComandId(-1);
    //setPercentValue(100 - percentValue);
    setYearsData([...yearsData, newItem])
  }*/

  /*const TECHNOLOGIES_MODAL_YEARS_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'year',
      title: 'Год',
      className: 'centeredCol'
    },
    {
      dataIndex: 'price',
      title: 'Инвестирование, млн.$',
      className: 'centeredCol'
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <Button variant="info" onClick={() => removeYearhandler(row)}>
          Удалить
        </Button>)},
    
  ];*/

  const TECHNOLOGIES_MODAL_COMANDS_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'name',
      title: 'Команда'
    },
    { 
      dataIndex: 'percent',
      title: 'Доля участия, %'
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <Button variant="info" onClick={() => removeComandhandler(row)}>
          Удалить
        </Button>)},
    
  ];

const changeComandSelectHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedComandId(+e.target.value)
  }
const changeYearStartHandler = (e: React.BaseSyntheticEvent) => {
    setYearStartValue(+e.target.value)
  }
const changeYearEndHandler = (e: React.BaseSyntheticEvent) => {
    setYearEndValue(+e.target.value)
  }
const changePriceHandler = (e: React.BaseSyntheticEvent) => {
    setPriceValue(+e.target.value)
  }
const changePercentHandler = (e: React.BaseSyntheticEvent) => {
    setPercentValue(+e.target.value)
  }
const changeMyPercentHandler = (e: React.BaseSyntheticEvent) => {
    setMyPercentValue(+e.target.value)
  }

  return (
    <div className="licensingHistory">
      <div className="technologiesHistory--back">
        <Button variant="outline-secondary" onClick={goBackHandler}>
          <RollbackOutlined className="licensingHistory--icons" />
          Назад
        </Button>
      </div>
      <Table
        dataSource={formattedData}
        columns={TECHNOLOGY_HISTORY_FIELDS_CONFIG}
        rowKey="id"
        className="LicensingHistoryModalTable"
        pagination={false}
        expandable={{
          expandedRowRender: (record) => {
            const {minYear, maxYear} = getYears(record.yearItems);
            return (
            <>
              <Row>
                <Col xs={6}>
                  <Row>
                <Col xs={4}>
                    <p>Год начала</p>
                    <Form.Control
                      onChange={()=>{}}
                      disabled
                      //onBlur={singlePriceValidator}
                      value={minYear}
                      type="number"
                    />
                  </Col>
                  <Col xs={4}>
                    <p>Год окончания</p>
                    <Form.Control
                      onChange={()=>{}}
                      disabled
                      //onBlur={singlePriceValidator}
                      value={maxYear}
                      type="number"
                    />
                  </Col>
                  <Col xs={4}>
                    <p>Инвестированно ежегодно</p>
                    <Form.Control
                      onChange={()=>{}}
                      disabled
                      //onBlur={singlePriceValidator}
                      value={record.yearItems[0].amount}
                      type="number"
                    />
                  </Col>
                  </Row>
                  {/*<Table
                    dataSource={record.yearItems}
                    columns={TECHNOLOGY_HISTORY_YEARS_MODAL_FIELDS_CONFIG}
                    rowKey="year"
                    className="LicensingHistoryModalTable"
                    pagination={false}
                  />*/}
                </Col>
                <Col xs={6}>
                  <Table
                    dataSource={record.teamItems}
                    columns={TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG}
                    rowKey="userId"
                    className="LicensingHistoryModalTable"
                    pagination={false}
                  />
                </Col>
              </Row>
            </>
          )},
          //rowExpandable: record => record.actionType !== actionTypes.StartDevelopment,
        }}
      />

      <Modal show={showDeleteModal} onHide={hideDeleteModal} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Подтвердите</Modal.Title>
        </Modal.Header>
        <Modal.Body>Подтвердите удаление</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkDeleteModal}>
            Подтвердить
          </Button>
          <Button variant="secondary" onClick={hideDeleteModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={()=>setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Редактирование</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => {
              setActiveTab(key);
            }}
          >
            <Tabs.TabPane tab="Индивидуальная заявка" key="1">
              <Row className="licensing--actionModal--title">
                <Row>
                  <Col xs={12}>
                    <p>Название технологии</p>
                    <Form.Control value={selectedTech?.name || ''} disabled={true} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <p>Год начала</p>
                    <Form.Control
                      onChange={changeYearStartHandler}
                      //onBlur={singlePriceValidator}
                      value={yearStartValue}
                      type="number"
                    />
                  </Col>
                  <Col xs={4}>
                    <p>Год окончания</p>
                    <Form.Control
                      onChange={changeYearEndHandler}
                      //onBlur={singlePriceValidator}
                      value={yearEndValue}
                      type="number"
                    />
                  </Col>
                  <Col xs={4}>
                    <p>Инвестирование, млн.$</p>
                    <Form.Control
                      onChange={changePriceHandler}
                      //onBlur={singlePriceValidator}
                      value={priceValue}
                      type="number"
                    />
                  </Col>
                  {/*<Col xs={3}>
                    <Button
                      variant="success"
                      onClick={addYearHandler}
                      disabled={!yearValue || !priceValue}
                      className="technology--modal__addButton"
                    >
                      Добавить
                    </Button>
                  </Col>*/}
                </Row>
              </Row>
              {/*<Table
                dataSource={yearsData}
                columns={TECHNOLOGIES_MODAL_YEARS_FIELDS_CONFIG}
                rowKey="id"
                size="small"
                className="technologiesModalTable"
                pagination={false}
              />*/}
            </Tabs.TabPane>

            <Tabs.TabPane tab="Коллективная заявка" key="2">
              <Row className="licensing--actionModal--title">
                <Row>
                  <Col xs={12}>
                    <p>Название технологии</p>
                    <Form.Control value={selectedTech?.name || ''} disabled={true} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <p>Год начала</p>
                    <Form.Control
                      onChange={changeYearStartHandler}
                      //onBlur={singlePriceValidator}
                      value={yearStartValue}
                      type="number"
                    />
                  </Col>
                  <Col xs={4}>
                    <p>Год окончания</p>
                    <Form.Control
                      onChange={changeYearEndHandler}
                      //onBlur={singlePriceValidator}
                      value={yearEndValue}
                      type="number"
                    />
                  </Col>
                  <Col xs={4}>
                    <p>Инвестирование, млн.$</p>
                    <Form.Control
                      onChange={changePriceHandler}
                      //onBlur={singlePriceValidator}
                      value={priceValue}
                      type="number"
                    />
                  </Col>
                  {/*<Col xs={3}>
                    <Button
                      variant="success"
                      onClick={addYearHandler}
                      disabled={!yearValue || !priceValue}
                      className="technology--modal__addButton"
                    >
                      Добавить
                    </Button>
                  </Col>*/}
                </Row>
                {/*<Table
                  dataSource={yearsData}
                  columns={TECHNOLOGIES_MODAL_YEARS_FIELDS_CONFIG}
                  rowKey="id"
                  size="small"
                  className="technologiesModalTable"
                  pagination={false}
                />*/}
              </Row>
              <Row className="licensing--actionModal--myTeam">
                <Col xs={3}>
                  <p className="licensing--actionModal--myTeam__title">Моя команда</p>
                </Col>
                <Col xs={3}>
                  <p>Доля участия, %</p>
                  <Form.Control
                    onChange={changeMyPercentHandler}
                    //onBlur={myPercentValidator}
                    min={1}
                    max={100}
                    value={myPercentValue}
                    type="number"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <p>Команда</p>
                  <Form.Select value={selectedComandId} onChange={changeComandSelectHandler}>
                    <option value={-1} key={-1}>
                      Выберите
                    </option>
                    {teams.map((el: any) => (
                      <option value={el.id} key={el.id} disabled={usedComands.includes(el.id)}>
                        {el.teamName}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col xs={3}>
                  <p>Доля участия, %</p>
                  <Form.Control
                    onChange={changePercentHandler}
                    //onBlur={percentValidator}
                    min={1}
                    max={100}
                    value={percentValue}
                    type="number"
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    variant="success"
                    onClick={addComandHandler}
                    disabled={!percentValue || selectedComandId < 0}
                    className="technology--modal__addButton"
                  >
                    Добавить команду
                  </Button>
                </Col>
              </Row>
              <Row>
                <Table
                  dataSource={comandsData}
                  columns={TECHNOLOGIES_MODAL_COMANDS_FIELDS_CONFIG}
                  rowKey="id"
                  className="technologiesModalTable"
                  pagination={false}
                  size="small"
                />
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={sendTechnologiesHandler}
          >
            Сохранить
          </Button>
          <Button variant="secondary" onClick={()=>setShowEditModal(false)}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Notification success={false} error={error} />
    </div>
  );
};

export default TechnologiesHistory;
