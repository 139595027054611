
import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const USERS_STORE_PATH = 'users';

const UsersSlice = createSlice({
    name: USERS_STORE_PATH,
    initialState: {
        error: null,
        status: APP_STATE.IDLE
    },
    reducers: {
        updateUsersStart (store, action) {
            return {...store, error: null}
        },
        updateUsersSuccess (store, action) {
            return {...store, data: action.payload, error: null}
        },
        updateUsersError (store, action) {
            return {...store, 
            error: 
                    action.payload.errors ? action.payload.errors.toString() : 
                    action.payload ? action.payload : 
                    'Ошибка сервера'}
        }
    }
})

export const { updateUsersStart, updateUsersSuccess, updateUsersError } = UsersSlice.actions;
export default UsersSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${USERS_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

/*export function dataSelector(state) {
    return get(state, `${USERS_STORE_PATH}.data`) || [];
}*/

export function errorSelector(state: any) {
    return get(state, `${USERS_STORE_PATH}.error`);
}