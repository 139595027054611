import React, { useState, useEffect, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRouterProps } from "../../constants/interfaces";
import {
  fetchAgreementsStart,
  fetchAgreementsParamsStart,
  dataSelector,
  errorSelector,
  isLoadingSelector,
  paramsSelector
} from "./redux/AgreementsSlice";

import View from "./Agreements.view";

export default function AgreementsList(routerProps: IRouterProps): ReactElement {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [activeTab, setActiveTab] = useState('1');
  const [regionId, setRegionId] = useState(0);
  const [fieldType, setFieldType] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editedRow, setEditedRow] = useState(null)

  const isLoading : boolean = useSelector(isLoadingSelector);
  const error : boolean = useSelector(errorSelector);
  const data = useSelector(dataSelector);
  const params = useSelector(paramsSelector);

  const fetchDataParams = {regionId, type: +activeTab};
  useEffect(() => {
    //dispatch(fetchAgreementsStart({regionId, fieldType}));
    dispatch(fetchAgreementsStart(fetchDataParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, regionId, fieldType, activeTab]);


  function getAgreementsParams () {
    dispatch(fetchAgreementsParamsStart({type: +activeTab}));
  }

  const editHandler = (row: any) => {
    setEditedRow(row);
    setShowEditModal(true);
  }

  const removehandler = (row: any) => {
    setEditedRow(row);
    setShowDeleteModal(true);
  }

  const closeModalHandler = () => {
    setEditedRow(null);
    if (showEditModal) setShowEditModal(false);
    if (showDeleteModal) setShowDeleteModal(false);
  }

  return (
    <View
      {...routerProps}
      data={data}
      isLoading={isLoading}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      error={error}
      setFieldType={setFieldType}
      setRegionId={setRegionId}
      activeTab={+activeTab}
      setActiveTab={setActiveTab}
      getAgreementsParams={getAgreementsParams}
      params={params}
      fetchDataParams={fetchDataParams}
      editHandler={editHandler}
      removehandler={removehandler}
      closeModalHandler={closeModalHandler}
      editedRow={editedRow}

      showEditModal={showEditModal}
      setShowEditModal={setShowEditModal}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
    />
  );
}
