import { Table } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { LICENSING_FIELDS_CONFIG, LICENSING_ITEM_KEY } from '../Licensing.constants';
import { teamsSelector, getUsersStart, userSelector } from "../../../store/appSlice";


function LicensingTable ({data, selectedRowKeys, setSelectedRowKeys}: any): ReactElement  {
  const dispatch = useDispatch();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const element = data.find((el: any) => el.id === newSelectedRowKeys[0])
    if (element.ownerId === user.teamId || !element.ownerId) setSelectedRowKeys(newSelectedRowKeys);
  };

  const teams = useSelector(teamsSelector);
  const user = useSelector(userSelector);

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    type: 'radio' as 'radio', //это связано с плохой типизацией antd - это странно выглядит но работает
    onChange: onSelectChange,
  };

return (
  <>
    <Table 
        dataSource={data} 
        columns={LICENSING_FIELDS_CONFIG({teams, user})} 
        rowKey={LICENSING_ITEM_KEY}
        className='licensingTable'
        rowSelection={rowSelection}
        rowClassName={record => (record.ownerId === user.teamId || !record.ownerId) ? "" : "licensing-disabled-row"}
        size='middle'
        onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                const element = data.find((el: any) => el.id === record.id)
                if (element.ownerId === user.teamId || !element.ownerId) setSelectedRowKeys([record.id])
              }, 
            };
          }}
        />
        </>
)}

export default LicensingTable;