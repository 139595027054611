import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash';
import View from './Users.view';
import { IRouterProps } from "../../constants/interfaces";
//import { errorSelector} from './redux/UsersSlice';
import { teamsSelector, errorSelector, getUsersStart } from "../../store/appSlice";

export default function Users(routerProps: IRouterProps) {
    const dispatch = useDispatch();
    const error = useSelector(errorSelector);
    const data = useSelector(teamsSelector);
    
    useEffect(()=>{
        if (isEmpty(data)) dispatch(getUsersStart());
    }, [dispatch, data])
    
    return (
        <View 
            {...routerProps}
            data={data}
            error={error}
        />
    )
}
