import React from 'react';
import View from './Management.view';


const Management = (routerProps:any) => {
   
  return (
    <View 
      {...routerProps}
    />
  )
};

export default Management;