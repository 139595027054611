import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { ColumnsType } from 'antd/es/table';
import { IData } from '../Management.interfaces';
import { Table } from 'antd';
import { gamesSelector } from "../redux/ManagementSlice";
import NewGameModal from './NewGameModal';


const GameTab = () => {
  const [showModal, setShowModal] = useState(false);
  const [editedRow, setEditedRow] = useState<any>(null);
  const games = useSelector(gamesSelector);

  const newGameHandler = () => {
    setShowModal(true);
  }

  const editHandler = (row: any) => {
    setShowModal(true);
    setEditedRow(row)
  }

  const GAME_FIELDS_CONFIG: ColumnsType<IData> = [
    {
      dataIndex: "id",
      title: "Идентификатор",
      //className: 'hiddenCol'
    },
    {
      dataIndex: "name",
      title: "Название",
    },
    {
      dataIndex: "description",
      title: "Описание",
    },
    {
      dataIndex: "isActive",
      title: "Активность",
      render: (_: any, row: any) => (
        <>
        {row.isActive ? "Активна" : "Не активна"}
        </>),
    },
    {
      dataIndex: '',
      title: '',
      render: (_: any, row: any) => (
        <>
        <Button variant="outline-info" onClick={() => editHandler(row)}>
          Редактировать
        </Button>{' '}
        {/*<Button variant="outline-warning" onClick={() => removeHandler(row)}>
          Удалить
        </Button>*/}
        </>),
    }
  ]
   
  return (
    <div className='management'>
        <div className='management--block'>
            <Button variant="outline-primary" className="management--newGameButton" onClick={newGameHandler}>Начать новую игру</Button>
            <Table 
                dataSource={games} 
                columns={GAME_FIELDS_CONFIG} 
                rowKey='id'
                className='managementTable'
                pagination={false}
            />
        </div>
        <NewGameModal showModal={showModal} setShowModal={setShowModal} row={editedRow} setRow={setEditedRow}/>
    </div>
  )
};

export default GameTab;