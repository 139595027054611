import React, { ReactElement } from "react";
import { Tabs } from 'antd';
import MacroTab from "./MacroTab";
import TaxTab from "./TaxTab";
import BudgetTab from "./BudgetTab";

export default function AgreementsTabs(props: any): ReactElement {

  const { data, activeTab, setActiveTab, editHandler, removehandler } = props;

  return (
    <Tabs className="agreements--tabs" defaultActiveKey={activeTab} onChange={(key) => {setActiveTab(key)}}>
        <Tabs.TabPane tab="Корп. решения" key="1">
            <MacroTab 
                data={data}
                editHandler={editHandler}
                removehandler={removehandler}
            />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Налоги" key="2">
            <TaxTab 
                data={data}
                editHandler={editHandler}
                removehandler={removehandler}
            />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Финансирование регионов" key="3">
            <BudgetTab 
                data={data}
                editHandler={editHandler}
                removehandler={removehandler}
            />
        </Tabs.TabPane>
    </Tabs>
    
  );
}
