import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Notification from '../../components/Notification';
import { fetchDataStart, errorSelector, dataSelector } from "./redux/ReportsRegionsSlice";
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import CountryPanel from '../../components/CountryPanel/CountryPanel';
import './reportsRegions.css';

const ReportsRegions = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const data = useSelector(dataSelector);

  const [regionId, setRegionId] = useState(1);
  
  useEffect(()=> {
    dispatch(fetchDataStart({regionId}))
  }, [dispatch, regionId])

  function rowMaker (head: any, prop: string | null, classN: string) {
    return (
      <tr className={classN}>
        <td colSpan={prop ? 1 : 3} className="fixed">{head}</td>
        {prop ? data.map((el: any) => (<td>{+el[prop as string] < 0 ? `(${Math.abs(+el[prop as string])})` : el[prop as string]}</td>)) : null}
      </tr>
    )
  }

  function RowRegionMaker () {
    switch(regionId) {
      case 1:  //Валгала
        return (<tbody>
            {rowMaker(<b>Выручка</b>, 'year', 'bold border-bottom margin-top')}
            {rowMaker(<p>Выручка от реализации нефти</p>, 'segrevenueoil', '')}
            {rowMaker(<p>Выручка от реализации газа</p>, 'segrevenuegas', '')}
            {rowMaker(<b>Итого выручка:</b>, 'segrevenuetotal', '')}
            {rowMaker(<b>Затраты и расходы</b>, null, 'bold border-bottom margin-top')}
            {rowMaker(<p>Производственные и операционные расходы</p>, 'segopex', '')}
            {rowMaker(<p>Транспортные расходы</p>, 'segtransp', '')}
            {rowMaker(<p>Износ, истощение и амортизация</p>, 'segdda', '')}
            {rowMaker(<p>НДД</p>, 'segndd', '')}
            {rowMaker(<b>Итого затраты и расходы</b>, 'segcoststotal', 'bold border-bottom border-top')}
            {rowMaker(<b>Операционная прибыль</b>, 'segebit', 'bold border-bottom margin-top')}
        </tbody>)

      case 2:  //Гардарика
      return (<tbody>
          {rowMaker(<b>Выручка</b>, 'year', 'bold border-bottom margin-top')}
          {rowMaker(<p>Выручка от реализации нефти</p>, 'segrevenueoil', '')}
          {rowMaker(<p>Выручка от реализации газа</p>, 'segrevenuegas', '')}
          {rowMaker(<b>Итого выручка:</b>, 'segrevenuetotal', '')}
          {rowMaker(<b>Затраты и расходы</b>, null, 'bold border-bottom margin-top')}
          {rowMaker(<p>Производственные и операционные расходы</p>, 'segopex', '')}
          {rowMaker(<p>Транспортные расходы</p>, 'segtransp', '')}
          {rowMaker(<p>Износ, истощение и амортизация</p>, 'segdda', '')}
          {rowMaker(<p>Эксп. пошлина (нефть, ДНС)</p>, 'segedoilcur', '')}
          {rowMaker(<p>НДПИ (нефть, ДНС)</p>, 'segndpioilcur', '')}
          {rowMaker(<p>Эксп. пошлина (нефть, НДД)</p>, 'segedioilndd', '')}
          {rowMaker(<p>НДД (нефть, НДД)</p>, 'segndd', '')}
          {rowMaker(<p>НДПИ (нефть, НДД)</p>, 'segndpindd', '')}
          {rowMaker(<p>Экспортная пошлина (газ, ДНС)</p>, 'segedgascur', '')}
          {rowMaker(<p>НДПИ (газ, ДНС)</p>, 'segndpigascur', '')}
          {rowMaker(<p>Налог на имущество</p>, 'segprtx', '')}
          {rowMaker(<b>Итого затраты и расходы</b>, 'segcoststotal', 'bold border-bottom border-top')}
          {rowMaker(<b>Операционная прибыль</b>, 'segebit', 'bold border-bottom margin-top')}
      </tbody>)


      case 3:  //Парфия
        return (<tbody>
          {rowMaker(<b>Выручка</b>, 'year', 'bold border-bottom margin-top')}
          {rowMaker(<p>Выручка от реализации нефти (справочно)</p>, 'segrevenueoil', '')}
          {rowMaker(<p>Выручка от реализации газа (справочно)</p>, 'segrevenuegas', '')}
          {rowMaker(<b>Итого выручка:</b>, 'segrevenuetotal', '')}
          {rowMaker(<b>Затраты и расходы</b>, null, 'bold border-bottom margin-top')}
          {rowMaker(<p>Производственные и операционные расходы</p>, 'segopex', '')}
          {rowMaker(<p>Транспортные расходы</p>, 'segtransp', '')}
          {rowMaker(<p>Износ, истощение и амортизация</p>, 'segdda', '')}
          {rowMaker(<p>Отчисления по СРП (справочно)</p>, 'segsrp', '')}
          {rowMaker(<p>Роялти (справочно)</p>, 'segroyalty', '')}
          {rowMaker(<b>Итого затраты и расходы</b>, 'segcoststotal', 'bold border-bottom border-top')}
          {rowMaker(<b>Операционная прибыль</b>, 'segebit', 'bold border-bottom margin-top')}
        </tbody>)


      case 4:  //Шривиджайя
        return (<tbody>
          {rowMaker(<b>Выручка</b>, 'year', 'bold border-bottom margin-top')}
          {rowMaker(<p>Выручка от реализации нефти (справочно)</p>, 'segrevenueoil', '')}
          {rowMaker(<p>Выручка от реализации газа (справочно)</p>, 'segrevenuegas', '')}
          {rowMaker(<b>Итого выручка:</b>, 'segrevenuetotal', '')}
          {rowMaker(<b>Затраты и расходы</b>, null, 'bold border-bottom margin-top')}
          {rowMaker(<p>Производственные и операционные расходы</p>, 'segopex', '')}
          {rowMaker(<p>Транспортные расходы</p>, 'segtransp', '')}
          {rowMaker(<p>Износ, истощение и амортизация</p>, 'segdda', '')}
          {rowMaker(<p>Отчисления по СРП (справочно)</p>, 'segsrp', '')}
          {rowMaker(<p>Роялти (справочно)</p>, 'segroyalty', '')}
          {rowMaker(<b>Итого затраты и расходы</b>, 'segcoststotal', 'bold border-bottom border-top')}
          {rowMaker(<b>Операционная прибыль</b>, 'segebit', 'bold border-bottom margin-top')}
        </tbody>)


      case 5:  //Ассирия
        return (<tbody>
          {rowMaker(<b>Выручка</b>, 'year', 'bold border-bottom margin-top')}
          {rowMaker(<p>Выручка от реализации нефти (справочно)</p>, 'segrevenueoil', '')}
          {rowMaker(<p>Выручка от реализации газа (справочно)</p>, 'segrevenuegas', '')}
          {rowMaker(<b>Итого выручка:</b>, 'segrevenuetotal', '')}
          {rowMaker(<b>Затраты и расходы</b>, null, 'bold border-bottom margin-top')}
          {rowMaker(<p>Производственные и операционные расходы</p>, 'segopex', '')}
          {rowMaker(<p>Транспортные расходы</p>, 'segtransp', '')}
          {rowMaker(<p>Износ, истощение и амортизация</p>, 'segdda', '')}
          {rowMaker(<p>Отчисления по СК (справочно)</p>, 'segsk', '')}
          {rowMaker(<b>Итого затраты и расходы</b>, 'segcoststotal', 'bold border-bottom border-top')}
          {rowMaker(<b>Операционная прибыль</b>, 'segebit', 'bold border-bottom margin-top')}
        </tbody>)
    
      default:
        return (<tbody></tbody>)
  }}
  
  return (
    <>
      <CountryPanel setRegionId={setRegionId} notAllRegions={true}/>
      <Notification success={false} error={error} />
      <div className='d-flex' style={{overflowX: 'auto'}}>
      <div className='report-company--hidden'>{' '}</div>
      <table className="report-regions-table">
	      <RowRegionMaker />
      </table>
      </div>
    </>
  )
};

export default ReportsRegions;



/*<tbody>
          {rowMaker(<h5>I. БАЛАНС</h5>, 'year', 'bold border-bottom margin-top')}
          
          {rowMaker(<h5>АКТИВЫ (Инвестированный капитал)</h5>, null, 'bold border-bottom margin-top')}
          {rowMaker(<b>Краткосрочные активы:</b>, null, '')}
          {rowMaker(<p>Денежные средства и их эквиваленты</p>, 'cash', '')}
          {rowMaker(<p>Прочие краткосрочные активы</p>, 'othercurassets', '')}
          {rowMaker(<b>Итого краткосрочные активы</b>, 'totalcurassets', 'bold border-bottom border-top')}
          {rowMaker(<b>Рабочий капитал</b>, 'workcapital', 'bold border-bottom border-top')}

          {rowMaker(<b>Долгосрочные активы:</b>, null, '')}
          {rowMaker(<p>Основные средства</p>, 'ppe', '')}
          {rowMaker(<p>Прочие долгосрочные активы</p>, 'othernoncurassets', '')}
          {rowMaker(<b>Итого долгосрочные активы</b>, 'totalnoncurassets', 'bold border-bottom border-top')}
          {rowMaker(<b>Итого активы</b>, 'totalassets', 'bold border-bottom border-top')}

          {rowMaker(<h5>ОБЯЗАТЕЛЬСТВА И АКЦИОНЕРНЫЙ КАПИТАЛ <br />(Задействованный капитал)</h5>, null, 'bold border-bottom margin-top')}
          {rowMaker(<b>Краткосрочные обязательства:</b>, null, '')}
          {rowMaker(<p>Краткосрочный кредит</p>, 'shortdebt', '')}
          {rowMaker(<p>Текущая часть по долгосрочным кредитам и займам</p>, 'curportionltd', '')}
          {rowMaker(<b>Итого краткосрочные обязательства</b>, 'totalcureliabilities', 'bold border-bottom border-top')}

          {rowMaker(<b>Долгосрочные обязательства:</b>, null, '')}
          {rowMaker(<p>Долгосрочные облигации</p>, 'longbonds', '')}
          {rowMaker(<p>Долгосрочные кредиты</p>, 'longloans', '')}
          {rowMaker(<b>Итого долгосрочные обязательства</b>, 'totalnoncurliabilities', 'bold border-bottom border-top')}

          {rowMaker(<b>Капитал:</b>, null, '')}
          {rowMaker(<p>Уставный капитал</p>, 'sharecapital', '')}
          {rowMaker(<p>Нераспределённая прибыль</p>, 'retainearnings', '')}
          {rowMaker(<b>Итого капитал</b>, 'totalequity', 'bold border-bottom border-top')}
          {rowMaker(<b>Итого обязательства и капитал</b>, 'totalequityliabilities', 'bold border-bottom border-top')}

          {rowMaker(<h5>II. ОТЧЕТ О ПРИБЫЛЯХ И УБЫТКАХ</h5>, 'year', 'bold border-bottom margin-top')}

          {rowMaker(<b>Выручка:</b>, null, '')}
          {rowMaker(<p>Выручка от реализации нефти</p>, 'revenueoil', '')}
          {rowMaker(<p>Выручка от реализации газа</p>, 'revenuegas', '')}
          {rowMaker(<b>Итого выручка</b>, 'totalrevenue', 'bold border-bottom border-top')}

          {rowMaker(<b>Затраты и расходы:</b>, null, '')}
          {rowMaker(<p>Производственные и операционные расходы</p>, 'costsproduction', '')}
          {rowMaker(<p>Транспортные расходы</p>, 'costtransport', '')}
          {rowMaker(<p>Износ, истощение и амортизация</p>, 'dda', '')}
          {rowMaker(<p>Налоги и отчисления</p>, 'taxes', '')}
          {rowMaker(<p>Экспортная пошлина</p>, 'exportduty', '')}
          {rowMaker(<b>Итого затраты и расходы</b>, 'totalcosts', 'bold border-bottom border-top')}

          {rowMaker(<b>Операционная прибыль</b>, 'operprofit', 'bold border-bottom border-top')}
          {rowMaker(<p>Финансовые и прочие доходы</p>, 'otherincome', '')}
          {rowMaker(<p>Финансовые и прочие расходы</p>, 'otherexpences', '')}
          {rowMaker(<b>Прибыль до налогообложения</b>, 'ebt', 'bold border-bottom border-top')}
          {rowMaker(<p>Налог на прибыль</p>, 'incometax', '')}
          {rowMaker(<b>Чистая прибыль</b>, 'netprofit', 'bold border-bottom border-top')}

          {rowMaker(<h5>ФИНАНСОВЫЕ ПОКАЗАТЕЛИ</h5>, null, 'bold border-bottom margin-top')}
          {rowMaker(<p>EBITDA</p>, 'ebitda', '')}
          {rowMaker(<p>Маржа EBITDA, %</p>, 'ebitdamargin', '')}
          {rowMaker(<p>Свободный денежный поток</p>, 'fcf', '')}
          {rowMaker(<p>Маржа чистой прибыли, %</p>, 'netprofitmargin', '')}
          {rowMaker(<p>ROACE, %</p>, 'roace', '')}
          {rowMaker(<p>Дивиденды на акцию, %</p>, 'divpershare', '')}
          {rowMaker(<p>Дивиденды к уплате</p>, 'dividends', '')}
          {rowMaker(<p>Долг / EBITDA</p>, 'debtebitda', '')}
          {rowMaker(<p>Долг / Капитал</p>, 'debtequity', '')}

          {rowMaker(<h5>III. ОТЧЕТ О ДВИЖЕНИИ ДЕНЕЖНЫХ СРЕДСТВ</h5>, 'year', 'bold border-bottom margin-top')}

          {rowMaker(<b>Операционная деятельность:</b>, null, 'bold border-bottom border-top')}
          {rowMaker(<p>Поступления от продаж</p>, 'netsales', '')}
          {rowMaker(<p>Платежи по расходам</p>, 'cogs', '')}
          {rowMaker(<p>Платежи по налогам</p>, 'taxpayable', '')}
          {rowMaker(<p>Изменение рабочего капитала</p>, 'wkchange', '')}

          {rowMaker(<b>Чистые денежные средства от операционной деятельности</b>, 'netcashoper', 'bold border-bottom border-top')}
          {rowMaker(<p>Поступления от продажи производственных активов</p>, 'ppecash', '')}
          {rowMaker(<p>Платежи поставщикам основных средств</p>, 'capex', '')}
          {rowMaker(<p>Платежи по финансовым вложениям</p>, 'investments', '')}

          {rowMaker(<b>Чистые денежные средства от инвестиционной деятельности</b>, 'netcashinvest', 'bold border-bottom border-top')}
          {rowMaker(<p>Поступления от долгосрочных заимствований</p>, 'longdebtin', '')}
          {rowMaker(<p>Поступления от краткосрочных заимствований</p>, 'shortdebtin', '')}
          {rowMaker(<p>Поступления от взносов в капитал</p>, 'equityin', '')}
          {rowMaker(<p>Платежи из фондов</p>, 'fundsout', '')}
          {rowMaker(<p>Платежи по долгосрочным заимствованиям</p>, 'longdebtout', '')}
          {rowMaker(<p>Платежи по краткосрочным заимствованиям</p>, 'shortdebtout', '')}
          {rowMaker(<p>Платежи по дивидендам</p>, 'dividendscf', '')}

          {rowMaker(<b>Чистые денежные средства от финансовой деятельности</b>, 'netcashfin', 'bold border-bottom border-top')}
          {rowMaker(<b>Чистое изменение денежных средств и их эквивалентов</b>, 'netcash', 'bold border-bottom border-top')}
          {rowMaker(<b>Денежные средства и их эквиваленты на начало периода</b>, 'cashstart', 'bold border-bottom border-top')}
          {rowMaker(<b>Денежные средства и их эквиваленты на конец периода</b>, 'cashend', 'bold border-bottom border-top')}

	      </tbody>*/