import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const ACCEPT_STORE_PATH = 'accept';

const AcceptSlice = createSlice({
    name: ACCEPT_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE,
        savedSuccess: false,
    },
    reducers: {
        getAcceptStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        getAcceptSucces (store, action) {
            return {...store, status: APP_STATE.IDLE, data: action.payload, error: null}
        },
        getAcceptError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        getGroupedAcceptStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        getGroupedSucces (store, action) {
            return {...store, status: APP_STATE.IDLE, groupedData: action.payload, error: null}
        },
        getGroupedError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        sendAcceptStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null, savedSuccess: false}
        },
        sendAcceptSucces (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, savedSuccess: true}
        },
        sendAcceptError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },


        getAcceptAgreementsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        getAcceptAgreementsSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, data: action.payload, error: null}
        },
        getAcceptAgreementsError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        sendAcceptAgreementsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null, savedSuccess: false}
        },
        sendAcceptAgreementsSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, savedSuccess: true}
        },
        sendAcceptAgreementsError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        clearSavedSuccess (store) {
            return {...store, savedSuccess: false,}
        }

    }
})

export const { 
    sendAcceptStart, sendAcceptSucces, sendAcceptError,
    getAcceptStart, getAcceptSucces, getAcceptError,
    getGroupedAcceptStart, getGroupedSucces, getGroupedError,
    getAcceptAgreementsStart, getAcceptAgreementsSuccess, getAcceptAgreementsError,
    sendAcceptAgreementsStart, sendAcceptAgreementsSuccess, sendAcceptAgreementsError,
    clearSavedSuccess
 } = AcceptSlice.actions;
export default AcceptSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function acceptGroupedDataSelector(state: any) {
    return get(state, `${ACCEPT_STORE_PATH}.groupedData`) || [];
}

export function acceptDataSelector(state: any) {
    return get(state, `${ACCEPT_STORE_PATH}.data`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${ACCEPT_STORE_PATH}.error`);
}

export function savedSuccessSelector(state: any) {
    return get (state, `${ACCEPT_STORE_PATH}.savedSuccess`);
}