import React, { ReactElement } from "react";
import { Row, Col } from 'react-bootstrap';
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import MiningTable from "./components/MiningTable";
import MiningHeader from './components/MiningHeader';
import MiningActionPanel from './components/MiningActionPanel';
import './components/mining.css'
import MiningHistoryPanel from "./components/MiningHistoryPanel";
import LicenseTypePanel from "../../components/LicenseTypePanel/LicenseTypePanel";

export default function View(props: any): ReactElement {
  const {
    isLoading,
    data,
    error,
    selectedRowKeys, 
    setSelectedRowKeys,
    setFieldType,
    setRegionId,
    selectedRowSubStage,
    setSelectedRowSubStage,
  } = props;

  return (
    <>
      <MiningHeader 
        setRegionId={setRegionId}
        setFieldType={setFieldType}
      />

      {/* TODO: move to separate component? */}
      <div className="assets-content">
        <Row className="mining--header__stageBlock">
          <Col xs={9}>
            <Row className="justify-content-between align-items-center">
              <Col xs={6}>
                <h4>Выберите актив для управления добычей</h4>
              </Col>
              <Col xs={2} className="align-self-right">
                <LicenseTypePanel setFieldType={setFieldType} />
              </Col>
              <Col xs={3} className="mining--header__historyButton">
                <MiningHistoryPanel />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={10}>
            <MiningTable data={data} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} setSelectedRowSubStage={setSelectedRowSubStage}/>
          </Col>
          <Col xs={2}>
            <MiningActionPanel selectedRowKeys={selectedRowKeys} selectedRowSubStage={selectedRowSubStage}/>
          </Col>
        </Row>
      </div>

      <Loader isLoading={isLoading} />
      <Notification success={false} error={error} />
    </>
  );
}
