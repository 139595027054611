import React from "react";

export default function NotFound() {
  return (
    <>
      <h1>404</h1>
      Страница не найдена
    </>
  );
}
