import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  fetchNewsStart, fetchNewsSuccess, fetchNewsError,
 } from './NewsHistorySlice';

 function* fetchNewsData (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'NewsArchive',
          payload: action.payload
        });
      yield put(fetchNewsSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(fetchNewsError(error))
  }
}


function* fetchNewsWatcher () {
  yield takeLatest(fetchNewsStart.type, fetchNewsData)
}


export const NewsHistorySagas = [fork(fetchNewsWatcher)];
//не забываем добавлять сагу в рут- сагу