import { Table } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { Mining_Fields_Config, MINING_ITEM_KEY } from '../Mining.constants';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { teamsSelector, getUsersStart, userSelector } from "../../../store/appSlice";


const MiningTable = (props: any): ReactElement => {

  const dispatch = useDispatch();
  const { data, selectedRowKeys, setSelectedRowKeys, setSelectedRowSubStage } = props;

  const teams = useSelector(teamsSelector);
  const user = useSelector(userSelector);

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
  }, [dispatch, teams])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const element = data.find((el: any) => el.id === newSelectedRowKeys[0])
    if (element.ownerId === user.teamId) {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRowSubStage(data.find((el: any) => el.id === newSelectedRowKeys[0])?.subStage)
    }
  };

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    //selections: false,
    type: 'radio' as 'radio', //это связано с плохой типизацией antd - это странно выглядит но работает
    onChange: onSelectChange,
  };

return (
    <Table 
        dataSource={data} 
        columns={Mining_Fields_Config({teams, user})} 
        rowKey={MINING_ITEM_KEY}
        className='MiningTable'
        size='middle'
        rowSelection={rowSelection}
        rowClassName={record => (record.ownerId === user.teamId) ? "" : "mining-disabled-row"}
        onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                const element = data.find((el: any) => el.id === record.id)
                if (element.ownerId === user.teamId) {
                  setSelectedRowKeys([record.id])
                  setSelectedRowSubStage(+record.subStage)
                }
              }, 
            };
          }}
    />
)}

export default MiningTable;