import React, { ReactElement, useEffect, useState } from "react";
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { Slider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { actionTypes } from "../../../constants";
import { yearSelector } from "../../../store/appSlice";
import { saveMiningItemStart, closeModalSlice, sendedSuccessSelector } from "../redux/MiningSlice";

export default function MiningActionPanel(props: any): ReactElement {
  const dispatch = useDispatch();
  const { selectedRowKeys, selectedRowSubStage } = props;

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<null | number>(null);

  const [year, setYear] = useState<number | string>('');
  const [percent, setPercent] = useState<number | string>('');
  const [sellPrice, setSellPrice] = useState<number | string>('');
  
  const [resetPercentOpen, setResetPercentOpen] = useState(false);

  const assetId = isEmpty(selectedRowKeys) ? null : selectedRowKeys[0];
  
  const baseYear = useSelector(yearSelector);
  const sendedSuccess = useSelector(sendedSuccessSelector);
  useEffect(()=>{
    if (baseYear && baseYear !== year) setYear(baseYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, baseYear])

  const result = {assetId, actionType: modalType, year, percent, amount: sellPrice};

  const handleOk = () => {
    dispatch(saveMiningItemStart(result))
    //if (baseYear) setYear(baseYear)
    //setPercent('');
    //setSellPrice('');
    //setShowModal(false);
    //setResetPercentOpen(false);
  };

  useEffect(()=> {
    if (sendedSuccess) {
      if (baseYear) setYear(baseYear)
      setPercent('');
      setSellPrice('');
      setShowModal(false);
      setResetPercentOpen(false);
      dispatch(closeModalSlice());
    }
  }, [sendedSuccess])

  const handleCancel = () => {
    if (baseYear) setYear(baseYear)
    setPercent('');
    setSellPrice('');
    setShowModal(false);
    setResetPercentOpen(false);
  };

  const yearHandler = (e: React.BaseSyntheticEvent) => {
    setYear(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const percentChangeSliderHandler = (val: number) => {
    setPercent(val)
  }

  const percentChangeHandler = (e: React.BaseSyntheticEvent) => {
    setPercent(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const percentResetHandler = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      setPercent(0);
      setResetPercentOpen(true)
    } else {
      setResetPercentOpen(false)
    }
  }

  const sellPriceHandler = (e: React.BaseSyntheticEvent) => {
    setSellPrice(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const showModalHandler = (type: number) => {
    return function () {
      setShowModal(true); 
      setModalType(type);
      if (baseYear && baseYear !== year) setYear(baseYear);
    }
  }

  let title = 'Параметры решения';
  if (modalType === actionTypes.StartDevelopment) title = 'Запустить в разработку';
  if (modalType === actionTypes.ChangeMiningLevel) title = 'Изменить уровень добычи';
  if (modalType === actionTypes.Liquidation) title = 'Ликвидация актива';
  if (modalType === actionTypes.Sell) title = 'Продажа актива';
  if (modalType === actionTypes.Stop) title = 'Остановить добычу';
  if (modalType === actionTypes.ReStart) title = 'Возобновить добычу';

  return (
      <div className="Mining--actionButtons">
        <p>Выберите действие:</p>
        <Button style={{background: '#0dcaf0'}} disabled={!assetId || !(selectedRowSubStage === 5)} onClick={showModalHandler(actionTypes.StartDevelopment)} >
          Запустить в разработку
        </Button>
        <Button style={{background: '#DFE228', color: '#000'}} disabled={!assetId || !(selectedRowSubStage === 6 || selectedRowSubStage === 7 || selectedRowSubStage ===8)} onClick={showModalHandler(actionTypes.ChangeMiningLevel)} >
          Изменить уровень добычи
        </Button>
        <Button style={{background: '#DE3D50'}} disabled={!assetId || !(selectedRowSubStage === 6 || selectedRowSubStage === 7 || selectedRowSubStage ===8 || selectedRowSubStage === 9)} onClick={showModalHandler(actionTypes.Liquidation)}>
          Ликвидация актива
        </Button>
        <Button style={{background: '#EFA81F', color: '#000'}}  disabled={!assetId || !(selectedRowSubStage === 6 || selectedRowSubStage === 7 || selectedRowSubStage ===8 || selectedRowSubStage === 9)} onClick={showModalHandler(actionTypes.Sell)}>
          Продажа актива
        </Button>
        <Button style={{background: '#3F3F3F'}}  disabled={!assetId || !(selectedRowSubStage === 6 || selectedRowSubStage === 7 || selectedRowSubStage ===8)} onClick={showModalHandler(actionTypes.Stop)}>
          Остановить добычу
        </Button>
        <Button style={{background: '#37BE65'}}  disabled={!assetId || !(selectedRowSubStage === 9)} onClick={showModalHandler(actionTypes.ReStart)}>
          Возобновить добычу
        </Button>

        <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === actionTypes.ChangeMiningLevel && 
            <Row>
            <Col>
              <p>Год решения</p>
              <Form.Control
                onChange={yearHandler}
                value={year}
                type="number"
                isInvalid={year === ''}
              />
            </Col>
            <Col>
              <Row>
                <p>Снизить на, %</p>
                <Col xs={7}>
                  <Slider
                    min={1}
                    max={100}
                    onChange={percentChangeSliderHandler}
                    disabled={resetPercentOpen}
                    value={typeof percent === 'number' ? percent : 0}
                  />
                </Col>
                <Col xs={5}>
                  <Form.Control
                    type="number"
                    min={1}
                    max={100}
                    value={percent}
                    disabled={resetPercentOpen}
                    onChange={percentChangeHandler}
                  />
                </Col>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type={'checkbox'}
                    label={`Восстановить уровень`}
                    onChange={percentResetHandler}
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>}
          {modalType === actionTypes.Liquidation && 
            <Row>
              <Col>
                <p>Год решения</p>
                <Form.Control
                  onChange={yearHandler}
                  value={year}
                  type="number"
                  isInvalid={year === ''}
                />
              </Col>
            </Row>}
          {modalType === actionTypes.Sell && 
            <Row>
              <Col>
                <p>Год решения</p>
                <Form.Control
                  onChange={yearHandler}
                  value={year}
                  type="number"
                  isInvalid={year === ''}
                />
              </Col>
              <Col>
              <p>Сумма продажи, млн $</p>
              <Form.Control
                  type="number"
                  value={sellPrice}
                  onChange={sellPriceHandler}
                />
              </Col>
            </Row>}
            {modalType === actionTypes.Stop && 
            <Row>
              <Col>
                <p>Год решения</p>
                <Form.Control
                  onChange={yearHandler}
                  value={year}
                  type="number"
                  isInvalid={year === ''}
                />
              </Col>
            </Row>}
            {modalType === actionTypes.ReStart && 
            <Row>
              <Col>
                <p>Год решения</p>
                <Form.Control
                  onChange={yearHandler}
                  value={year}
                  type="number"
                  isInvalid={year === ''}
                />
              </Col>
            </Row>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleOk}>
            Подать заявку
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
  );
}