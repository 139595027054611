import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useHistory } from "react-router";
import { DeleteOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';
import Notification from '../../components/Notification';
import './grrHistory.css';
import { IData } from "./GRRHistory.interfaces";
import { teamsSelector, getUsersStart } from "../../store/appSlice";
import { getGRRHistoryStart, historyGRRSelector, errorSelector, deleteGrrItemStart, updateGrrItemStart, closeModalSlice, sendedSuccessSelector } from './redux/GRRHistorySlice';

const GRRHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const error = useSelector(errorSelector);
  const sendedSuccess = useSelector(sendedSuccessSelector);
  const teams = useSelector(teamsSelector);
  const data = useSelector(historyGRRSelector) || [];
  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch])
  
  useEffect(()=>{
    dispatch(getGRRHistoryStart());
  }, [dispatch])

  const editHandler = (row: any) => {
    setEditedRow(row);
    setShowEditModal(true);
  }

  

  const removehandler = (row: any) => {
    setDeletedId(row.id);
    setShowDeleteModal(true);
  }

  const goBackHandler = () => {
    history.push("/grr");
  }


  //Обработчики данных для таблицы
  const formattedData = data.map((el: any) => ({
    ...el,
    owner: el?.teamName || teams.find((element: any) => element.id === el.ownerId)?.teamName || 'Админ'}))

  const LICENSING_HISTORY_MODAL_FIELDS_CONFIG: ColumnsType<IData> = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'roundNumber',
      title: 'Раунд',
    },
    {
      dataIndex: 'name',
      title: 'Решение'
    },
    { 
      dataIndex: 'owner',
      title: 'Инициатор'
    },
    {
      dataIndex: 'startNow',
      title: 'запустить разработку сразу',
      align: "center",
      render: (_: any, row: any) => (
          <Form.Check id='grrHistoryCheck' type="checkbox" checked={row.startNow}  onChange={()=>{}} />
        )
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <>
          <Button variant="outline-info" onClick={() => editHandler(row)} title="Редактировать">
            <EditOutlined className="licensingHistory--icons"/>
          </Button>
          <Button variant="outline-info" onClick={() => removehandler(row)} title="Удалить">
            <DeleteOutlined className="licensingHistory--icons"/>
          </Button>
        </>)
    },
  ]


  //Обработчики для модалки удаления
  const [deletedId, setDeletedId] = useState<null | number>(null);

  const hideDeleteModal = () => {
    setDeletedId(null);
    setShowDeleteModal(false);
  }

  const onOkDeleteModal = () => {
    dispatch(deleteGrrItemStart({id: deletedId}))
    setDeletedId(null);
    setShowDeleteModal(false);
  }


  //Обработчики для модалки редактирования
  const [editedRow, setEditedRow] = useState<any>(null);
  const [startNow, setStartNow] = useState(false);

  useEffect(()=>{
    if (editedRow) setStartNow(editedRow.startNow)
  }, [editedRow])

  const hideEditModal = () => {
    setShowEditModal(false);
  }

  const startNowHandler = () => {
    setStartNow(!startNow)
  }

  const onOkEditModal = () => {
    const payload = {
      id: editedRow.id,
      assetId: editedRow.assetId,
      startNow: startNow
    }
    dispatch(updateGrrItemStart(payload))
  }

  useEffect(()=> {
    if (sendedSuccess) {
      setEditedRow(null);
      setShowEditModal(false);
      dispatch(closeModalSlice());
    }
  }, [sendedSuccess])

  return (
    <div className="licensingHistory">
      <div className='grrHistory--back'>
        <Button variant="outline-secondary" onClick={goBackHandler}>
          <RollbackOutlined  className="licensingHistory--icons"/>
          Назад
        </Button>
      </div>
      <Table 
        dataSource={formattedData} 
        columns={LICENSING_HISTORY_MODAL_FIELDS_CONFIG} 
        rowKey='id'
        className='LicensingHistoryModalTable'
        pagination={false}
      />
      

      <Modal show={showDeleteModal} onHide={hideDeleteModal} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Подтвердите</Modal.Title>
        </Modal.Header>
        <Modal.Body>Подтвердите удаление</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkDeleteModal}>
            Подтвердить
          </Button>
          <Button variant="secondary" onClick={hideDeleteModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={hideEditModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editedRow && 
            <Row>
              <Col xs={2} />
              <Col xs={6}>
                <p>В случае успеха запустить разработку актива сразу</p>
              </Col>
              <Col xs={2}>
                <Form.Check id='grrHistoryCheck' type="checkbox" checked={startNow} onChange={startNowHandler} />
              </Col>
            </Row>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkEditModal}>
            Сохранить
          </Button>
          <Button variant="secondary" onClick={hideEditModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Notification success={false} error={error} />
    </div>
  );
};

export default GRRHistory;
