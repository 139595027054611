import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  getTechnologiesHistoryStart, getTechnologiesHistorySuccess, getTechnologiesHistoryError,
  deleteTechnologiesItemStart, deleteTechnologiesItemSucces, deleteTechnologiesItemError, 
  updateTechnologiesItemStart, updateTechnologiesItemSuccess, updateTechnologiesItemError,
 } from './TechnologiesHistorySlice';

 function* getTechnologiesHistory (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
        apiMethod: 'TechActions',
        payload: {}
      });
    yield put(getTechnologiesHistorySuccess(response))
} catch (error) {
    console.log(error)
    yield put(getTechnologiesHistoryError(error))
}
}

function* deleteTechnologiesItem (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.delete, {
            apiMethod: 'TechActions',
            payload: action.payload
          });
        yield put(deleteTechnologiesItemSucces(response))
        yield put(getTechnologiesHistoryStart())
    } catch (error) {
        console.log(error)
        yield put(deleteTechnologiesItemError(error))
    }
}

function* updateTechnologiesItem (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
          apiMethod: 'TechActions',
          payload: action.payload
        });
      yield put(updateTechnologiesItemSuccess(response))
      yield put(getTechnologiesHistoryStart())
  } catch (error) {
      console.log(error)
      yield put(updateTechnologiesItemError(error))
  }
}

function* getTechnologiesHistoryWatcher () {
  yield takeLatest(getTechnologiesHistoryStart.type, getTechnologiesHistory)
}

function* deleteTechnologiesHistoryItemWatcher () {
    yield takeLatest(deleteTechnologiesItemStart.type, deleteTechnologiesItem)
}

function* updateTechnologiesHistoryItemWatcher () {
    yield takeLatest(updateTechnologiesItemStart.type, updateTechnologiesItem)
}


export const TechnologiesHistorySagas = [fork(deleteTechnologiesHistoryItemWatcher), fork(updateTechnologiesHistoryItemWatcher), fork(getTechnologiesHistoryWatcher)];
//не забываем добавлять сагу в рут- сагу