import React, { ReactElement } from "react";
import ManagementTabs from './components/ManagementTabs';
import './management.css';

export default function View(props: any): ReactElement {
  const {} = props;

  return (
    <>
      <ManagementTabs />
    </>
  );
}
