import React, { ReactElement } from "react";
import CountryPanel from "../../../components/CountryPanel/CountryPanel";

export default function GRRHeader(props: any): ReactElement {

  const {setRegionId} = props;

  return (
      <div className="GRR--header">
        <div className="d-flex grr--header__block">
          <CountryPanel setRegionId={setRegionId} />
        </div>
      </div>
  );
}
