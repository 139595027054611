import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const ADD_REPORT_STORE_PATH = 'add_report';

const AddReportSlice = createSlice({
    name: ADD_REPORT_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE
    },
    reducers: {
        getReportDataStart (state) {
            return{...state, status: APP_STATE.LOADING}
        },
        getReportDataSuccess (store, action) {
            return {...store, data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        getReportDataError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },

        addReportStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        addReportSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        addReportError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },

        getReportFileStart (state, action) {
            return{...state, status: APP_STATE.LOADING, preparation: [], error: null}
        },
        getReportFileSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        getReportFileError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        deleteReportFileStart (state, action) {
            return{...state, status: APP_STATE.LOADING, preparation: [], error: null}
        },
        deleteReportFileSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        deleteReportFileError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },
    }
})

export const { 
    addReportStart, addReportSuccess, addReportError, 
    getReportDataStart, getReportDataSuccess, getReportDataError,
    getReportFileStart, getReportFileSuccess, getReportFileError,
    deleteReportFileStart, deleteReportFileSuccess, deleteReportFileError
 } = AddReportSlice.actions;
 
export default AddReportSlice.reducer;
//не забываем редюсер добавить в рут- редюсер



/*export function isLoadingSelector(state: any) {
    return (
      get(state, `${LICENSING_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.data`) || [];
}*/

export function reportDataSelector(state: any) {
    return get(state, `${ADD_REPORT_STORE_PATH}.data`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${ADD_REPORT_STORE_PATH}.error`);
}