import React, { ReactElement, useState, useEffect } from "react";
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux'
import {fetchTechnologiesTableStart} from '../redux/TechnologiesSlice';
import AvailableTab from "./AvailableTab";
import ImplementedTab from "./ImplementedTab";
import ActivedTab from "./ActivedTab";

export default function TechnologiesTabs(props: any): ReactElement {
    const dispatch = useDispatch();

    const { data } = props;
    const [activeTab, setActiveTab] = useState('1');

    useEffect(() => {
        dispatch(fetchTechnologiesTableStart({status: +activeTab}));
    }, [dispatch, activeTab]);


  return (
    <>
    <Tabs className="Technologies--tabs" defaultActiveKey={activeTab} onChange={(key) => {setActiveTab(key)}}>
        <Tabs.TabPane tab="Доступные" key="1">
            <AvailableTab data={data}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Текущие" key="2">
            <ActivedTab data={data}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Реализованные" key="3">
            <ImplementedTab data={data}/>
        </Tabs.TabPane>
    </Tabs>
    </>
  );
}
