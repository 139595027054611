import React, { useState, useEffect } from 'react';
import { isEmpty, sortBy } from "lodash";
import { Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import './addReport.css';
import { reportTypes } from '../../constants';
import { teamsSelector, getUsersStart } from "../../store/appSlice";
import { addReportStart, getReportDataStart, reportDataSelector, getReportFileStart, deleteReportFileStart } from "./redux/AddReportSlice";

const AddNews = () => {
  const dispatch = useDispatch();
  //const error = useSelector(errorSelector);
  const teams = useSelector(teamsSelector);
  const data = useSelector(reportDataSelector);
  const [showModal, setShowModal] = useState(false);
  //const [selectedComandId, setSelectedComandId] = useState(-1);
  //const [selectedType, setSelectedType] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  useEffect(()=>{
    dispatch(getReportDataStart());
  }, [dispatch])

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  
  /*const changeComandSelectHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedComandId(+e.target.value)
  }

  const changeSelectTypeHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedType(+e.target.value)
  }*/

  const sendFileHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedFiles(e.target.files)
  }

  const downloadHandler = (row: any) => {
    dispatch(getReportFileStart({id: row.id}))
  }

  const removeHandler = (row: any) => {
    dispatch(deleteReportFileStart({id: row.id}))
  }

  const onOkModal = () => {
    dispatch(addReportStart({files: selectedFiles, data: {}}));
    setSelectedFiles(null)
    setShowModal(false);
  }

  const REPORTS_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'reportType',
      title: 'ТипОтчета',
      render: (_: any, row: any) => (<p>{reportTypes.find((el: any) => el.id === row.reportType)?.title || ''}</p>)
    },
    {
      dataIndex: 'roundNumber',
      title: 'Номер Раунда',
      className: 'centeredCol'
    },
    { 
      dataIndex: 'teamName',
      title: 'Имя команды',
      className: 'centeredCol'
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <div>
          <Button variant="outline-info" onClick={() => downloadHandler(row)} title="Скачать">
            <SaveOutlined  className="report--icons"/> 
            <span className="report--inner--buttons--text">Скачать</span>
          </Button>
          <Button variant="outline-info" onClick={() => removeHandler(row)} title="Удалить">
            <DeleteOutlined className="report--icons"/> 
            <span className="report--inner--buttons--text">Удалить</span>
          </Button>
        </div>)
    },
  ]
  
  
  const sortedData = sortBy(data, ['teamName', 'reportType'])

  return (
    <div className='addReport'>
        <div className="reports--addReportButton">
          <Button variant="outline-primary" onClick={() => setShowModal(true)} >
              Добавить отчет
          </Button>
        </div>
        <Table 
          dataSource={sortedData} 
          columns={REPORTS_FIELDS_CONFIG} 
          rowKey='id'
          size='small'
          className='ReportTable'
          pagination={false}
        />
        
        <Modal show={showModal} onHide={()=>setShowModal(false)} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Добавление отчета</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*<p>Команда</p>
          <Form.Select value={selectedComandId} onChange={changeComandSelectHandler}>
            <option value={-1} key={-1}>Выберите</option>
            {teams.map((el: any) => (<option value={el.id} key={el.id} >{el.teamName}</option>))}
          </Form.Select>
          <p style={{marginTop: '20px'}}>Тип отчета</p>
          <Form.Select value={selectedType} onChange={changeSelectTypeHandler}>
            <option value={0} key={0}>Выберите</option>
            {reportTypes.map((el: any) => (<option value={el.id} key={el.id} >{el.title}</option>))}
          </Form.Select>*/}
          <Form.Label htmlFor="addReport--fileUpload" className="btn btn-report-file btn-outline-warning" style={{marginTop: '20px'}}>
              Загрузить файл
          </Form.Label>
          <Form.Control 
            id="addReport--fileUpload" 
            type="file" 
            accept=".pdf"
            multiple
            className="addReport__hidden" 
            placeholder="Загрузить файл" 
            onChange={sendFileHandler}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkModal} disabled={!selectedFiles}>
            Сохранить
          </Button>
          <Button variant="secondary" onClick={()=> setShowModal(false)}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
};

export default AddNews;