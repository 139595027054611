import { IData } from "./Licensing.interfaces";
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const LICENSING_ITEM_KEY: string = "id";

export const LICENSING_STORE_PATH: string = "licensing";

export const waitRegisteredId: number = 28006;

export function LICENSING_FIELDS_CONFIG ({teams, user}: any): ColumnsType<IData>  {
    return ([
  {
    dataIndex: "id",
    title: 'id',
    className: 'hiddenCol'
  },
  {
    dataIndex: "name",
    title: "Актив",
    align: 'center',
  },
  {
    dataIndex: "oper",
    title: "Оператор",
    align: "center",
    render: (_: any, row: any) => (
      (+row.ownerId === user.teamId || !row.ownerId) ? 
      <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p> 
      :
      <div className="d-flex">
        <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p>
        <Tooltip placement="right" title='Принимать решения по данному активу может только его оператор' >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      )
  },
  {
    dataIndex: "region",
    title: "Регион",
    align: "center",
  },
  {
    dataIndex: "geol",
    title: "Геология",
    align: "center",
  },
  {
    dataIndex: "product",
    title: "Тип УВ",
    align: "center",
  },
  {
    dataIndex: "ur",
    title: "Диапазон ресурсов, млн. тнэ.",
    align: "center",
    width: 120
  },
  {
    dataIndex: "spl",
    title: "Стартовый платёж",
    align: "center",
    width: 120,
    className: "th--mlnd"
  },
  {
    dataIndex: "edc",
    title: "Затраты ГРР",
    align: "center",
    width: 120,
    className: "th--mlnd"
  },
  {
    dataIndex: "semv",
    title: "EMV",
    align: "center",
    className: "th--mlnd"
  },
])};