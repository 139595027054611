import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  getMiningHistoryStart, getMiningHistorySuccess, getMiningHistoryError,
  deleteMiningItemStart, deleteMiningItemSucces, deleteMiningItemError, 
  updateMiningItemStart, updateMiningItemSuccess, updateMiningItemError,
 } from './MiningHistorySlice';

 function* getMiningHistory (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
        apiMethod: 'MiningActions',
        payload: {}
      });
    yield put(getMiningHistorySuccess(response))
} catch (error) {
    console.log(error)
    yield put(getMiningHistoryError(error))
}
}

function* deleteMiningItem (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.delete, {
            apiMethod: 'MiningActions',
            payload: action.payload
          });
        yield put(deleteMiningItemSucces(response))
        yield put(getMiningHistoryStart())
    } catch (error) {
        console.log(error)
        yield put(deleteMiningItemError(error))
    }
}

function* updateMiningItem (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
          apiMethod: 'MiningActions',
          payload: action.payload
        });
      yield put(updateMiningItemSuccess(response))
      yield put(getMiningHistoryStart())
  } catch (error) {
      console.log(error)
      yield put(updateMiningItemError(error))
  }
}

function* getMiningHistoryWatcher () {
  yield takeLatest(getMiningHistoryStart.type, getMiningHistory)
}

function* deleteMiningHistoryItemWatcher () {
    yield takeLatest(deleteMiningItemStart.type, deleteMiningItem)
}

function* updateMiningHistoryItemWatcher () {
    yield takeLatest(updateMiningItemStart.type, updateMiningItem)
}


export const MiningHistorySagas = [fork(deleteMiningHistoryItemWatcher), fork(updateMiningHistoryItemWatcher), fork(getMiningHistoryWatcher)];
//не забываем добавлять сагу в рут- сагу