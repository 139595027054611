import React, { ReactElement, useState, useEffect } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../constants";
import { saveGrrItemStart, sendedSuccessSelector, closeModalSlice } from "../redux/GRRSlice";

export default function GRRActionPanel(props: any): ReactElement {
  const dispatch = useDispatch();
  const {selectedRowKeys} = props;
  const [showModal, setShowModal] = useState(false);
  const [startNow, setstartNow] = useState(false);

  const changeHandler = (e: React.BaseSyntheticEvent) => {
    setstartNow(!startNow)
  }

  const sendedSuccess = useSelector(sendedSuccessSelector);
  const assetId = isEmpty(selectedRowKeys) ? null : selectedRowKeys[0];
  const selectedRowSubStage = props.data.find((el: any) => el.id === selectedRowKeys[0])?.subStage;

  const handleOk = () => {
    dispatch(saveGrrItemStart({assetId, startNow, actionType: actionTypes.CarryOutGrr}))
    //setShowModal(false);
  };

  useEffect(()=> {
    if (sendedSuccess) {
      setShowModal(false);
      dispatch(closeModalSlice());
    }
  }, [sendedSuccess])
  
  const handleCancel = () => {
    setShowModal(false);
  };

  return (
      <div className="GRR--actionButtons">
        <p>Выберите действие:</p>
        <Button variant="warning" onClick={()=>setShowModal(true)} disabled={isEmpty(selectedRowKeys) || selectedRowSubStage === 4}>
          Провести ГРР
        </Button>

        <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Параметры решения</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Check id='grrModalCheck' type="checkbox" checked={startNow} onChange={changeHandler} label="В случае успеха запустить разработку актива сразу" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleOk}>
            Принять решение
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
  );
}