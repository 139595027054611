import {FC} from 'react';
import s from './countries_map.module.css';
import mapCountriesImg from '../../../../../../assets/img/map_countries.png';
import {Button} from 'antd';
import cn from 'classnames';
import {CountriesPage} from '../../../../models/wiki';

interface Props {
  setActiveCountriesPage: (value: any) => void;
}

const CountriesMap: FC<Props> = ({setActiveCountriesPage}) => {
  const handleCountriesChange = (page: CountriesPage) => {
    setActiveCountriesPage(page);
  };

  return (
    <div>
      <div className={s.map}>
        <img src={mapCountriesImg} alt="Map of countries" />

        <Button onClick={() => handleCountriesChange(CountriesPage.Valhalla)} className={cn(s.mapButton, s.Valhalla)}>
          Валгалла
        </Button>
        <Button onClick={() => handleCountriesChange(CountriesPage.Gardarika)} className={cn(s.mapButton, s.Gardarika)}>
          Гардарика
        </Button>
        <Button onClick={() => handleCountriesChange(CountriesPage.Assyria)} className={cn(s.mapButton, s.Assyria)}>
          Ассирия
        </Button>
        <Button onClick={() => handleCountriesChange(CountriesPage.Parthia)} className={cn(s.mapButton, s.Parthia)}>
          Парфия
        </Button>
        <Button onClick={() => handleCountriesChange(CountriesPage.Srivijaya)} className={cn(s.mapButton, s.Srivijaya)}>
          Шривиджайя
        </Button>
      </div>

      <div className={s.title}>Выберите страну для получения подробной информации</div>
    </div>
  );
};

export default CountriesMap;
