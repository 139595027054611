import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
import { LICENSING_STORE_PATH } from "./../Licensing.constants";

const LicensingSlice = createSlice({
    name: LICENSING_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE,
        data: [],
        sendedSuccess: false,
    },
    reducers: {
        fetchStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchSucces (store, action) {
            return {...store, data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        fetchError (store, action) {
            console.log(action.payload)
            return {...store, status: APP_STATE.IDLE, data: [],
                error: 
                    action.payload.errors ? action.payload.errors.toString() : 
                    action.payload ? action.payload : 
                    'Ошибка сервера'}
        },


        sendLicenseOrderStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        sendLicenseOrderSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, sendedSuccess: true, error: null}
        },
        sendLicenseOrderError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },


        getLicenseHistoryStart (state) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        getLicenseHistorySuccess (store, action) {
            return {...store, history_data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        getLicenseHistoryError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },

        closeModalSlice (store) {
            return {...store, sendedSuccess: false}
        },
    }
})

export const { 
    fetchStart, fetchSucces, fetchError, 
    sendLicenseOrderStart, sendLicenseOrderSuccess, sendLicenseOrderError,
    getLicenseHistoryStart, getLicenseHistorySuccess, getLicenseHistoryError,
    closeModalSlice
 } = LicensingSlice.actions;
export default LicensingSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${LICENSING_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.data`) || [];
}

export function historyDataSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.history_data`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.error`);
}

export function sendedSuccessSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.sendedSuccess`);
}