import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const REPORTS_GRAPH_REGIONS_STORE_PATH = 'reports_graph_regions';

const ReportsGraphRegionsSlice = createSlice({
    name: REPORTS_GRAPH_REGIONS_STORE_PATH,
    initialState: {
        error: null as null | string,
        data: [],
        status: APP_STATE.IDLE,
    },
    reducers: {
        fetchDataStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        fetchDataSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, data: action.payload, error: null}
        },
        fetchDataError (store, action) {
            return {...store, status: APP_STATE.IDLE, data: [],
                error: 
                    action.payload.errors ? action.payload.errors.toString() : 
                    action.payload ? action.payload : 
                    'Ошибка сервера'}
        },
    }
})

export const { 
    fetchDataStart, fetchDataSuccess, fetchDataError,
 } = ReportsGraphRegionsSlice.actions;
export default ReportsGraphRegionsSlice.reducer;
//не забываем редюсер добавить в рут- редюсер


export function dataSelector(state: any) {
    return get(state, `${REPORTS_GRAPH_REGIONS_STORE_PATH}.data`);
}

export function errorSelector(state: any) {
    return get(state, `${REPORTS_GRAPH_REGIONS_STORE_PATH}.error`);
}