import Main from "../pages/Main";
import Licensing from "../pages/Licensing";
import Mining from "../pages/Mining";
import NotFound from "../pages/NotFound";
import GRR from "../pages/GRR";
import Technologies from "../pages/Technologies";
import Management from "../pages/Management";
import ReportsGraphCompany from "../pages/ReportsGraphCompany";
import ReportsGraphRegions from "../pages/ReportsGraphRegions";
import Users from "../pages/Users/";
import AddNews from "../pages/AddNews";
import Accept from "../pages/Accept";
import LicensingHistory from "../pages/LicensingHistory";
import Agreements from "../pages/Agreements";
import GRRHistory from "../pages/GRRHistory";
import Wiki from "../pages/Wiki";
import MiningHistory from "../pages/MiningHistory";
import AddReport from "../pages/AddReport";
import TechnologiesHistory from "../pages/TechnologiesHistory";
import ReportsCompany from "../pages/ReportsCompany";
import NewsHistory from "../pages/NewsHistory";
import ReportsRegions from "../pages/ReportsRegions";

export const routes = {
  main: {
    title: "Главная",
    path: "/",
    component: Main,
  },
  news: {
    menuid: 5001,
    title: "Новости",
    path: "/news",
    component: Main,
  },
  licensing: {
    menuid: 5001,
    title: "Лицензирование",
    path: "/licensing",
    component: Licensing,
  },
  licensingHistory: {
    title: "Принятые решения",
    path: "/licensing_history",
    component: LicensingHistory,
  },
  grr: {
    menuid: 5001,
    title: "ГРР",
    path: "/grr",
    component: GRR,
  },
  grrHistory: {
    title: "Принятые решения",
    path: "/grr_history",
    component: GRRHistory,
  },
  mining: {
    menuid: 5001,
    title: "Добыча",
    path: "/mining",
    component: Mining,
  },
  miningHistory: {
    title: "Принятые решения",
    path: "/mining_history",
    component: MiningHistory,
  },
  technology: {
    menuid: 5001,
    title: "Технологии",
    path: "/technology",
    component: Technologies,
  },
  technologyHistory: {
    title: "Принятые решения",
    path: "/technology_history",
    component: TechnologiesHistory
  },
  agreements: {
    menuid: 5001,
    title: "Договоренности\nи корп. решения",
    path: "/agreements",
    component: Agreements,
  },
  agreementsHistory: {
    title: "Принятые решения",
    path: "/agreements_history",
    component: Agreements
  },
  reportsGraphCompany: {
    menuid: 5001,
    title: "Графики\n(по компании)",
    path: "/reports_graph_company",
    component: ReportsGraphCompany,
  },
  reportsGraphRegions: {
    menuid: 5001,
    title: "Графики\n(по регионам)",
    path: "/reports_graph_regions",
    component: ReportsGraphRegions,
  },
  reportsCompany: {
    menuid: 5001,
    title: "Отчетность\n(по компании)",
    path: "/reports_company",
    component: ReportsCompany,
  },
  reportsRegions: {
    menuid: 5001,
    title: "Отчетность\n(по регионам)",
    path: "/reports_regions",
    component: ReportsRegions,
  },
  management: {
    menuid: 5002,
    title: "Управление",
    path: "/management",
    component: Management,
  },
  accept: {
    menuid: 5002,
    title: "Подтверждения",
    path: "/accept",
    component: Accept,
  },
  addNews: {
    menuid: 5002,
    title: "Создание Новости",
    path: "/addNews",
    component: AddNews,
  },
  NewsHistory: {
    menuid: 5001,
    title: "История новостей",
    path: "/news_history",
    component: NewsHistory,
  },
  users: {
    menuid: 5002,
    title: "Пользователи",
    path: "/users",
    component: Users,
  },
  wiki: {
    menuid: 5001,
    title: "Вики",
    path: "/wiki",
    component: Wiki
  },
  addReport: {
    menuid: 5002,
    title: "Создание Отчетов",
    path: "/addReport",
    component: AddReport
  },
  notFound: {
    title: "Страница не найдена",
    path: "/NotFound",
    component: NotFound,
  }
};

export default routes;
