import {createSlice} from '@reduxjs/toolkit';
import {APP_STATE} from '../../../constants';
import {get} from 'lodash';
const WIKI_STORE_PATH = 'wiki';

const WikiSlice = createSlice({
  name: WIKI_STORE_PATH,
  initialState: {
    error: null as null | string,
    status: APP_STATE.IDLE,
  },
  reducers: {
    wikiStart(store, action) {
      return {...store, status: APP_STATE.LOADING, error: null};
    },
    wikiSucces(store, action) {
      return {...store, status: APP_STATE.IDLE, error: null};
    },
    wikiError(store, action) {
      return {...store, status: APP_STATE.IDLE, error: action.payload?.errors.toString() || 'Ошибка сервера'};
    },
  },
});

export const {wikiStart, wikiSucces, wikiError} = WikiSlice.actions;

export default WikiSlice.reducer;

export function errorSelector(state: any) {
  return get(state, `${WIKI_STORE_PATH}.error`);
}
