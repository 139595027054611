import React, { ReactElement } from "react";
//import CountryPanel from "../../../components/CountryPanel/CountryPanel";


export default function TechnologiesHeader(): ReactElement {

  return (
      <div className="d-flex justify-content-between Technologies--header">
        {/*<CountryPanel />*/}
      </div>
  );
}
