import React, { useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo3.png";
import { LogoutOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { useTimer } from 'react-timer-hook';
//import { appActionCreators } from "../../store/appReducer";
import { timerSelector, logoutStart, getStatusStart, roundSelector, yearSelector } from "../../store/appSlice";

import './header.css';

interface IUser {
  userName: string;
  teamName: string;
}

interface IHeader {
  toggleMenu: ()=>void;
  isMenuOpened: boolean;
  user: IUser;
}

export default function Header({ toggleMenu, user }: IHeader) {
  const dispatch = useDispatch();
  function handleLogoutButton() {
    dispatch(logoutStart());
  }
  const timeStamp = useSelector(timerSelector);
  const round = useSelector(roundSelector);
  const year = useSelector(yearSelector);

  useEffect(()=>{
    if (!round || !year) dispatch(getStatusStart())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round, year])

  return (
    <Navbar  variant="dark" className="mainHeader justify-content-between">
      <Nav className='mainHeader--Block'>
        <Nav className='mainHeader--IconBlock'>
          <Nav.Link onClick={toggleMenu}>
            {/*<MenuOutlined style={{fontSize: 20}} />*/}
          </Nav.Link>
          <Navbar.Brand className="mainHeader--IconBlock--Logo">
            <Link to="/">
              <img
                src={logo}
                width="200"
                height="28"
                className="d-inline-block"
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
        </Nav>
        <MyTimer expiryTimestamp={timeStamp} />
        {round ?
        <div className="d-flex mainHeader--round">
          <p>Раунд:</p>
          <span>{round}</span>
        </div> : null}
        {year ? 
        <div className="d-flex mainHeader--year">
          <p>Год:</p>
          <span>{year}</span>
        </div> : null}
      </Nav>
      <Nav className='mainHeader--Block'>
        <Nav.Link>{user.teamName}</Nav.Link>
        <Nav.Link onClick={handleLogoutButton}>
          <LogoutOutlined style={{fontSize: 20}} />
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}

function MyTimer({ expiryTimestamp }: any) {
  const {
    seconds,
    minutes,
    hours,
    //days,
    //isRunning,
    //start,
    //pause,
    //resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

  useEffect(()=> {
    restart(expiryTimestamp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTimestamp])

  return (
    <div className="mainHeader--Timer">
        <FieldTimeOutlined className="mainheader--TimerIcon"/>
        <div className="mainHeader--TimerTitle">
          <span>Осталось времени:</span>
        </div>
        <div className="mainHeader--TimerFlex">
          <div className="mainHeader--TimerValue">
            <span>{('00' + hours).slice(-2)}</span>:<span>{('00' + minutes).slice(-2)}</span>:<span>{('00' + seconds).slice(-2)}</span>
          </div>
          <div className="mainHeader--TimerComment">
            <span>часов</span><span>минут</span><span>секунд</span>
          </div>
        </div>
    </div>
  );
}
