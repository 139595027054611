import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  fetchDataStart, fetchDataSuccess, fetchDataError,
 } from './ReportsCompanySlice';

function* fetchReportCompanyData (action: any) {
  try {
    const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
      apiMethod: 'CompanyReports',
      payload: action.payload
    });
  yield put(fetchDataSuccess(response))
} catch (error) {
  console.log(error)
  yield put(fetchDataError(error))
}
}


function* fetchReportCompanyDataWatcher () {
  yield takeLatest(fetchDataStart.type, fetchReportCompanyData)
}

export const ReportsCompanySagas = [ fork(fetchReportCompanyDataWatcher)];
//не забываем добавлять сагу в рут- сагу