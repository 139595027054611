import React, { ReactElement, useState, useEffect } from "react";
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux'
import RoundTab from './RoundTab';
import GameTab from './GameTab';
import { getGamesStart } from "../redux/ManagementSlice";

export default function ManagementTabs(props: any): ReactElement {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('1');

    useEffect(() => {
        if (activeTab === '2') dispatch(getGamesStart());
    }, [dispatch, activeTab]);


  return (
    <>
    <Tabs className="Management--tabs" defaultActiveKey={activeTab} onChange={(key) => {setActiveTab(key)}}>
        <Tabs.TabPane tab="Раунд" key="1">
            <RoundTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Игра" key="2">
            <GameTab />
        </Tabs.TabPane>
    </Tabs>
    </>
  );
}
