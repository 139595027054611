import React, { ReactElement } from 'react';
import { Table } from 'antd';
import { MACRO_FIELDS_CONFIG } from '../Agreements.constants';

const MacroTab = (props: any): ReactElement => {  
  const { data, editHandler, removehandler } = props;

return (
<Table
        dataSource={data}
        columns={MACRO_FIELDS_CONFIG(editHandler, removehandler)}
        rowKey={'id'}
        className="agreementsTable"
        pagination={false}
      />
)
}

export default MacroTab;