import React from "react";
import { Select } from 'antd';
import { productTypes } from '../../constants';
const { Option } = Select;

export default function ProductSelect(props: any) {

    const {setPropductType} = props;

    const handleChange = (row: { value: string; label: React.ReactNode }) => {
        if (setPropductType) setPropductType(+row.value)
      };

  return (
    <div className="stagePanel">
    <Select
        labelInValue
        defaultValue={{ value: '0', label: productTypes[0].name }}
        style={{ width: 120 }}
        onChange={handleChange}
        disabled={false}
    >
        { 
            productTypes.map(el => (
                <Option key={el.id} value={el.id}>{el.name}</Option>        
            ))
        }   
    </Select>
    </div>
  );
}
