import {FC, useCallback, useState} from 'react';
import s from './country_page.module.css';
import {Button} from 'antd';
import {Country, defaultCountriesDescrTabId, defaultCountriesPage} from '../../../../models/wiki';
import VerticalTabs from '../../../vertical_tabs';

interface Props {
  country: Country;
  setActiveCountriesPage: (value: any) => void;
}

const CountryPage: FC<Props> = ({country, setActiveCountriesPage}) => {
  const [activeDescrTabId, setActiveDescrTabId] = useState<number>(defaultCountriesDescrTabId);

  const onChange = useCallback((value: any) => {
    setActiveDescrTabId(value);
  }, []);

  const handleBackToMap = () => {
    setActiveCountriesPage(defaultCountriesPage);
  };

  const {title, subtitle, tabs} = country;
  const activeDescrTab = tabs.find((item) => item.id === activeDescrTabId)!;

  return (
    <div className={s.block}>
      <div className={s.tabsBlock}>
        <div className={s.tabs}>
          <VerticalTabs tabs={country.tabs} value={activeDescrTabId} onChange={onChange} selected={activeDescrTabId}/>
        </div>

        <div className={s.buttons}>
          <Button className={s.backButton} onClick={handleBackToMap}>
            Вернуться к карте
          </Button>
        </div>
      </div>

      <div>
        <div className={s.titleBlock}>
          <div className={s.title}>{title}</div>
          <div className={s.subtitle}>{subtitle}</div>
        </div>

        <div className={s.contentTitle}>{activeDescrTab.contentTitle}</div>
        <div className={s.content}>{activeDescrTab.content}</div>
      </div>
    </div>
  );
};

export default CountryPage;
