import news1 from "../assets/img/news1.png";
import news2 from "../assets/img/news2.png";
import news3 from "../assets/img/news3.png";
import news4 from "../assets/img/news4.png";
import news5 from "../assets/img/news5.png";
import news6 from "../assets/img/news6.png";
import news7 from "../assets/img/news7.png";
import news8 from "../assets/img/news8.png";
import news9 from "../assets/img/news9.png";
import news10 from "../assets/img/news10.png";
import news11 from "../assets/img/news11.png";
import news12 from "../assets/img/news12.png";
import news13 from "../assets/img/news13.png";
import news14 from "../assets/img/news14.png";
import news15 from "../assets/img/news15.png";
import news16 from "../assets/img/news16.jpg";
import news17 from "../assets/img/news17.jpg";
import news18 from "../assets/img/news18.jpg";
import news19 from "../assets/img/news19.jpg";
import news20 from "../assets/img/news20.jpg";
import news21 from "../assets/img/news21.jpg";
import news22 from "../assets/img/news22.jpg";
import news23 from "../assets/img/news23.jpg";
import news24 from "../assets/img/news24.jpg";
import news25 from "../assets/img/news25.jpg";
import news26 from "../assets/img/news26.jpg";
import news27 from "../assets/img/news27.jpg";
import news28 from "../assets/img/news28.jpg";
import news29 from "../assets/img/news29.jpg";
import news30 from "../assets/img/news30.jpg";
import news31 from "../assets/img/news31.jpg";
import news32 from "../assets/img/news32.jpg";
import news33 from "../assets/img/news33.jpg";
import news34 from "../assets/img/news34.jpg";
import news35 from "../assets/img/news35.jpg";
import news36 from "../assets/img/news36.jpg";
import news37 from "../assets/img/news37.jpg";
import news38 from "../assets/img/news38.jpg";

export const APP_STATE = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  ERROR: "ERROR"
};


export const ADMIN_USER_TOKEN_KEY = "adminUserToken";
export const CHECK_TOKEN_VALID_STATUS = 0;
export const USER_DATA_KEY = "userData";
export const MENU_DATA_KEY = "menuData";

export const DATE_FORMAT_YYYY_MM_DD_HH_MM_SS = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT_YYYY_MM_DDTHH_MM_SS = "YYYY-MM-DDTHH:mm:ss";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";

// Error messages
export const DEFAULT_ERROR_MESSAGE = "Что-то пошло не так";
export const REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE = "Обязательное поле";



export const countries = [
  {
    name: 'Все регионы',
    id: 0
  },
  {
    id: 1,
    name: "Валгалла"
  },
  {
    id: 2,
    name: "Гардарика"
  },
  {
    id: 3,
    name: "Парфия"
  },
  {
    id: 4,
    name: "Шривиджайя"
  },
  {
    id: 5,
    name: "Ассирия"
  }
];

export const productTypes = [
  {
    name: 'Все УВ',
    id: 0
  },
  {
    name: 'Нефть',
    id: 1
  },
  {
    name: 'Газ',
    id: 2
  }
]

export const newsTypes = [
  {
    name: 'Главные',
    id: 1
  },
  {
    name: 'Мировые',
    id: 2
  },
  {
    name: 'О Компании',
    id: 3
  },
  {
    name: 'По технологии',
    id: 4
  },
];

export const stage = [
  {
    title: 'Все стадии',
    id: 0
  },
  {
    title: 'Greenfield',
    id: 1
  },
  {
    title: 'Bluefield',
    id: 2
  },
  {
    title: 'Brownfield',
    id: 3
  }
]

export const actionTypes = {
  //лицензирование
  LicenseOrder: 1,
  //ГРР
  CarryOutGrr: 2,
  // Добыча
  StartDevelopment: 3,
  ChangeMiningLevel: 4,
  Liquidation: 5,
  Sell: 6,
  Stop: 8,
  ReStart: 9
}

export const techActionTypes = {
  postNewTech: 7,
  postEditedTech: 10,
  acceptNewTech: 4,
  acceptEditedTech: 5
}

export const reportTypes = [
  {
    id: 1,
    title: 'Отчетность'
  },
  {
    id: 2,
    title: 'Компания'
  },
  {
    id: 11,
    title: 'Валгалла'
  },
  {
    id: 12,
    title: 'Гардарика'
  },
  {
    id: 13,
    title: 'Парфия'
  },
  {
    id: 14,
    title: 'Шривиджайя'
  },
  {
    id: 15,
    title: 'Ассирия'
  },
]

export const newsImages = [
  {
    id: 1,
    src: news1
  },
  {
    id: 2,
    src: news2
  },
  {
    id: 3,
    src: news3
  },
  {
    id: 4,
    src: news4
  },
  {
    id: 5,
    src: news5
  },
  {
    id: 6,
    src: news6
  },
  {
    id: 7,
    src: news7
  },
  {
    id: 8,
    src: news8
  },
  {
    id: 9,
    src: news9
  },
  {
    id: 10,
    src: news10
  },
  {
    id: 11,
    src: news11
  },
  {
    id: 12,
    src: news12
  },
  {
    id: 13,
    src: news13
  },
  {
    id: 14,
    src: news14
  },
  {
    id: 15,
    src: news15
  },
  {
    id: 16,
    src: news16
  },
  {
    id: 17,
    src: news17
  },
  {
    id: 18,
    src: news18
  },
  {
    id: 19,
    src: news19
  },
  {
    id: 20,
    src: news20
  },
  {
    id: 21,
    src: news21
  },
  {
    id: 22,
    src: news22
  },
  {
    id: 23,
    src: news23
  },
  {
    id: 24,
    src: news24
  },
  {
    id: 25,
    src: news25
  },
  {
    id: 26,
    src: news26
  },
  {
    id: 27,
    src: news27
  },
  {
    id: 28,
    src: news28
  },
  {
    id: 29,
    src: news29
  },
  {
    id: 30,
    src: news30
  },
  {
    id: 31,
    src: news31
  },
  {
    id: 32,
    src: news32
  },
  {
    id: 33,
    src: news33
  },
  {
    id: 34,
    src: news34
  },
  {
    id: 35,
    src: news35
  },
  {
    id: 36,
    src: news36
  },
  {
    id: 37,
    src: news37
  },
  {
    id: 38,
    src: news38
  },
]