import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const TECHNOLOGIES_HISTORY_STORE_PATH = 'technologies_history';

const TechnologiesHistorySlice = createSlice({
    name: TECHNOLOGIES_HISTORY_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE,
        sendedSuccess: false,
    },
    reducers: {
        getTechnologiesHistoryStart (state) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        getTechnologiesHistorySuccess (store, action) {
            return {...store, history_data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        getTechnologiesHistoryError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },

        deleteTechnologiesItemStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        deleteTechnologiesItemSucces (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        deleteTechnologiesItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },


        updateTechnologiesItemStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        updateTechnologiesItemSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, sendedSuccess: true, error: null}
        },
        updateTechnologiesItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        closeModalSlice (store) {
            return {...store, sendedSuccess: false}
        },

    }
})

export const { 
    getTechnologiesHistoryStart, getTechnologiesHistorySuccess, getTechnologiesHistoryError,
    deleteTechnologiesItemStart, deleteTechnologiesItemSucces, deleteTechnologiesItemError, 
    updateTechnologiesItemStart, updateTechnologiesItemSuccess, updateTechnologiesItemError,
    closeModalSlice } = TechnologiesHistorySlice.actions;
export default TechnologiesHistorySlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${TECHNOLOGIES_HISTORY_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function historyTechnologiesSelector(state: any) {
    return get(state, `${TECHNOLOGIES_HISTORY_STORE_PATH}.history_data`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${TECHNOLOGIES_HISTORY_STORE_PATH}.error`);
}

export function sendedSuccessSelector(state: any) {
    return get(state, `${TECHNOLOGIES_HISTORY_STORE_PATH}.sendedSuccess`);
}