import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const NEWS_HISTORY_STORE_PATH = 'news_history';

const NewsHistorySlice = createSlice({
    name: NEWS_HISTORY_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE
    },
    reducers: {
        fetchNewsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchNewsSuccess (store, action) {
            return {...store, data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        fetchNewsError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error: 
                    action.payload.errors ? action.payload.errors.toString() : 
                    action.payload ? action.payload : 
                    'Ошибка сервера'}
        },
    }
})

export const { 
    fetchNewsStart, fetchNewsSuccess, fetchNewsError,
 } = NewsHistorySlice.actions;
 
export default NewsHistorySlice.reducer;
//не забываем редюсер добавить в рут- редюсер



/*export function isLoadingSelector(state: any) {
    return (
      get(state, `${LICENSING_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}*/

export function dataSelector(state: any) {
    return get(state, `${NEWS_HISTORY_STORE_PATH}.data`) || [];
}

export function technologiesSelector(state: any) {
    return get(state, `${NEWS_HISTORY_STORE_PATH}.tech`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${NEWS_HISTORY_STORE_PATH}.error`);
}