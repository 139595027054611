import React from "react";
import { IData } from "./Mining.interfaces";
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const MINING_ITEM_KEY: string = "id";

export const MINING_STORE_PATH: string = "mining";

export const actionTypes = {
  START_WORKING: 3,
  CHANGE_WORKING: 4,
}

export function Mining_Fields_Config({teams, user}: any): ColumnsType<IData> { 
  return ([
  {
    dataIndex: "id",
    title: "ID",
    className: "hiddenCol"
  },
  {
    dataIndex: "name",
    title: "Актив",
    align: "center",
  },
  {
    dataIndex: "product",
    title: "Тип УВ",
    align: "center",
  },
  {
    dataIndex: "astage",
    title: "Стадия актива",
    align: "center",
  },
  {
    dataIndex: "oper",
    title: "Оператор",
    align: "center",
    render: (_: any, row: any) => (
      +row.ownerId === user.teamId ? 
      <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p> 
      :
      <div className="d-flex">
        <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p>
        <Tooltip placement="right" title='Принимать решения по данному активу может только его оператор' >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      )
  },
  {
    dataIndex: "as",
    title: "Доля владения",
    align: "center",
    className: "th--percent"
  },
  {
    dataIndex: "pr",
    title: "Запасы, млн т.н.э.",
    align: "center",
  },
  {
    dataIndex: "openy",
    title: "Год открытия месторождения",
    align: "center",
  },
  {
    dataIndex: "devy",
    title: "Год старта разработки",
    align: "center",
  },
  {
    dataIndex: "prody",
    title: "Год начала добычи",
    align: "center",
  },
  {
    dataIndex: "sapa",
    title: "Накопленная добыча, млн т.н.э.",
    align: "center",
  },
  {
    dataIndex: "scapex",
    title: "CAPEX накопленный",
    align: "center",
    className: "th--mlnd"
  },
  {
    dataIndex: "capex",
    title: "CAPEX полный",
    align: "center",
    className: "th--mlnd"
  },
  {
    dataIndex: "npvfc",
    title: "NPV (FC)",
    align: "center",
    className: "th--mlnd"
  },
  {
    dataIndex: "npvpf",
    title: "NPV (PF)",
    align: "center",
    className: "th--mlnd"
  },
])};


