import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { Table, Tabs } from 'antd';
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { teamsSelector, userSelector, getUsersStart, yearSelector } from "../../../store/appSlice";
import { saveTechnologiesItemStart, sendedSuccessSelector, closeModalSlice } from "../redux/TechnologiesSlice";
import { techActionTypes } from '../../../constants';

const TechModal = (props: any): ReactElement => {  
  const dispatch = useDispatch();
  const { showModal, setShowModal, row, isNew } = props;

  const [activeTab, setActiveTab] = useState("1");
  const [percentValue, setPercentValue] = useState<number | string>('');
  const [myPercentValue, setMyPercentValue] = useState<number | string>('');
  const [selectedComandId, setSelectedComandId] = useState(-1);
  const [yearStartValue, setYearStartValue] = useState<number | string>('');
  const [yearEndValue, setYearEndValue] = useState<number | string>('');
  const [priceValue, setPriceValue] = useState<number | string>('');
  const [comandsData, setComandsData] = useState<any[]>([]);

  const baseYear = useSelector(yearSelector);
  const teams = useSelector(teamsSelector);
  const user = useSelector(userSelector);
  const sendedSuccess = useSelector(sendedSuccessSelector);
  const myUserId = user?.id || 0;

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    if (baseYear && baseYear !== yearStartValue) setYearStartValue(baseYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, teams, baseYear])

  const usedComands = useMemo(()=> {
    return [myUserId, ...comandsData.map(el => el.userId)]
  }, [comandsData, myUserId])

  useEffect(()=> {
    if (sendedSuccess) {
      setShowModal(false);
      clearValues();
      dispatch(closeModalSlice());
    }
  }, [sendedSuccess])
  
  const clearValues = () => {
    setYearStartValue(baseYear);
    setYearEndValue('');
    setPriceValue('');
    setPercentValue('');
    setMyPercentValue('');
    setComandsData([]);
  }

  const handleChangeCancel = () => {
    setShowModal(false);
    clearValues();
  }

  const addComandHandler = () => {
    const newItem = {
      id: isEmpty(comandsData) ? 0 : comandsData[comandsData.length - 1].id +1,
      userId: selectedComandId,
      name: teams.find((el: any) => el.id === selectedComandId)!.teamName,
      percent: percentValue,
    }
    setSelectedComandId(-1);
    setComandsData([...comandsData, newItem])
  }

  const removeComandhandler = (row: any) => {
    const newComands = comandsData.filter(el => el.id !== row.id);
    setComandsData(newComands);
  }

  const requestData = useMemo(()=> {
    const teamItems = activeTab === '1' ? [{"userId": user?.id,"percent": 100}] : [{"userId": user?.id,"percent": myPercentValue}, ...comandsData.map((el: any) => ({"userId": el.userId, "percent": el.percent}))];
    let years = [];
    for (let i = yearStartValue as number; i <= yearEndValue; i++) { 
      years.push(i);
    };
    const yearItems = years.map((el: any) => ({"year": el,"amount": priceValue}));
    return {
      techId: row?.id,
      teamItems,
      yearItems,
      actionType: isNew ? techActionTypes.postNewTech : techActionTypes.postEditedTech
    }
  }, [user, activeTab, comandsData, myPercentValue, row, yearStartValue, yearEndValue, priceValue])

  const sendTechnologiesHandler = () => {
    dispatch(saveTechnologiesItemStart(requestData));
    //setShowModal(false);
    //clearValues();
  }

  const changeComandSelectHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedComandId(+e.target.value)
  }
  const changeYearStartHandler = (e: React.BaseSyntheticEvent) => {
    setYearStartValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }
  const changeYearEndHandler = (e: React.BaseSyntheticEvent) => {
    setYearEndValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }
  const changePriceHandler = (e: React.BaseSyntheticEvent) => {
    setPriceValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }
  const changePercentHandler = (e: React.BaseSyntheticEvent) => {
    setPercentValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }
  const changeMyPercentHandler = (e: React.BaseSyntheticEvent) => {
    setMyPercentValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const TECHNOLOGIES_MODAL_COMANDS_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'name',
      title: 'Команда'
    },
    { 
      dataIndex: 'percent',
      title: 'Доля участия, %'
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <Button variant="info" onClick={() => removeComandhandler(row)}>
          Удалить
        </Button>)},
    
  ];

return (
  <>
    <Modal show={showModal} onHide={handleChangeCancel} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Параметры инвестирования</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Tabs defaultActiveKey={activeTab} onChange={key=>{setActiveTab(key)}} >

          <Tabs.TabPane tab="Индивидуальная заявка" key="1">
            <Row className="licensing--actionModal--title">
                <Row>
                    <Col xs={12}>
                        <p>Название технологии</p>
                        <Form.Control value={row?.name || ''} disabled={true} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <p>Год начала</p>
                        <Form.Control
                            onChange={changeYearStartHandler}
                            //onBlur={singlePriceValidator}
                            value={yearStartValue}
                            type="number"
                        />
                    </Col>
                    <Col xs={4}>
                        <p>Год окончания</p>
                        <Form.Control
                            onChange={changeYearEndHandler}
                            //onBlur={singlePriceValidator}
                            value={yearEndValue}
                            type="number"
                        />
                    </Col>
                    <Col xs={4}>
                        <p>Инвестирование ежегодно, млн.$</p>
                        <Form.Control
                            onChange={changePriceHandler}
                            //onBlur={singlePriceValidator}
                            value={priceValue}
                            type="number"
                        />
                    </Col>
                    {/*<Col xs={3}>
                        <Button variant="success" onClick={addYearHandler} disabled={!yearValue || !priceValue} className="technology--modal__addButton">
                            Добавить
                        </Button>
                    </Col>*/}
                </Row>
            </Row>
            {/*<Table 
                dataSource={yearsData} 
                columns={TECHNOLOGIES_MODAL_YEARS_FIELDS_CONFIG} 
                rowKey='id'
                size='small'
                className='technologiesModalTable'
                pagination={false}
                  />*/}
          </Tabs.TabPane>


          <Tabs.TabPane tab="Коллективная заявка" key="2">
          <Row className="licensing--actionModal--title">
                <Row>
                    <Col xs={12}>
                        <p>Название технологии</p>
                        <Form.Control value={row?.name || ''} disabled={true} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <p>Год начала</p>
                        <Form.Control
                            onChange={changeYearStartHandler}
                            //onBlur={singlePriceValidator}
                            value={yearStartValue}
                            type="number"
                        />
                    </Col>
                    <Col xs={4}>
                        <p>Год окончания</p>
                        <Form.Control
                            onChange={changeYearEndHandler}
                            //onBlur={singlePriceValidator}
                            value={yearEndValue}
                            type="number"
                        />
                    </Col>
                    <Col xs={4}>
                        <p>Инвестирование ежегодно, млн.$</p>
                        <Form.Control
                            onChange={changePriceHandler}
                            //onBlur={singlePriceValidator}
                            value={priceValue}
                            type="number"
                        />
                    </Col>
                    {/*<Col xs={3}>
                        <Button variant="success" onClick={addYearHandler} disabled={!yearValue || !priceValue} className="technology--modal__addButton">
                            Добавить
                        </Button>
                    </Col>*/}
                </Row>
                {/*<Table 
                    dataSource={yearsData} 
                    columns={TECHNOLOGIES_MODAL_YEARS_FIELDS_CONFIG} 
                    rowKey='id'
                    size='small'
                    className='technologiesModalTable'
                    pagination={false}
                  />*/}
          </Row>
          <Row>
            <Col xs={3}>
              <p className="licensing--actionModal--myTeam__title">Моя команда</p>
            </Col>
            <Col xs={3}>
              <p>Доля участия, %</p>
              <Form.Control 
                  onChange={changeMyPercentHandler}
                  //onBlur={myPercentValidator}
                  min={1}
                  max={100}
                  value={myPercentValue}
                  type="number"/>
            </Col>
            <Col xs={3}>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <p>Команда</p>
              <Form.Select value={selectedComandId} onChange={changeComandSelectHandler}>
                  <option value={-1} key={-1}>Выберите</option>
                  {teams.map((el: any) => (<option value={el.id} key={el.id} disabled={usedComands.includes(el.id)}>{el.teamName}</option>))}
              </Form.Select>
            </Col>
            <Col xs={3}>
              <p>Доля участия, %</p>
              <Form.Control 
                  onChange={changePercentHandler}
                  //onBlur={percentValidator}
                  min={1}
                  max={100}
                  value={percentValue}
                  type="number"/>
            </Col>
            <Col xs={3}>
              <Button variant="success" onClick={addComandHandler} disabled={!percentValue || selectedComandId < 0} className="technology--modal__addButton">
                Добавить команду
              </Button>
            </Col>
          </Row>
          <Row>
          <Table 
            dataSource={comandsData} 
            columns={TECHNOLOGIES_MODAL_COMANDS_FIELDS_CONFIG} 
            rowKey='id'
            className='technologiesModalTable'
            pagination={false}
            size='small'
          />
          </Row>
          </Tabs.TabPane>
        </Tabs>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={sendTechnologiesHandler} >
            Инвестировать
          </Button>
          <Button variant="secondary" onClick={handleChangeCancel}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
  </>
);}

export default TechModal;