import {FC} from 'react';
import s from './vertical_tabs.module.css';
import cn from 'classnames';
import {Radio, RadioChangeEvent} from 'antd';

interface Props {
  tabs: any[];
  value: any;
  selected: number;
  onChange: (value: any) => void;
}

const VerticalTabs: FC<Props> = ({tabs, value, selected, onChange}) => {
  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
  };

  return (
    <Radio.Group onChange={handleChange} value={value} className={s.descrPanel}>
      {tabs.map((tab) => (
        <Radio.Button key={tab.id} value={tab.id} className={+selected === +tab.id ? cn(s.descrPanelElement, s.wikiButtonChecked) : cn(s.descrPanelElement)}>
          {tab.tabTitle}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default VerticalTabs;
