import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const ADD_NEWS_STORE_PATH = 'add_news';

const AddNewsSlice = createSlice({
    name: ADD_NEWS_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE
    },
    reducers: {
        fetchNewsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchNewsSuccess (store, action) {
            return {...store, data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        fetchNewsError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error: 
                    action.payload.errors ? action.payload.errors.toString() : 
                    action.payload ? action.payload : 
                    'Ошибка сервера'}
        },

        addNewsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        addNewsSucces (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        addNewsError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },

        fetchTechnologiesStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchTechnologiesSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, tech: action.payload, error: null}
        },
        fetchTechnologiesError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: 
                action.payload.errors ? action.payload.errors.toString() : 
                action.payload ? action.payload : 
                'Ошибка сервера'}
        },

        deleteNewsStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null,}
        },
        deleteNewsSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        deleteNewsError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },

        updateNewsStart (state, action) {
            return{...state, status: APP_STATE.LOADING, saved: false, error: null,}
        },
        updateNewsSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, saved: true}
        },
        updateNewsError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },

        clearTechnologies (store) {
            return {...store, tech: []}
        }
    }
})

export const { 
    fetchNewsStart, fetchNewsSuccess, fetchNewsError,
    addNewsStart, addNewsSucces, addNewsError, 
    fetchTechnologiesStart, fetchTechnologiesSuccess, fetchTechnologiesError,
    deleteNewsStart, deleteNewsSuccess, deleteNewsError,
    updateNewsStart, updateNewsSuccess, updateNewsError,
    clearTechnologies
 } = AddNewsSlice.actions;
 
export default AddNewsSlice.reducer;
//не забываем редюсер добавить в рут- редюсер



/*export function isLoadingSelector(state: any) {
    return (
      get(state, `${LICENSING_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}*/

export function dataSelector(state: any) {
    return get(state, `${ADD_NEWS_STORE_PATH}.data`) || [];
}

export function technologiesSelector(state: any) {
    return get(state, `${ADD_NEWS_STORE_PATH}.tech`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${ADD_NEWS_STORE_PATH}.error`);
}