import * as React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import SideMenu from "../../components/SideMenu";
import { sideMenuItems } from "../../constants/sideMenuItems";
import "./BaseWrapper.css";

export default function BaseWrapper(props) {
  const { user, children, menudata } = props;
  const [isMenuOpened, setIsMenuOpened] = useState(true);

  function toggleMenu() {
    setIsMenuOpened(!isMenuOpened);
  }

  return (
    <>
      {user && (
        <Header
          toggleMenu={toggleMenu}
          isMenuOpened={isMenuOpened}
          user={user}
        />
      )}
      <Container fluid className="basewrapper__container">
        <Row className="basewrapper__container__row">
          <Col className="basewrapper__container__col">
            {user && (
              <SideMenu
                isMenuOpened={isMenuOpened}
                sideMenuItems={sideMenuItems}
                menudata={menudata}
              />
            )}
          </Col>
          <Col className="basewrapper__body">{children}</Col>
        </Row>
      </Container>
    </>
  );
}
