import { takeLatest, fork, put, call} from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
    fetchMiningTableStart, fetchMiningTableSuccess, fetchMiningTableError, 
    saveMiningItemStart, saveMiningItemSuccess, saveMiningItemError,
 } from './MiningSlice';

function* fetchMiningTableData (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'AssetMining',
            payload: action.payload
          });
        yield put(fetchMiningTableSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(fetchMiningTableError(error))
    }
}

function* saveMiningItem (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
          apiMethod: 'MiningActions',
          payload: action.payload
        });
      yield put(saveMiningItemSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(saveMiningItemError(error))
  }
}


function* fetchMiningDataWatcher () {
    yield takeLatest(fetchMiningTableStart.type, fetchMiningTableData)
}

function* saveMiningItemWatcher () {
  yield takeLatest(saveMiningItemStart.type, saveMiningItem)
}


export const MiningSagas = [fork(fetchMiningDataWatcher), fork(saveMiningItemWatcher)];
//не забываем добавлять сагу в рут- сагу