import { Table } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { IMPLEMENTED_FIELDS_CONFIG } from '../Technologies.constants';
import { useSelector, useDispatch } from 'react-redux';
import { teamsSelector, getUsersStart, userSelector } from "../../../store/appSlice";

const ImplementedTab = (props: any): ReactElement => {  
  const { data} = props;
  const dispatch = useDispatch();
  /*const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    type: 'radio' as 'radio', //вот такая типизация в antd - это странно выглядит но работает
    onChange: onSelectChange,
  };*/

  const teams = useSelector(teamsSelector);
  const user = useSelector(userSelector);

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
  }, [dispatch, teams])

return (
    <div className='technologies--implemented'>
      <h4>Ознакомьтесь с реализованными технологиями и их эффектами</h4>
      <Table 
          dataSource={data} 
          columns={IMPLEMENTED_FIELDS_CONFIG({teams, user})} 
          rowKey={'id'}
          className='TechnologiesTable'
          //rowSelection={rowSelection}
          pagination={false}
          /*onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  setSelectedRowKeys([record.id])
                }, 
              };
            }}*/
      />
    </div>
)}

export default ImplementedTab;