import React, { ReactElement, useState, useEffect} from "react";
import { Row, Col } from 'react-bootstrap';
import { Area, Line, Column, Pie } from '@ant-design/charts';

export default function ReportsGraph(props: any): ReactElement {
  const {data } = props;
  const [donutWidth, setDonutWidth] = useState(260);

  const production = data?.production || [];
  const fcf = data?.fcf || [];
  const capex = data?.capex || [];
  const taxes = data?.taxes || [];
  const donut1 = data?.donut1 || [];
  const donut2 = data?.donut2 || [];

  window.onresize = () => {
    const width = document.querySelector('.reports--graph__block--donut')?.getBoundingClientRect().width || 260;
    setDonutWidth(width)
  }

  useEffect(()=> {
    const width = document.querySelector('.reports--graph__block--donut')?.getBoundingClientRect().width || 260;
    setDonutWidth(width)
  }, [])
  

  const configProduction = {
    data: production,
    xField: 'year',
    yField: 'value',
    seriesField: 'name',
    height: 300,
    areaStyle: {
      fillOpacity: 1,
    },
    legend: {
      layout: 'horizontal' as 'horizontal',
      position: 'top' as 'top',
    },
    color: ['#002060', '#5B9BD5'],
  };

  const configFCF = {
    data: fcf,
    xField: 'year',
    yField: 'value',
    seriesField: 'name',
    smooth: true,
    color: ['#5B9BD5'],
    annotations: [
      {
        type: 'line',
        start: ['min', '0'] as [string, string],
        end: ['max', '0'] as [string, string],
        style: {
          lineWidth: 2,
          stroke: '#a0a0a0'
        },
      }
    ],
    legend: false as {},    //Он очень хочет увидеть legend config как объект
    height: 300,
  }

  const configCapex = {
    data: capex,
    isStack: true,
    xField: 'year',
    yField: 'value',
    seriesField: 'name',
    height: 300,
    legend: {
      layout: 'horizontal' as 'horizontal',
      position: 'top' as 'top',
    },
    color: ['#4682B4', '#FFD700', '#3CB371'],
  }

  const configDonut1 = {
    data: donut1,
    angleField: 'value',
    colorField: 'name',
    height: 300,
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      autoRotate: false,
      style: {
        textAlign: 'center',
        fontSize: 18,
      },
    },
    color: ['#002060', '#5B9BD5'],
    legend: {
      layout: 'vertical' as 'vertical',
      position: 'left' as 'right',
      offsetX: donutWidth * 0.62,
      
    },
    statistic: {
      title: false as false,
      content: {
        content: '',
      },
    },
  };

  return (
      <div className="reports--graph">
        <Row>
            <Col xs={6}>
                <div className="reports--graph__block reports--graph__block--double">
                    <h5>Добыча, млн тнэ</h5>
                    <Area {...configProduction} />
                </div>
            </Col>
            <Col xs={6}>
              <Row>
                <Col xs={6}>
                <div className="reports--graph__block reports--graph__block--donut">
                    <h5>Запасы, млн тнэ</h5>
                    <Pie {...configDonut1} style={{marginLeft: `-${donutWidth * 0.25}px`}}/>
                </div>
                </Col>
                <Col xs={6}>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>Обеспеченность запасами, лет</h5>
                    <h1>{data.resratio}</h1>
                  </div>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>Коэф. восполнения запасов</h5>
                    <h1>{data.resrepratio}</h1>
                  </div>
                </Col>
              </Row>
            </Col>
        </Row>
        <Row>
          <Col xs={6}> 
            <div className="reports--graph__block reports--graph__block--double">
                <h5>FCF, млн $</h5>
                <Line {...configFCF} />
            </div>
          </Col>
          <Col xs={6}>
          <Row>
              <Col xs={6}>
                <div className="reports--graph__block reports--graph__value_block">
                    <h5>Цена акции, $</h5>
                    <h1>{data.shareprice}</h1>
                  </div>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>Дивиденды на акцию, %</h5>
                    <h1>{data.divpershare}</h1>
                  </div>
              </Col>
              <Col xs={6}>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>Уровень диверсификации, %</h5>
                    <h1>{data.diverlevel}</h1>
                  </div>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>ROACE, %</h5>
                    <h1>{data.roace}</h1>
                  </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className="reports--graph__block reports--graph__block--double">
                <h5>CAPEX, млн $</h5>
                <Column {...configCapex} />
            </div>
          </Col>
          <Col xs={6}>
              <Row>
                <Col xs={6}>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>EBITDA, млн $</h5>
                    <h1>{data.ebitdAtotal}</h1>
                  </div>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>Чистая прибыль, млн $</h5>
                    <h1>{data.netprofit}</h1>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>Долг / EBITDA</h5>
                    <h1>{data.debtebitda}</h1>
                  </div>
                  <div className="reports--graph__block reports--graph__value_block">
                    <h5>Долг / Капитал</h5>
                    <h1>{data.debtequity}</h1>
                  </div>
                </Col>
              </Row>
            </Col>
        </Row>
      </div>
  );
}
