import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { updateUsersStart, updateUsersSuccess, updateUsersError } from './UsersSlice';
import { getUsersStart } from "../../../store/appSlice";

function* updateUser (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
          apiMethod: 'admin/users',
          payload: action.payload
        });
      yield put(updateUsersSuccess(response))
      yield put(getUsersStart())
  } catch (error) {
      console.log(error)
      yield put(updateUsersError(error))
  }
}

function* updateUserWatcher () {
    yield takeLatest(updateUsersStart.type, updateUser)
}


export const UsersSagas = [fork(updateUserWatcher), ];
//не забываем добавлять сагу в рут- сагу