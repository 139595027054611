import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Notification from '../../components/Notification';
import { Row, Col } from 'react-bootstrap';
import { fetchDataStart, dataSelector, errorSelector } from "./redux/ReportsGraphRegionsSlice";
import './reportsGraphRegions.css';
import { isEmpty } from 'lodash';
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import ProductTypeSelect from '../../components/ProductTypeSelect/ProductSelect';
import ReportsGraph from './components/ReportsGraph';
//import 'material-design-icons/iconfont/material-icons.css';

const ReportsGraphRegions = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const data = useSelector(dataSelector);
  const [regionId, setRegionId] = useState(0);
  const [productType, setPropductType] = useState(0);

  useEffect(()=> {
    dispatch(fetchDataStart({regionId, productType}))
  }, [dispatch, regionId, productType])
  
  
  return (
    <>
      <Row>
        <Col xs={1} style={{minWidth: '150px'}}>
          <CountrySelect setRegionId={setRegionId} />
        </Col>
        <Col xs={1} style={{minWidth: '150px'}}>
          <ProductTypeSelect setPropductType={setPropductType} />
        </Col>
      </Row>
      <Notification success={false} error={error} />
      {!isEmpty(data) && <ReportsGraph data={data} regionId={regionId}/>}
    </>
  )
};

export default ReportsGraphRegions;