import {FC, useCallback, useState} from 'react';
import s from './index.module.css';
import {Companies as ICompanies} from '../../../models/wiki';
import VerticalTabs from '../../vertical_tabs';
import {Button} from 'antd';

enum CompaniesId {
  Common = 1,

  Gasolina = 2,
  BigLight = 3,
  AtomNuc = 4,
  ForeignAffairsInitiatives = 10,
  MadrugalElectromotive = 11,
  PureEnergyHeritageFond = 12,

  Ministers = 5,
  Shareholders = 6,
  Experts = 7,
  Green = 8,
  Organizations = 9,
}
const defaultCompaniesId = CompaniesId.Common;

const companies: ICompanies = {
  defaultTabs: [
    {
      id: CompaniesId.Common,
      tabTitle: '',
      contentTitle: 'Общая информация',
      contentSubtitle: '',
      content: (
        <>
          В своей деятельности Ваша компания столкнется с другими компаниями, которыми не управляют ваши соперники по игре. Из новостной
          ленты вы, возможно, узнаете о событиях, которые происходят с другими неигровыми компаниями (выигрышах на лицензионных торгах,
          покупках активов и M&A, инвестициях и технологическом развитии, банкротствах или успешных переговорах…). Однако при этом вы не
          будете ощущать непосредственного конкурентного давления с их стороны.
          <br />
          <br />
          Периодически в ходе игры будут появляться разные персонажи, с которыми Ваша команда, возможно, будет взаимодействовать. В ходе
          общения с ними вы сможете:
          <br />
          <ul>
            <li>получить новую информацию по интересующему вопросу</li>
            <li>получить новые вводные для учета в работе</li>
            <li>достичь определенных договоренностей по вопросам в сфере их ответственности и пр.</li>
          </ul>
        </>
      ),
    },
  ],
  companiesTabs: [
    {
      id: CompaniesId.Gasolina,
      tabTitle: 'GASOLINA',
      contentTitle: 'GASOLINA',
      contentSubtitle: '',
      content: (
        <>
          Одна из крупнейших и успешнейших компаний на рынке энергетики, основной вид деятельности – транспортировка и макетирование
          природного газа в США. Была образована в 1980-ые годы и к текущему моменту сильно диверсифицировала свой бизнес, войдя в сегменты
          электрогенерации, строительного бизнеса и сопутствующих производств.
        </>
      ),
    },
    {
      id: CompaniesId.BigLight,
      tabTitle: 'BIG LIGHT',
      contentTitle: 'BIG LIGHT',
      contentSubtitle: '',
      content: (
        <>
          Одна из крупнейших технологический и производственных компаний США. Основана в 1901 году, за время существования занималась
          различными направлениями бизнеса: добычей и переработкой нефти и газа, производством электроники, энергетическим и транспортным
          машиностроением, медицинским оборудованиям и др.
        </>
      ),
    },
    {
      id: CompaniesId.AtomNuc,
      tabTitle: 'ATOM NUC',
      contentTitle: 'ATOM NUC',
      contentSubtitle: '',
      content: (
        <>
          Практически единоличный лидер в атомной энергетике в мире. Единственная компания объединяющая полный цикл в атомной отрасли.
          Включает разветвленную сеть промышленных предприятий в разных сегментах экономики: добыче и переработке ядерного топлива,
          энергетическом машиностроении, строительстве АЭС, управлении радиоактивными отходами и сопутствующих производствах. Ведет
          деятельность по всему миру, штаб-квартира располагается в Гардарике.
        </>
      ),
    },
    {
      id: CompaniesId.ForeignAffairsInitiatives,
      tabTitle: 'Foreign Affairs Initiatives Ltd',
      contentTitle: 'Foreign Affairs Initiatives Ltd',
      contentSubtitle: '',
      content: (
        <>
          Крупная транснациональная компания со штаб квартирой в Лондоне (Великобритания), специализирующаяся на аналитической и консультационной 
          работе на межгосударственном уровне. Компания существует и успешно развивается на протяжении более 150 лет. Первое официальное упоминание 
          FAIL датируется с момента оказания юридических услуг Британской Ост-Индской Компани. 
          <br />
          <br />
          За последние 20 лет компания FAIL успешно сотрудничала с рядом государств Ближнего Востока по привлечению финансирования МВФ на развитие 
          гражданского общества и демократических институтов власти. Также удалось реализовать масштабную программу на Африканском Континенте по 
          реформированию аграрного сектора и сектора добычи редкоземельных металлов. Результатом программы реформ стало привлечение западных инвестиций 
          и высокотехнологичных компаний для увеличения посевных площадей и строительства экспортно-ориентированных обогатительных комбинатов. 
          <br />
          <br />
          Одним из наиболее значимых проектов, по словам президента Компании Бенедикта Ролоса, является участие в реализации комплексной программы 
          приватизации собственности республики Гардарики, направленной на развитие частного капитала и привлечение иностранных инвестиций.
          <br />
          <br />
          В настоящее время компания FAIL активно вовлечена в реализацию ряда лоббистских инициатив: по увеличению экспорта оружия в страны 
          Африканского континента для поддержания высокого уровня демократии и гражданских свобод, а также получению разрешения на проведение масштабных 
          ГРП в особо охраняемой территории природного заповедника одного из Восточно-Европейских государств.
          <br />
          <br />
          Несмотря на достаточно редкое освещение Компанией FAIL своей активности в официальных пресс-релизах, старший вице-президент по реализации 
          крупных проектов Джером Видгеройт эффективно продвигает интересы Компании на различных форумах и отраслевых конференциях, активно участвует 
          в коммуникациях неформального характера. Джером долгое время работал в администрации Белого дома, несколько лет занимал пост главного спичрайтера 
          президента США и формируя общую стратегию коммуникаций и PR.
        </>
      ),
    },
    {
      id: CompaniesId.MadrugalElectromotive,
      tabTitle: 'Madrugal Electromotive',
      contentTitle: 'Madrugal Electromotive',
      contentSubtitle: '',
      content: (
        <>
          Становление компании началось в 1952 году с сети фаст-фуд ресторанов Кесо Манчего в пригороде Гамбурга, основанной Хорхе Суаресом, 
          бедным крестьянином из мексиканской провинции Синалоа и иммигрировавшим в послевоенную Германию. Благодаря настойчивости и удаче компания за 
          короткий промежуток времени трансформировалась в конгломерат Madrugal, работающий в области энергетического, промышленного, медицинского оборудования 
          и услуг связи. Германия является корпоративной штаб-квартирой корпорации Madrugal. Производственные мощности есть в Норвегии, Бразилии, Китае, 
          Индии, Пакистане. Фабрики концерна неоднократно получают престижную премию «Лучшая фабрика» и организуют на своих производствах более 100 тысяч рабочих мест.
          <br />
          <br /> 
          Эффективная стратегия и диверсификация бизнесов позволили увеличить годовой оборот корпорации до сотен миллиардов Евро. При этом Компания 
          по-прежнему отказывается выходить на фондовый рынок и публикует только часть своей отчетности, а публично известный список акционеров, помимо 
          семьи Суарес, включает несколько трастов, зарегистрированных в Лихтенштейне. Леонель Суарес, нынешний глава и внук основателя Madrugal, подчеркивает, 
          что ему неинтересно делиться своими достижениями и проблемами «с алчными и бессовестными шакалами с фондового рынка».
          <br />
          <br />
          Основными подразделениями по выручке являются тяжелое машиностроение, железнодорожный и городской электротранспорт, производство диагностического 
          и терапевтического медицинского оборудования, а также биохимическая промышленность. Отдельным направлением выступает оказание услуг по технологическому 
          консалтингу в области рискованного научно-технического бизнеса.
          <br />
          <br />
          Одним из многочисленных проектов является поставка оборудования для самой протяжённой в мире радиорелейной трассы на территории Ассирии. 
          Неоднозначный контракт с Гардарикой на поставку и обслуживание высокоскоростных поездов.  
          <br />
          <br />
          Также Madrugal неоднократно подвергается обвинениям в связи с дачей взяток для получения выгодных сделок, нарушении санкций и картельных сговорах с 
          другими производителями. Однако ни один из этих «предположений», так и не был доказан в суде, тогда как значительные суммы, направляемые на борьбу с 
          голодом в Африке и развитие базовых медицинских сервисов в бедных странах, являются общепризнанным фактом. В 1998 году Компании получила престижную 
          премию за свой вклад в развитие и пропаганду вакцинирования в мире. 
          <br />
          <br />
          Мигель Суарес, занимающий пост директора по корпоративным коммуникациям считает это происками конкурентов и в своих социальных сетях транслирует 
          вовлеченность и активное участие Компании, как спонсорство в таких видах спорта как Формула-1 и горный велосипед.
        </>
      ),
    },
    {
      id: CompaniesId.PureEnergyHeritageFond,
      tabTitle: 'Pure Energy Heritage Fond (PEHF)',
      contentTitle: 'Pure Energy Heritage Fond (PEHF)',
      contentSubtitle: '',
      content: (
        <>
          Pure Energy Heritage – некоммерческая организация (фонд), специализирующаяся на изучении проблематики изменения климата (в т.ч. вследствие 
          «парникового эффекта»), продвижении использованию ВИЭ, охране уникальных экосистем Арктической зоны. 
          <br />
          <br />
          За 30 лет своего существования, фонд Pure Energy Heritage с момента своего создания из группы энтузиастов в количестве 12 человек с 
          арендуемым офисом на окраине, эволюционировал в международный фонд с широкой сетью своих представительств (более 80-ти) во всех крупных странах 
          Западной и Восточной Европы, Северной Аиерики, Ближнего Востока и АТР, с общей численностью сотрудников порядка 30 тысяч, со штаб квартирой в центре Вальгаллы.
          <br />
          <br />
          Финансирование деятельности фонда осуществляется за счет взносов неправительственных международных организаций, прямых бюджетных дотаций государства и 
          возросших за последнее время добровольных взносов от представителей ответственного бизнеса.
          <br />
          <br />
          В последние годы фонд уделяет особое внимание борьбе с добычей, переработкой и использованием ископаемого топлива и активно борется с нефтегазовыми 
          компаниями, которые по мнению фонда игнорируют эко повестку и не инвестируют в развитие ВИЭ для снижения выбросов CO2. 
          <br />
          <br />
          Так, за последние несколько лет, эко активисты фонда организовали ряд масштабных акций протеста на нескольких платформах на шельфе ряда крупных 
          иностранных нефтяных компаний, получивших в народе название «Великого шельфового противостояния». В рамках протеста сотрудники фонда приварили обмотанных 
          цепями молодых эко энтузиастов к выдвижным опорам плавучих буровых установок, что позволило парализовать работу буровых на несколько суток и привело к 
          многомиллионным убыткам компаний. 
          <br />
          <br />
          По словам руководителя фонда Йорика Йестина, после великого шельфового противостояния многие нефтяные компании, работающие на шельфе, значительно 
          увеличили свои отчисления в фонд и направили своих представителей для координации совместной работы над проектами ВИЭ. Сам Йорик Йестин более 15 лет 
          работал в нефтяной компании, в качестве главного инженера, однако 10 лет назад после аварии на буровой платформе на шельфе Северного моря, увидев последствия 
          техногенной катастрофы разочаровался в нефтяном бизнесе и решил посвятить свою жизнь охране окружающей среды.
        </>
      ),
    },
  ],
  charactersTabs: [
    {
      id: CompaniesId.Ministers,
      tabTitle: 'Министры',
      contentTitle: 'Министры',
      contentSubtitle: 'Нефти, энергетики и другие',
      content: (
        <>
          Это представители органов власти в разных регионах мира, которые будут устанавливать правила игры на рынках стран, таким образом
          оказывать серьезное регуляторное влияние на условия ведения текущего бизнеса. Их основные сферы деятельности: лицензирование,
          добыча, фискальная политика.
          <br />
          <br />
          Более подробную информацию о министрах стран можно получить в разделе «Страны».
        </>
      ),
    },
    {
      id: CompaniesId.Shareholders,
      tabTitle: 'Акционеры и совет директоров',
      contentTitle: 'Акционеры компании и совет директоров',
      contentSubtitle: '',
      content: (
        <>
          Вы уже познакомились с Гарри Блинком из White Stone, крупнейшим акционером компании и влиятельным бизнесменом. В дальнейшем вы
          столкнетесь и с другими стейкхолдерами вашей компании. Учет их интересов и выполнение поставленных целей является вашей
          первоочередной задачей. Будьте готовы к отчетам о ваших успехах и неудачах, обсуждению ваших стратегий, а также требований
          акционеров и совета директоров.
        </>
      ),
    },
    {
      id: CompaniesId.Experts,
      tabTitle: 'Отраслевые эксперты',
      contentTitle: 'Отраслевые эксперты',
      contentSubtitle: '',
      content: (
        <>
          Специалисты в различных сферах: бизнесе, экономике, технологиях и других областях знаний, они помогут получить дополнительную
          информацию по интересующим вопросам. Без их прогнозов и глубокого понимания темы вам будет сложно принять взвешенное решение.
        </>
      ),
    },
    {
      id: CompaniesId.Green,
      tabTitle: 'Движение "зеленых"',
      contentTitle: 'Движение "зеленых"',
      contentSubtitle: '',
      content: (
        <>
          Представители данного движения являются проводниками идей, связанных с экологией, защитой окружающей среды, «зеленой» повесткой и
          принципами ESG. Это не просто эко-активисты и фанаты дикой природы, это состоявшееся и организованное движение. Протекающий
          энергопереход будет только усиливать их позиции и активность, в связи с чем они могут стать заметной силой наравне с бизнесом и
          властью, оказывающий существенное влияние на правила игры.
        </>
      ),
    },
    {
      id: CompaniesId.Organizations,
      tabTitle: 'Международные организации',
      contentTitle: 'Международные организации',
      contentSubtitle: '',
      content: (
        <>
          Представители различных международных организаций, в том числе занимающихся сотрудничеством между государствами и обеспечением
          общего мирового блага, время от времени будут транслировать рекомендации и инициативы, поддержанные мировым сообществом. Хотя
          следование новым принципам носит добровольный характер, зачастую необходимость их исполнения можно описать фразой «не обязательно,
          но настоятельно рекомендуется» … Стоит это учитывать в своей деятельности.
        </>
      ),
    },
  ],
};

const Companies: FC = () => {
  const [activeCompaniesId, setActiveCompaniesId] = useState<CompaniesId>(defaultCompaniesId);

  const onChange = useCallback((value: any) => {
    setActiveCompaniesId(value);
  }, []);

  const handleBackToCommon = () => {
    setActiveCompaniesId(defaultCompaniesId);
  };

  const {defaultTabs, companiesTabs, charactersTabs} = companies;
  const activeCompaniesTab = [...defaultTabs, ...companiesTabs, ...charactersTabs].find((item) => item.id === activeCompaniesId)!;

  return (
    <div className={s.block}>
      <div className={s.tabsWrap}>
        <div className={s.tabsBlock}>
          <div className={s.tabsTitle}>Компании</div>
          <VerticalTabs tabs={companiesTabs} value={activeCompaniesId} onChange={onChange} selected={activeCompaniesId}/>
        </div>

        <div className={s.tabsBlock}>
          <div className={s.tabsTitle}>Персонажи</div>
          <VerticalTabs tabs={charactersTabs} value={activeCompaniesId} onChange={onChange} selected={activeCompaniesId}/>
        </div>

        <div className={s.buttons}>
          <Button className={s.backButton} onClick={handleBackToCommon}>
            Вернуться к общей информации
          </Button>
        </div>
      </div>

      <div>
        <div className={s.contentTitleBlock}>
          <div className={s.contentTitle}>{activeCompaniesTab.contentTitle}</div>
          {activeCompaniesTab.contentSubtitle ? <div className={s.contentSubtitle}>{activeCompaniesTab.contentSubtitle}</div> : null}
        </div>

        <div className={s.content}>{activeCompaniesTab.content}</div>
      </div>
    </div>
  );
};

export default Companies;
