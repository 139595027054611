import { takeLatest, fork, put, call} from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
    fetchStart, fetchSucces, fetchError, 
    sendLicenseOrderStart, sendLicenseOrderSuccess, sendLicenseOrderError,
    getLicenseHistoryStart, getLicenseHistorySuccess, getLicenseHistoryError
 } from './LicensingSlice';

function* fetchData (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'AssetLicenses',
            payload: action.payload
          });
        yield put(fetchSucces(response))
    } catch (error) {
        console.log(error)
        yield put(fetchError(error))
    }
}

function* sendLicense (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
          apiMethod: 'LicenseOrderActions',
          payload: action.payload
        });
      yield put(sendLicenseOrderSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(sendLicenseOrderError(error))
  }
}

function* getLicenseHistory (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'LicenseOrderActions',
          payload: {}
        });
      yield put(getLicenseHistorySuccess(response))
  } catch (error) {
      console.log(error)
      yield put(getLicenseHistoryError(error))
  }
}

function* fetchDataWatcher () {
    yield takeLatest(fetchStart.type, fetchData)
}

function* sendLicenseOrderWatcher () {
    yield takeLatest(sendLicenseOrderStart.type, sendLicense)
}

function* getLicenseHistoryWatcher () {
    yield takeLatest(getLicenseHistoryStart.type, getLicenseHistory)
}

export const LicensingSagas = [fork(fetchDataWatcher), fork(sendLicenseOrderWatcher), fork(getLicenseHistoryWatcher)];
//не забываем добавлять сагу в рут- сагу