import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col } from "react-bootstrap";
import { Table } from 'antd';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import newsIcon2 from "../../../assets/img/news_icon_2.jpg";
import {  fetchTechnologiesDescriptionStart, 
  fetchTechnologiesNewsStart,
  technologiesNewsSelector,
  technologiesDescriptionSelector } from "../redux/TechnologiesSlice";
import { ACTIVED_FIELDS_CONFIG } from '../Technologies.constants';
import { teamsSelector, getUsersStart, userSelector } from "../../../store/appSlice";
import TechModal from './techModal';

const ActivedTab = (props: any): ReactElement => {  
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showNewsModal, setShowNewsModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);

  const teams = useSelector(teamsSelector) || [];
  const user = useSelector(userSelector);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const element = data.find((el: any) => el.id === newSelectedRowKeys[0])
    if (element.ownerId === user.teamId) {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRow(data.find((el: any) => el.id === newSelectedRowKeys[0]))
    }
  };

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
  }, [dispatch, teams])

  const description = useSelector(technologiesDescriptionSelector);
  const news = useSelector(technologiesNewsSelector);
  
  useEffect(()=>{
    if (selectedRow?.descriptionId && showDescriptionModal) dispatch(fetchTechnologiesDescriptionStart({id: selectedRow.descriptionId}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showDescriptionModal])

  useEffect(()=>{
    if (selectedRow?.id && showNewsModal) dispatch(fetchTechnologiesNewsStart({techId: selectedRow.id}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showNewsModal])

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    type: 'radio' as 'radio', //вот такая типизация в antd - это странно выглядит но работает
    onChange: onSelectChange,
  };

  const descriptionHandler = () => {
    setShowDescriptionModal(true);
  }

  /*const newsHandler = () => {
    setShowNewsModal(true);
  }*/

  const toHistoryHandler = () => {
    history.push("/technology_history");
  }

  

return (
  <>
    <div className='technologies--actived'>
        <Row>
          <Col xs={6}>
            <h4>Ознакомьтесь с реализованными технологиями и их эффектами</h4>
          </Col>
          <Col xs={6}>
          <div className='technologies--actived__buttons'>
            {/*<Button variant="outline-dark" onClick={newsHandler} disabled={isEmpty(selectedRowKeys)}>
              Новости по технологии
            </Button>*/}
            <Button variant="success" onClick={()=>setShowChangeModal(true)} disabled={isEmpty(selectedRowKeys)}>
              Изменить инвестиции
            </Button>
            <Button variant="warning" onClick={descriptionHandler} disabled={isEmpty(selectedRowKeys)} style={{ marginLeft: '1rem' }}>
              Подробнее о технологии
            </Button>
            <Button variant="primary" onClick={toHistoryHandler} style={{backgroundColor: '#5F8AB6', marginLeft: '1rem', border: 'none'}}>
              Принятые решения
            </Button>
          </div>
          </Col>
        </Row>

      <Table
        dataSource={data}
        columns={ACTIVED_FIELDS_CONFIG({teams, user})}
        rowKey={'id'}
        className="TechnologiesTable TechnologiesTable--pointer"
        rowSelection={rowSelection}
        pagination={false}
        rowClassName={record => (record.ownerId === user.teamId) ? "" : "technologies-disabled-row"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              const element = data.find((el: any) => el.id === record.id)
              if (element.ownerId === user.teamId) {
                  setSelectedRowKeys([record.id]);
                  setSelectedRow(record);
              }
            },
          };
        }}
      />
    </div>

    <Modal show={showDescriptionModal} onHide={() => setShowDescriptionModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{description?.techName || 'Технология'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="technologies--available__descriptionBlock">
          <h5>Бриф по технологии:</h5>
          <p>{description?.brief || ''}</p>
        </div>
        <div className="technologies--available__descriptionBlock">
          <h5>Суть технологии:</h5>
          <p>{description?.essence || ''}</p>
        </div>
        <div className="technologies--available__descriptionBlock">
          <h5>Параметры технологии:</h5>
          <pre>{description?.effects || ''}</pre>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowDescriptionModal(false)}>
          Назад
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={showNewsModal} onHide={() => setShowNewsModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Новости по технологии</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {!isEmpty(news) &&
        <>
          {news && news.map((el: any) => (
              <div className="technologies--available--item" key={el.id}>
                <div className="d-flex">
                  <img src={newsIcon2} className="technologies--available--icon" alt=""/>
                    <h5>{el.title}</h5>
                      </div>
                        <p>{el.body}</p>  
                      </div>
          ))}
        </>}
      {isEmpty(news) && <p>На данный момент по технологии новостей нет</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowNewsModal(false)}>
          Назад
        </Button>
      </Modal.Footer>
    </Modal>

    <TechModal showModal={showChangeModal} setShowModal={setShowChangeModal} row={selectedRow} isNew={false} />
  </>
);}

export default ActivedTab;