import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import View from './Mining.view';
import { IRouterProps } from "../../constants/interfaces";
import {fetchMiningTableStart, dataSelector, errorSelector} from './redux/MiningSlice';

export default function Mining(routerProps: IRouterProps) {
    const dispatch = useDispatch();
    const error = useSelector(errorSelector);
    const data = useSelector(dataSelector);

    const [regionId, setRegionId] = useState(0);
    const [fieldType, setFieldType] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRowSubStage, setSelectedRowSubStage] = useState(0);


    useEffect(() => {
        dispatch(fetchMiningTableStart({regionId, fieldType}));
    }, [dispatch, regionId, fieldType]);
    
    return (
        <View 
            {...routerProps}
            data={data}
            error={error}
            setRegionId={setRegionId}
            setFieldType={setFieldType}
            selectedRowKeys={selectedRowKeys} 
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowSubStage={selectedRowSubStage}
            setSelectedRowSubStage={setSelectedRowSubStage}
        />
    )
}
