import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  getGRRHistoryStart, getGRRHistorySuccess, getGRRHistoryError,
  deleteGrrItemStart, deleteGrrItemSucces, deleteGrrItemError, 
  updateGrrItemStart, updateGrrItemSuccess, updateGrrItemError,
 } from './GRRHistorySlice';

 function* getGRRHistory (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
        apiMethod: 'GrrActions',
        payload: {}
      });
    yield put(getGRRHistorySuccess(response))
} catch (error) {
    console.log(error)
    yield put(getGRRHistoryError(error))
}
}

function* deleteGrrItem (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.delete, {
            apiMethod: 'GrrActions',
            payload: action.payload
          });
        yield put(deleteGrrItemSucces(response))
        yield put(getGRRHistoryStart())
    } catch (error) {
        console.log(error)
        yield put(deleteGrrItemError(error))
    }
}

function* updateGrrItem (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
          apiMethod: 'GrrActions',
          payload: action.payload
        });
      yield put(updateGrrItemSuccess(response))
      yield put(getGRRHistoryStart())
  } catch (error) {
      console.log(error)
      yield put(updateGrrItemError(error))
  }
}

function* getGRRHistoryWatcher () {
  yield takeLatest(getGRRHistoryStart.type, getGRRHistory)
}

function* deleteGRRHistoryItemWatcher () {
    yield takeLatest(deleteGrrItemStart.type, deleteGrrItem)
}

function* updateGRRHistoryItemWatcher () {
    yield takeLatest(updateGrrItemStart.type, updateGrrItem)
}


export const GRRHistorySagas = [fork(deleteGRRHistoryItemWatcher), fork(updateGRRHistoryItemWatcher), fork(getGRRHistoryWatcher)];
//не забываем добавлять сагу в рут- сагу