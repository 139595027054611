import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { getLicenseHistoryStart } from "../../../pages/Licensing/redux/LicensingSlice";
import { 
  deleteLicensingItemStart, deleteLicensingItemSucces, deleteLicensingItemError, 
  updateLicensingItemStart, updateLicensingItemSuccess, updateLicensingItemError,
 } from './LicensingHistorySlice';

function* deleteLicensingItem (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.delete, {
            apiMethod: 'LicenseOrderActions',
            payload: action.payload
          });
        yield put(deleteLicensingItemSucces(response))
        yield put(getLicenseHistoryStart())
    } catch (error) {
        console.log(error)
        yield put(deleteLicensingItemError(error))
    }
}

function* updateLicensingItem (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
          apiMethod: 'LicenseOrderActions',
          payload: action.payload
        });
      yield put(updateLicensingItemSuccess(response))
      yield put(getLicenseHistoryStart())
  } catch (error) {
      console.log(error)
      yield put(updateLicensingItemError(error))
  }
}


function* deleteLicensingItemWatcher () {
    yield takeLatest(deleteLicensingItemStart.type, deleteLicensingItem)
}

function* updateLicensingItemWatcher () {
    yield takeLatest(updateLicensingItemStart.type, updateLicensingItem)
}


export const LicensingHistorySagas = [fork(deleteLicensingItemWatcher), fork(updateLicensingItemWatcher), ];
//не забываем добавлять сагу в рут- сагу