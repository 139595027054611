import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const MANAGEMENT_STORE_PATH = 'management';

const ManagementSlice = createSlice({
    name: MANAGEMENT_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE,
        preparation: [],
        games: [],
        fileSend: false,
        saved: false,
    },
    reducers: {
        startRoundStart (store) {
            return {...store, status: APP_STATE.LOADING, preparation: [], error: null, saved: false}
        },
        startRoundSucces (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, saved: true}
        },
        startRoundError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },


        endRoundStart (state) {
            return{...state, status: APP_STATE.LOADING, preparation: [], error: null, saved: false}
        },
        endRoundSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, saved: true}
        },
        endRoundError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },


        //file
        postPreparationRoundStart (state, action) {
            return{...state, status: APP_STATE.LOADING, preparation: [], error: null, saved: false, fileSend: false}
        },
        postPreparationRoundSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, fileSend: true}
        },
        postPreparationRoundError (store, action) {
            return {...store, status: APP_STATE.IDLE, fileSend: false, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },


        getPreparationRoundStart (state, action) {
            return{...state, status: APP_STATE.LOADING, preparation: [], error: null}
        },
        getPreparationRoundSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, preparation: action.payload[0], error: null, fileSend: action.payload[0].endOn ? false : true}
        },
        getPreparationRoundError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },


        exportRoundStart (state, action) {
            return{...state, status: APP_STATE.LOADING, preparation: [], error: null, saved: false, fileSend: true}
        },
        exportRoundSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, fileSend: true, saved: true}
        },
        exportRoundError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },

        //file
        importRoundStart (state) {
            return{...state, status: APP_STATE.LOADING, preparation: [], error: null, saved: false}
        },
        importRoundSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        importRoundError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },


        getGamesStart (store) {
            return {...store, status: APP_STATE.LOADING, preparation: [], error: null}
        },
        getGamesSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, games: action.payload, error: null}
        },
        getGamesError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },


        newGameStart (store, action) {
            return {...store, status: APP_STATE.LOADING, preparation: [], error: null, saved: false}
        },
        newGameSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, saved: true}
        },
        newGameError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },


        editGameStart (store, action) {
            return {...store, status: APP_STATE.LOADING, preparation: [], error: null, saved: false}
        },
        editGameSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, saved: true}
        },
        editGameError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload.errors.toString() || 'Ошибка сервера'}
        },
    }
})

export const { 
    
    startRoundStart, startRoundSucces, startRoundError, 
    endRoundStart, endRoundSuccess, endRoundError,
    postPreparationRoundStart, postPreparationRoundSuccess, postPreparationRoundError,
    getPreparationRoundStart, getPreparationRoundSuccess, getPreparationRoundError,
    exportRoundStart, exportRoundSuccess, exportRoundError,
    importRoundStart, importRoundSuccess, importRoundError,
    getGamesStart, getGamesSuccess, getGamesError,
    newGameStart, newGameSuccess, newGameError,
    editGameStart, editGameSuccess, editGameError,
 } = ManagementSlice.actions;
export default ManagementSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

/*export function isLoadingSelector(state: any) {
    return (
      get(state, `${LICENSING_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.data`) || [];
}*/

export function preparationSelector(state: any) {
    return get(state, `${MANAGEMENT_STORE_PATH}.preparation`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${MANAGEMENT_STORE_PATH}.error`);
}

export function savedSelector(state: any) {
    return get(state, `${MANAGEMENT_STORE_PATH}.saved`);
}

export function fileSendSelector(state: any) {
    return get(state, `${MANAGEMENT_STORE_PATH}.fileSend`);
}

export function gamesSelector(state: any) {
    return get(state, `${MANAGEMENT_STORE_PATH}.games`);
}