import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { Table, Tabs } from 'antd';
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { updateUsersStart } from "../redux/UsersSlice";
//import { saveTechnologiesItemStart, clearTechnologies } from "../redux/TechnologiesSlice";
import { techActionTypes } from '../../../constants';

const UserEditModal = (props: any): ReactElement => {  
  const dispatch = useDispatch();
  const { showModal, closeModal, row } = props;

  const [name, setName] = useState('');

  useEffect(()=>{
    if (row?.teamName && row.teamName !== name) setName(row.teamName)
  }, [row])
  

  const sendUserHandler = () => {
    dispatch(updateUsersStart({id: row.id, teamName: name}));
    closeModal();
  }

  const changeNameHandler = (e: React.BaseSyntheticEvent) => {
    setName(e.target.value)
  }
  

return (
  <>
    <Modal show={showModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Параметры команды</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row>
                    <Col xs={6}>
                        <p>Название команды</p>
                    </Col>
                    <Col xs={4}>
                        <Form.Control
                            onChange={changeNameHandler}
                            //onBlur={singlePriceValidator}
                            value={name}
                            type="text"
                        />
                    </Col>
                </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={sendUserHandler} >
            Сохранить
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Отменить
          </Button>
        </Modal.Footer>
      </Modal>
  </>
);}

export default UserEditModal;