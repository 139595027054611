import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
import { AGREEMENTS_STORE_PATH } from "./../Agreements.constants";

const AgreementsSlice = createSlice({
    name: AGREEMENTS_STORE_PATH,
    initialState: {
        error: null as null | string,
        saved: false,
        status: APP_STATE.IDLE,
    },
    reducers: {
        fetchAgreementsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchAgreementsSucces (store, action) {
            return {...store, data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        fetchAgreementsError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error: 
                    action.payload.errors ? action.payload.errors.toString() : 
                    action.payload ? action.payload : 
                    'Ошибка сервера'}
        },

        fetchAgreementsParamsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null,}
        },
        fetchAgreementsParamsSucces (store, action) {
            return {...store, params: action.payload, status: APP_STATE.IDLE, error: null}
        },
        fetchAgreementsParamsError (store, action) {
            console.log(action.payload)
            return {...store, status: APP_STATE.IDLE, 
                error: 
                    action.payload.errors ? action.payload.errors.toString() : 
                    action.payload ? action.payload : 
                    'Ошибка сервера'}
        },


        sendAgreementsOrderStart (state, action) {
            return{...state, status: APP_STATE.LOADING, saved: false, error: null,}
        },
        sendAgreementsOrderSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, saved: true}
        },
        sendAgreementsOrderError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },


        deleteAgreementsItemStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null,}
        },
        deleteAgreementsItemSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        deleteAgreementsItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },

        updateAgreementsItemStart (state, action) {
            return{...state, status: APP_STATE.LOADING, saved: false, error: null,}
        },
        updateAgreementsItemSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null, saved: true}
        },
        updateAgreementsItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, 
                error:  action.payload.errors ? action.payload.errors.toString() : 
                        action.payload ? action.payload : 
                        'Ошибка сервера'}
        },

        clearSaved (state) {
            return{...state, saved: false}
        },
    }
})

export const { 
    fetchAgreementsStart, fetchAgreementsSucces, fetchAgreementsError, 
    fetchAgreementsParamsStart, fetchAgreementsParamsSucces, fetchAgreementsParamsError, 
    sendAgreementsOrderStart, sendAgreementsOrderSuccess, sendAgreementsOrderError,
    deleteAgreementsItemStart, deleteAgreementsItemSuccess, deleteAgreementsItemError,
    updateAgreementsItemStart, updateAgreementsItemSuccess, updateAgreementsItemError,
    clearSaved
 } = AgreementsSlice.actions;
export default AgreementsSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${AGREEMENTS_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${AGREEMENTS_STORE_PATH}.data`) || [];
}

export function paramsSelector(state: any) {
    return get(state, `${AGREEMENTS_STORE_PATH}.params`) || [];
}

export function historyDataSelector(state: any) {
    return get(state, `${AGREEMENTS_STORE_PATH}.history_data`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${AGREEMENTS_STORE_PATH}.error`);
}

export function savedSelector(state: any) {
    return get(state, `${AGREEMENTS_STORE_PATH}.saved`);
}