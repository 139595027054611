import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  addReportStart, addReportSuccess, addReportError,
  getReportDataStart, getReportDataSuccess, getReportDataError,
  getReportFileStart, getReportFileSuccess, getReportFileError,
  deleteReportFileStart, deleteReportFileSuccess, deleteReportFileError
 } from './AddReportSlice';

 function* getReportData () {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'Reports',
          payload: {}
        });
      yield put(getReportDataSuccess(response))
    } catch (error) {
      console.log(error)
      yield put(getReportDataError(error))
    }
  }

  function* addReport (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.filesend, {
          apiMethod: 'Reports/bulck',
          payload: action.payload
        });
      yield put(addReportSuccess(response))
      yield put(getReportDataStart())
  } catch (error) {
      console.log(error)
      yield put(addReportError(error))
  }
}

function* getReportFile (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.fileget, {
        apiMethod: 'ReportFiles',
        payload: action.payload
      });
    yield put(getReportFileSuccess(response))
} catch (error) {
    console.log(error)
    yield put(getReportFileError(error))
}
}

function* deleteReportFile (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.delete, {
          apiMethod: 'Reports',
          payload: action.payload
        });
      yield put(deleteReportFileSuccess(response))
      yield put(getReportDataStart())
  } catch (error) {
      console.log(error)
      yield put(deleteReportFileError(error))
  }
}


function* addReportWatcher () {
    yield takeLatest(addReportStart.type, addReport)
}
function* getReportDataWatcher () {
  yield takeLatest(getReportDataStart.type, getReportData)
}
function* getReportFileWatcher () {
  yield takeLatest(getReportFileStart.type, getReportFile)
}
function* deleteReportFileWatcher () {
  yield takeLatest(deleteReportFileStart.type, deleteReportFile)
}

export const AddReportSagas = [fork(addReportWatcher), fork(getReportDataWatcher), fork(getReportFileWatcher), fork(deleteReportFileWatcher)];
//не забываем добавлять сагу в рут- сагу