import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useHistory } from "react-router";
import { DeleteOutlined, EditOutlined, RollbackOutlined } from '@ant-design/icons';
import Notification from '../../components/Notification';
import { actionTypes } from '../../constants';
import './miningHistory.css';
import { IData } from "./MiningHistory.interfaces";
import { teamsSelector, getUsersStart } from "../../store/appSlice";
import { getMiningHistoryStart, historyMiningSelector, errorSelector, deleteMiningItemStart, updateMiningItemStart, closeModalSlice, sendedSuccessSelector } from './redux/MiningHistorySlice';

const GRRHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const error = useSelector(errorSelector);

  const teams = useSelector(teamsSelector);
  const data = useSelector(historyMiningSelector);
  const sendedSuccess = useSelector(sendedSuccessSelector);
  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
}, [dispatch, teams])
  
  useEffect(()=>{
    dispatch(getMiningHistoryStart());
  }, [dispatch])

  const editHandler = (row: any) => {
    setEditedRow(row);
    setShowEditModal(true);
  }

  const removehandler = (row: any) => {
    setDeletedId(row.id);
    setShowDeleteModal(true);
  }

  const goBackHandler = () => {
    history.push("/mining");
  }


  //Обработчики данных для таблицы
  const formattedData = data.map((el: any) => ({
    ...el,
    owner: el?.teamName || teams.find((element: any) => element.id === el.ownerId)?.teamName || 'Админ'}))

  const LICENSING_HISTORY_MODAL_FIELDS_CONFIG: ColumnsType<IData> = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'roundNumber',
      title: 'Раунд',
    },
    {
      dataIndex: 'name',
      title: 'Решение'
    },
    { 
      dataIndex: 'owner',
      title: 'Инициатор'
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <>
          {row.actionType !== actionTypes.StartDevelopment && <Button variant="outline-info" onClick={() => editHandler(row)} title="Редактировать">
            <EditOutlined className="licensingHistory--icons"/>
          </Button>}
          <Button variant="outline-info" onClick={() => removehandler(row)} title="Удалить">
            <DeleteOutlined className="licensingHistory--icons"/>
          </Button>
        </>)
    },
  ]


  //Обработчики для модалки удаления
  const [deletedId, setDeletedId] = useState<null | number>(null);

  const hideDeleteModal = () => {
    setDeletedId(null);
    setShowDeleteModal(false);
  }

  const onOkDeleteModal = () => {
    dispatch(deleteMiningItemStart({id: deletedId}))
    setDeletedId(null);
    setShowDeleteModal(false);
  }


  //Обработчики для модалки редактирования
  const [editedRow, setEditedRow] = useState<any>(null);
  const [year, setYear] = useState<number | string>('');
  const [percent, setPercent] = useState<number | string>('');
  const [sellPrice, setSellPrice] = useState<number | string>('');
  const [resetPercentOpen, setResetPercentOpen] = useState(false);

  useEffect(()=>{
    if (editedRow) {
      setYear(editedRow.year);
      setPercent(editedRow.percent);
      setSellPrice(editedRow.amount)
    }
  }, [editedRow])

  const hideEditModal = () => {
    setShowEditModal(false);
  }

  const onOkEditModal = () => {
    const payload = {
      id: editedRow.id,
      assetId: editedRow.assetId,
      actionType: editedRow.actionType,
      year,
      percent,
      amount: sellPrice
    }
    dispatch(updateMiningItemStart(payload))
    //setEditedRow(null);
    //setShowEditModal(false);
  }

  useEffect(()=> {
    if (sendedSuccess) {
      setEditedRow(null);
      setShowEditModal(false);
      dispatch(closeModalSlice());
    }
  }, [sendedSuccess])

  const changeYearHandler = (e: React.BaseSyntheticEvent) => {
    setYear(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''));
  }

  const changePercentHandler = (e: React.BaseSyntheticEvent) => {
    setPercent(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''));
  }

  const percentResetHandler = (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      setPercent(0);
      setResetPercentOpen(true)
    } else {
      setResetPercentOpen(false)
    }
  }

  const changeSellPricehandler = (e: React.BaseSyntheticEvent) => {
    setSellPrice(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''));
  }
  

  return (
    <div className="licensingHistory">
      <div className='miningHistory--back'>
        <Button variant="outline-secondary" onClick={goBackHandler}>
          <RollbackOutlined  className="licensingHistory--icons"/>
          Назад
        </Button>
      </div>
      <Table 
        dataSource={formattedData} 
        columns={LICENSING_HISTORY_MODAL_FIELDS_CONFIG} 
        rowKey='id'
        className='LicensingHistoryModalTable'
        pagination={false}
        expandable={{
          expandedRowRender: record => 
            <>
            {record.actionType === actionTypes.ChangeMiningLevel && 
              <Row>
              <Col xs={6}>
                <p>Год решения</p>
                <Form.Control
                  onChange={()=>{}}
                  disabled
                  value={record.year}
                  type="number"
                />
              </Col>
              <Col xs={4}>
                  <p>Процент</p>
                    <Form.Control
                      type="number"
                      min={1}
                      max={100}
                      disabled
                      value={record.percent}
                      onChange={()=>{}}
                    />
              </Col>
            </Row>}

            {record.actionType === actionTypes.Liquidation && 
            <Row>
              <Col xs={6}>
                <p>Год решения</p>
                <Form.Control
                  onChange={()=>{}}
                  disabled
                  value={record.year}
                  type="number"
                />
              </Col>
            </Row>}

            {record.actionType === actionTypes.Sell && 
            <Row>
              <Col xs={6}>
                <p>Год решения</p>
                <Form.Control
                  onChange={()=>{}}
                  disabled
                  value={record.year}
                  type="number"
                />
              </Col>
              <Col xs={4}>
              <p>Сумма продажи, млн $</p>
              <Form.Control
                  type="number"
                  min={1}
                  disabled
                  value={record.amount}
                  onChange={()=>{}}
                />
              </Col>
            </Row>}
            {(record.actionType === actionTypes.Stop || record.actionType === actionTypes.ReStart) && 
            <Row>
              <Col xs={6}>
                <p>Год решения</p>
                <Form.Control
                  onChange={()=>{}}
                  disabled
                  value={record.year}
                  type="number"
                />
              </Col>
            </Row>}
            </>
            ,
            rowExpandable: record => record.actionType !== actionTypes.StartDevelopment,
            }}
      />
      

      <Modal show={showDeleteModal} onHide={hideDeleteModal} size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>Подтвердите</Modal.Title>
        </Modal.Header>
        <Modal.Body>Подтвердите удаление</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkDeleteModal}>
            Подтвердить
          </Button>
          <Button variant="secondary" onClick={hideDeleteModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={hideEditModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editedRow && editedRow.actionType === actionTypes.ChangeMiningLevel && 
              <Row>
              <Col xs={6}>
                <p>Год решения</p>
                <Form.Control
                  onChange={changeYearHandler}
                  value={year}
                  type="number"
                />
              </Col>
              <Col xs={5}>
                <p>Снизить на, %</p>
                  <Form.Control
                    type="number"
                    min={0}
                    max={100}
                    value={percent}
                    disabled={resetPercentOpen}
                    onChange={changePercentHandler}
                  />
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type={'checkbox'}
                    label={`Восстановить уровень`}
                    onChange={percentResetHandler}
                  />
                </Form.Group>
                </Col>
            </Row>}

            {editedRow && editedRow.actionType === actionTypes.Liquidation && 
            <Row>
              <Col xs={6}>
                <p>Год решения</p>
                <Form.Control
                  onChange={changeYearHandler}
                  value={year}
                  type="number"
                />
              </Col>
            </Row>}

            {editedRow && editedRow.actionType === actionTypes.Sell && 
            <Row>
              <Col xs={6}>
                <p>Год решения</p>
                <Form.Control
                  onChange={changeYearHandler}
                  value={year}
                  type="number"
                />
              </Col>
              <Col xs={4}>
              <p>Сумма продажи, млн $</p>
              <Form.Control
                  type="number"
                  min={1}
                  isInvalid={sellPrice === null || sellPrice < 0}
                  value={sellPrice}
                  onChange={changeSellPricehandler}
                />
              </Col>
            </Row>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onOkEditModal} disabled={(percent && percent > 100) || (sellPrice && sellPrice < 0) as boolean}>
            Сохранить
          </Button>
          <Button variant="secondary" onClick={hideEditModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Notification success={false} error={error} />
    </div>
  );
};

export default GRRHistory;
