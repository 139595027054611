import React from "react";
import { IData } from "./Agreements.interfaces";
import { ColumnsType } from 'antd/es/table';
import { Button } from 'react-bootstrap';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { countries } from "../../constants";

export const AGREEMENTS_ITEM_KEY: string = "id";

export const AGREEMENTS_STORE_PATH: string = "agreements";


export function MACRO_FIELDS_CONFIG (editHandler: (row: any)=> void, removehandler: (row: any)=> void): ColumnsType<IData> {
  return ([
    {
      dataIndex: "id",
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: "roundNumber",
      title: "Раунд",
      align: "center"
    },
    {
      dataIndex: ["parameter", "name"],
      title: "Название параметра",
      align: 'center',
    },
    {
      dataIndex: "yearFrom",
      title: "Год с",
      align: "center",
    },
    {
      dataIndex: "value",
      title: "Значение",
      className: 'centeredCol',
      render: (_: any, row: any) => (
        <span>{row.value}{" " + (row?.parameter?.units || '')}</span>)
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <>
          <Button variant="outline-info" onClick={() => editHandler(row)} title="Редактировать">
            <EditOutlined className="agreementsTable--icons"/>
          </Button>
          <Button variant="outline-info" onClick={() => removehandler(row)} title="Удалить">
            <DeleteOutlined className="agreementsTable--icons"/>
          </Button>
        </>)
    },
  ]);
} 

export function TAX_FIELDS_CONFIG(editHandler: (row: any)=> void, removehandler: (row: any)=> void): ColumnsType<IData> {
  return ([
  {
    dataIndex: "id",
    title: 'id',
    className: 'hiddenCol'
  },
  {
    dataIndex: "roundNumber",
    title: "Раунд",
    align: "center"
  },
  {
    dataIndex: ["parameter", "name"],
    title: "Название параметра",
    align: 'center',
  },
  {
    dataIndex: "region",
    title: "Регион",
    align: 'center',
    render: (_: any, row: any) => <span>{countries.find(el => el.id === +row.regionId)?.name}</span>
  },
  {
    dataIndex: "yearFrom",
    title: "Год с",
    align: "center",
  },
  {
    dataIndex: "yearTo",
    title: "Год до",
    align: "center",
  },
  {
    dataIndex: "value",
    title: "Значение",
    className: 'centeredCol',
    render: (_: any, row: any) => (
      <span>{row.value}{" " + (row?.parameter?.units || '')}</span>)
  },
  {
    dataIndex: 'action',
    title: '',
    render: (_: any, row: any) => (
      <>
        <Button variant="outline-info" onClick={() => editHandler(row)} title="Редактировать">
          <EditOutlined className="agreementsTable--icons"/>
        </Button>
        <Button variant="outline-info" onClick={() => removehandler(row)} title="Удалить">
          <DeleteOutlined className="agreementsTable--icons"/>
        </Button>
      </>)
  },
])};

export function BUDGET_FIELDS_CONFIG(editHandler: (row: any)=> void, removehandler: (row: any)=> void): ColumnsType<IData> {
  return ([
  {
    dataIndex: "id",
    title: 'id',
    className: 'hiddenCol'
  },
  {
    dataIndex: "roundNumber",
    title: "Раунд",
    align: "center"
  },
  {
    dataIndex: "region",
    title: "Регион",
    align: 'center',
    render: (_: any, row: any) => <p>{countries.find(el => el.id === +row.regionId)?.name}</p>
  },
  {
    dataIndex: "yearFrom",
    title: "Год с",
    align: "center",
  },
  {
    dataIndex: "yearTo",
    title: "Год до",
    align: "center",
  },
  {
    dataIndex: "value",
    title: "Сумма ежегодно",
    className: 'centeredCol',
    render: (_: any, row: any) => (
      <span>{row.value}{" млн.$"}</span>)
  },
  {
    dataIndex: 'action',
    title: '',
    render: (_: any, row: any) => (
      <>
        <Button variant="outline-info" onClick={() => editHandler(row)} title="Редактировать">
          <EditOutlined className="agreementsTable--icons"/>
        </Button>
        <Button variant="outline-info" onClick={() => removehandler(row)} title="Удалить">
          <DeleteOutlined className="agreementsTable--icons"/>
        </Button>
      </>)
  },
])};