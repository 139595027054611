import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import { Provider } from "react-redux";
import AppContainer from "./AppContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./store";
import "./index.css";
//import "./assets/css/ReactTableCustom.css"; // Кастомные общие стили для React table


fetch('/backUrl.json').then(
  res => {
      if (res.ok) {
          return res.json().then(res => {
              localStorage.removeItem('GPN_GAME_BACK_ADDRESS');
              localStorage.setItem('GPN_GAME_BACK_ADDRESS', res.backurl);
          })
      }
  }
);

function ConnectedApp() {
  return (
    <Provider store={store} >
      <AppContainer />
    </Provider>
  );
}

ReactDOM.render(<ConnectedApp />, document.getElementById("root"));

