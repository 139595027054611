import React, { ReactElement, useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { countries } from "../../../constants";
import { yearSelector } from "../../../store/appSlice";
import { sendAgreementsOrderStart, updateAgreementsItemStart, deleteAgreementsItemStart, clearSaved, savedSelector } from "../redux/AgreementsSlice";

export default function LicensingActionPanel (props: any): ReactElement {
  const {getAgreementsParams, activeTab, params, fetchDataParams, showEditModal, showDeleteModal, closeModalHandler, editedRow, setShowEditModal} = props;
  const dispatch = useDispatch();
  const saved = useSelector(savedSelector);
  const baseYear = useSelector(yearSelector);

  const [regionId, setRegionId] = useState(-1);
  const [parameterId, setParameterId] = useState("-1");
  const [yearFrom, setYearFrom] = useState<number | string>('');
  const [yearTo, setYearTo] = useState<number | string>('');
  const [value, setValue] = useState<number | string>('');
  const [units, setUnits] = useState<string>('');

  useEffect(() => {
    if (showEditModal && activeTab !== 3)  getAgreementsParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditModal]);

  useEffect(()=>{
    if (baseYear && baseYear !== yearFrom) setYearFrom(baseYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, baseYear])

  useEffect(() => {
    if (showEditModal && editedRow) {
      setRegionId(editedRow?.regionId || -1);
      setParameterId(editedRow?.parameterId || '-1');
      setYearFrom(editedRow?.yearFrom || '');
      setYearTo(editedRow?.yearTo || '');
      setValue(editedRow?.value || '');
      setUnits(editedRow?.units || '');
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedRow]);

  useEffect(() => {
    if (showEditModal && saved)  {
      dispatch(clearSaved());
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditModal, saved]);

  const changeParamsSelectHandler = (e: React.BaseSyntheticEvent) => {
      setParameterId(e.target.value)
      setUnits(params.find((el: any) => el.id === e.target.value)?.units);
  }

  const changeRegionSelectHandler = (e: React.BaseSyntheticEvent) => {
    setRegionId(+e.target.value)
  }

  const onYearFromChange = (e: React.BaseSyntheticEvent) => {
    setYearFrom(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const onYearToChange = (e: React.BaseSyntheticEvent) => {
    setYearTo(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const onValueToChange = (e: React.BaseSyntheticEvent) => {
    setValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const closeModal = () => {
    setRegionId(-1);
    setParameterId('-1');
    if (baseYear && baseYear !== yearFrom) setYearFrom(baseYear);
    setYearTo('');
    setValue('');
    setUnits('');
    closeModalHandler();
  }

  let request: any;
  if (activeTab === 1) request = {parameterId, yearFrom, value, type: activeTab};
  if (activeTab === 2) request = {parameterId, regionId, yearFrom, yearTo, value, type: activeTab};
  if (activeTab === 3) request = {regionId, yearFrom, yearTo, value, type: activeTab};
  if (editedRow) request.id = editedRow.id

  const sendAgreementsOrder = () => {
    if (!editedRow) dispatch(sendAgreementsOrderStart({request, fetchDataParams}));
    if (editedRow) dispatch(updateAgreementsItemStart({request, fetchDataParams}));
  }

  const deleteAgreementsOrder = () => {
    dispatch(deleteAgreementsItemStart({request: {id: editedRow.id}, fetchDataParams}))
    closeModal();
  }

  const filteredParams = activeTab === 1 ? params : params.filter((el: any) => el.regionIds.includes(regionId));
  let modalTitle = activeTab === 1 ? 'Создать решение' : 'Создать договоренность';
  if (editedRow && activeTab === 1) modalTitle = 'Редактировать решение';
  if (editedRow && activeTab !== 1) modalTitle = 'Редактировать договоренность';
  
  return (
      <div className="licensing--actionButtons">
        <p>Выберите действие:</p>
        <Button variant="success" onClick={setShowEditModal}>
          {activeTab === 1 ? 'Создать решение' : 'Создать договоренность'}
        </Button>

        <Modal show={showEditModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
            {activeTab !== 1 &&
            <Col xs={2}>
              <p>Регион</p>
              <Form.Select value={regionId} onChange={changeRegionSelectHandler}>
                  <option value={-1} key={-1}>Выберите</option>
                  {countries.filter(el => el.id !== 0).map((el: any) => (<option value={el.id} key={el.id} >{el.name}</option>))}
              </Form.Select>
            </Col>}
            {activeTab !== 3 &&
              <Col xs={4}>
              <p>Параметр</p>
              <Form.Select value={parameterId} onChange={changeParamsSelectHandler}>
                  <option value={'-1'} key={'-1'}>Выберите</option>
                  {filteredParams.map((el: any) => (<option value={el.id} key={el.id} >{el.name}</option>))}
              </Form.Select>
            </Col>}
            <Col xs={2}>
              <p>Год с</p>
              <Form.Control 
                  onChange={onYearFromChange}
                  //onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.]/g, '')}
                  value={yearFrom}
                  type="number"/>
            </Col>
            {activeTab !== 1 &&
            <Col xs={2}>
              <p>Год до</p>
              <Form.Control 
                  onChange={onYearToChange}
                  value={yearTo}
                  type="number"/>
            </Col>}
            <Col xs={activeTab !== 3 ? 2 : 4}>
              <p>{activeTab !== 3 ? 'Значение' + (units ? ', ' + units : '') : 'Сумма ежегодно, млн.$'}</p>
              <Form.Control 
                  onChange={onValueToChange}
                  value={value}
                  type="number"/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={sendAgreementsOrder} >
            Сохранить
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showDeleteModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Удаление</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              Подтвердите удаление договоренности
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={deleteAgreementsOrder} >
            Удалить
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
  );
}