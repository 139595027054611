import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { dataFormatter } from '../ReportsGraphCompany.util';
import { 
  fetchDataStart, fetchDataSuccess, fetchDataError,
 } from './ReportsGraphCompanySlice';

function* fetchReportData (action: any) {
  try {
    const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
      apiMethod: 'CorporateCommonReports',
      payload: action.payload
    });
  yield put(fetchDataSuccess(dataFormatter(response)))
} catch (error) {
  console.log(error)
  yield put(fetchDataError(error))
}
}


function* fetchReportDataWatcher () {
  yield takeLatest(fetchDataStart.type, fetchReportData)
}

export const ReportsGraphCompanySagas = [ fork(fetchReportDataWatcher)];
//не забываем добавлять сагу в рут- сагу