import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
import { GRR_STORE_PATH } from "./../GRR.constants";

const GRRSlice = createSlice({
    name: GRR_STORE_PATH,
    initialState: {
        error: null,
        status: APP_STATE.IDLE,
        data: [],
        sendedSuccess: false,
    },
    reducers: {
        fetchGrrTableStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchGrrTableSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, data: action.payload, error: null}
        },
        fetchGrrTableError (store, action) {
            return {...store, status: APP_STATE.IDLE, data: [],
                error: 
                action.payload.errors ? action.payload.errors.toString() : 
                action.payload ? action.payload : 
                'Ошибка сервера'}
        },


        saveGrrItemStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        saveGrrItemSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, sendedSuccess: true, error: null}
        },
        saveGrrItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        closeModalSlice (store) {
            return {...store, sendedSuccess: false}
        },
    }
})

export const { fetchGrrTableStart, fetchGrrTableSuccess, fetchGrrTableError, saveGrrItemStart, saveGrrItemSuccess, saveGrrItemError, closeModalSlice } = GRRSlice.actions;
export default GRRSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${GRR_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${GRR_STORE_PATH}.data`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${GRR_STORE_PATH}.error`);
}

export function sendedSuccessSelector(state: any) {
    return get(state, `${GRR_STORE_PATH}.sendedSuccess`);
}