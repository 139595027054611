import React, { ReactElement } from "react";
import { Row, Col } from 'react-bootstrap';
import { Area, Line, Column } from '@ant-design/plots';

export default function ReportsGraph(props: any): ReactElement {
  const {data, regionId} = props;

  const production = data?.production || [];
  const ebit = data?.ebit || [];
  const capex = data?.capex || [];
  const revenue = data?.revenue || [];
  const taxes = data?.taxes || [];
  const finReg = data?.finReg || [];
  const co2 = data?.cO2 || [];
  const regTaxes = data?.regTaxes || [];

  const regionColor = ['#808080', '#4682B4', '#FFD700', '#228B22', '#D2691E'];
  const mainColors = regionId ? [regionColor[regionId -1]] : regionColor;

  const regTaxesColors = [
    ['#808080', '#B0E0E6', '#87CEEB', '#5F9EA0', '#3CB371', '#FFA500', '#ADD8E6', '#1E90FF', '#00008B', '#FFD700', '#FFA500', '#228B22', '#006400', '#D2691E'],
    ['#808080'],
    ['#B0E0E6', '#87CEEB', '#5F9EA0', '#3CB371', '#FFA500', '#ADD8E6', '#1E90FF', '#00008B'],
    ['#FFD700', '#FFA500'],
    ['#3CB371', '#006400'],
    ['#D2691E'],
  ]

  
  
  const configProduction = {
    data: production,
    xField: 'year',
    yField: 'value',
    seriesField: 'regionName',
    height: 300,
    areaStyle: {
      fillOpacity: 1,
    },
    legend: {
      layout: 'horizontal' as 'horizontal',
      position: 'top' as 'top',
    },
    color: mainColors,
  };

  const configEbit = {
    data: ebit,
    xField: 'year',
    yField: 'value',
    seriesField: 'regionName',
    smooth: true,
    height: 300,
    legend: {
      layout: 'horizontal' as 'horizontal',
      position: 'top' as 'top',
    },
    annotations: [
      {
        type: 'line',
        start: ['min', '0'] as [string, string],
        end: ['max', '0'] as [string, string],
        style: {
          lineWidth: 2,
          stroke: '#a0a0a0'
        },
      }
    ],
    color: mainColors,
  }

  const configRevenue = {
    data: revenue,
    isStack: true,
    xField: 'year',
    yField: 'value',
    seriesField: 'regionName',
    height: 300,
    legend: {
      layout: 'horizontal' as 'horizontal',
      position: 'top' as 'top',
    },
    color: mainColors,
  }

  const configCapex = {
    data: capex,
    isStack: true,
    xField: 'year',
    yField: 'value',
    seriesField: 'regionName',
    height: 300,
    legend: {
      layout: 'horizontal' as 'horizontal',
      position: 'top' as 'top',
    },
    color: mainColors,
  }

  const configTaxes = {
    data: taxes,
    isStack: true,
    xField: 'year',
    yField: 'value',
    seriesField: 'regionName',
    height: 300,
    legend: {
      layout: 'horizontal' as 'horizontal',
      position: 'top' as 'top',
    },
    color: mainColors,
  }

  const configFinReg = {
      data: finReg,
      isStack: true,
      xField: 'year',
      yField: 'value',
      seriesField: 'regionName',
      height: 300,
      legend: {
        layout: 'horizontal' as 'horizontal',
        position: 'top' as 'top',
      },
      annotations: [
        {
          type: 'line',
          start: ['min', '0'] as [string, string],
          end: ['max', '0'] as [string, string],
          style: {
            lineWidth: 2,
            stroke: '#a0a0a0'
          },
        }
      ],
      color: mainColors,
  }

  const configRegTaxes = {
      data: regTaxes,
      isStack: true,
      xField: 'year',
      yField: 'value',
      seriesField: 'name',
      height: 400,
      legend: {
        layout: 'horizontal' as 'horizontal',
        position: 'top' as 'top',
        maxRow: 2,
        //offsetY: !regionId ? -20 : 0
      },
      color: regTaxesColors[regionId],
  }

  const configCO2 = {
      data: co2,
      smooth: true,
      xField: 'year',
      yField: 'value',
      seriesField: 'regionName',
      height: 400,
      legend: {
        layout: 'horizontal' as 'horizontal',
        position: 'top' as 'top',
      },
      color: mainColors,
  }

  return (
      <div className="reports--graph">
        <Row>
            <Col xs={6}>
                <div className="reports--graph__block reports--graph__block--double">
                    <h5>Добыча, млн тнэ</h5>
                    <Area {...configProduction} />
                </div>
            </Col>
            <Col xs={6}>
              <div className="reports--graph__block reports--graph__block--double">
                <h5>Выручка, млн $</h5>
                <Column {...configRevenue} />
              </div>
            </Col>
        </Row>
        <Row>
          <Col xs={6}> 
            <div className="reports--graph__block reports--graph__block--double">
                <h5>EBIT, млн $</h5>
                <Line {...configEbit} />
            </div>
          </Col>
          <Col xs={6}>
            <div className="reports--graph__block reports--graph__block--double">
              <h5>CAPEX (добыча), млн $</h5>
              <Column {...configCapex} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className="reports--graph__block reports--graph__block--double">
                <h5>Налоги (по регионам), млн $</h5>
                <Column {...configTaxes} />
            </div>
          </Col>
          <Col xs={6}>
          <div className="reports--graph__block reports--graph__block--double">
                <h5>Финансирование регионов, млн $</h5>
                <Column {...configFinReg} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className="reports--graph__block reports--graph__block--double">
                <h5>Налоги (по статьям), млн $</h5>
                <Column {...configRegTaxes} />
            </div>
          </Col>
          <Col xs={6}>
          <div className="reports--graph__block reports--graph__block--double">
                <h5>Уд. выбросы CO2,  кг CO2 экв. /  тнэ</h5>
                <Line {...configCO2} />
            </div>
          </Col>
        </Row>
      </div>
  );
}
