import React from "react";
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { countries } from '../../constants';
import './countryPanel.css'

export default function CountryPanel(props: any) {

    const {setRegionId, notAllRegions = false} = props;

    const onChange = (e: RadioChangeEvent) => {
        if (setRegionId) setRegionId(+e.target.value);
      };

    const filteredRegions = notAllRegions ? countries.filter(el => el.id > 0) : countries;

  return (
    <Radio.Group onChange={onChange} defaultValue={notAllRegions ? 1 : 0} className='countryPanel--group'>
        {filteredRegions.map(el=>(
            <Radio.Button key={el.id} value={el.id} className='countryPanel--element'>
              {el.name}
            </Radio.Button>      
        ))}
    </Radio.Group>
  );
}
