export function filterMenuItems({ menudata, sideMenuItems, isFiltered }) {
  const availableMenuIds = menudata.map(data => data.menuid);

  if (!isFiltered) {
    return sideMenuItems;
  }

  return sideMenuItems.reduce((prev, current) => {
    if (availableMenuIds.includes(current.menuid)) {
      return [...prev, current];
    }
    if (Array.isArray(current.subItems) && current.subItems.filter(subItem =>
        availableMenuIds.includes(subItem.menuid)).length>0) {
      return [
        ...prev,
        {
          ...current,
          subItems: current.subItems.filter(subItem =>
            availableMenuIds.includes(subItem.menuid)
          )
        }
      ];
    }
    return prev;
  }, []);
}
