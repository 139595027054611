import React, { useState, useEffect } from 'react';
import { isEmpty, cloneDeep, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Tabs, Table, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { actionTypes, countries } from "../../constants";
import Notification from '../../components/Notification';
import './accept.css';
import { teamsSelector, getUsersStart } from "../../store/appSlice";
import { errorSelector, sendAcceptStart, getAcceptStart, acceptDataSelector, 
  getGroupedAcceptStart, acceptGroupedDataSelector, getAcceptAgreementsStart, sendAcceptAgreementsStart, 
  savedSuccessSelector, clearSavedSuccess } from './redux/AcceptSlice';

const Accept = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');
  //const [showAlert, setShowAlert] = useState(false);
  const [approved, setApproved] = useState<any[]>([]);
  const [agreementsTab, setAgreementsTab] = useState('1');
  const [technologiesTab, setTechnologiesTab] = useState('4');


  //actionType: isNew ? techActionTypes.postNewTech : techActionTypes.postEditedTech
  const error = useSelector(errorSelector);

  const teams = useSelector(teamsSelector);
  const data = useSelector(acceptDataSelector);
  const groupedData = useSelector(acceptGroupedDataSelector);
  const savedSuccess = useSelector(savedSuccessSelector);
  
  useEffect(()=>{
    if (!isEmpty(data)) setApproved(data.map((el: any) => ({id: el.id, isAccepted: el.isAccepted})))
  }, [data])

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(()=>{
    dispatch(clearSavedSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSuccess])

  useEffect(()=>{
    const approved: any[] = [];
    groupedData.forEach((element: any) => {
      element.actions.forEach((el: any) => approved.push({id: el.id, isAccepted: el.isAccepted}))
    })
    if (!isEmpty(groupedData)) setApproved(approved)
  }, [groupedData])

  useEffect(()=>{
    if (activeTab === '2' || activeTab === '3') dispatch(getAcceptStart({actionType: +activeTab}));
    if (activeTab === '1') dispatch(getGroupedAcceptStart({actionType: +activeTab}));
    if (activeTab === '4') dispatch(getGroupedAcceptStart({actionType: +technologiesTab}));
    if (activeTab === '5') dispatch(getAcceptAgreementsStart({type: +agreementsTab}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, agreementsTab, technologiesTab])

  const approvedHandler = (id: any) => {
    return function (e: CheckboxChangeEvent) {
      const result = cloneDeep(approved); 
      result.find(el => el.id === id).isAccepted = e.target.checked;
      setApproved(result);
  }}

  const formattedData = data.map((el: any) => ({
    ...el,
    owner: el?.teamName || teams.find((element: any) => element.id === el.ownerId)?.teamName || ''}))

    const formattedGroupedData = cloneDeep(groupedData);
    formattedGroupedData.forEach((item: any) => {
        item.actions.forEach((element: any) => {
          let summ = 0;
          if (element.parameters?.items) {
            element.parameters.items.forEach((el: any) => summ += el.price)
          }
          if (element.parameters?.yearItems) {
            element.parameters.yearItems.forEach((el: any) => summ += el.amount)
          }
          element.summ = summ;
        })
      })

  const sortedData = sortBy(formattedData, 'teamName')
  
  const sendAcceptHandler = () => {
    if (activeTab !== '5') dispatch(sendAcceptStart({actionType: +activeTab, acceptResults: approved}));
    if (activeTab === '5') dispatch(sendAcceptAgreementsStart({accept:{items: approved}, fetch: {type: +agreementsTab}}))
  }

  const getYears = (years: any) => {
    let minYear = years[0].year;
    let maxYear = years[0].year;
    years.forEach((el: any) => {
      if (el.year < minYear) minYear = el.year;
      if (el.year > maxYear) maxYear = el.year;
    })
    return ({minYear, maxYear})
}

  
  //конфиги таблиц для таба Лицензирование
  const LICENSING_FIELDS_CONFIG = [
    {
      dataIndex: 'parentId',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'parentName',
      title: 'Решение'
    },
  ]

  const SUB_LICENSING_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'name',
      title: 'Решение'
    },
    { 
      dataIndex: 'teamName',
      title: 'Инициатор'
    },
    { 
      dataIndex: 'summ',
      title: 'Сумма заявки'
    },
    {
      dataIndex: 'startGrrNow',
      title: '',
      render: (_: any, row: any) => (
        <>
          <Checkbox onChange={()=>{}} checked={row?.parameters?.startGrrNow} disabled>
            {/* {row?.parameters?.startGrrNow ? 'Да' : 'Нет'} */}
            Начать ГРР сразу
          </Checkbox>
        </>)},
    {
      dataIndex: 'action',
      title: '',
      className: 'accept--action_col',
      render: (_: any, row: any) => (
        <>
          <Checkbox onChange={approvedHandler(row.id)} checked={approved.find(el => el.id === row.id)?.isAccepted}>
            {/* {approved.find(el => el.id === row.id)?.isAccepted ? 'Подтверждено' : 'Не подтверждено'} */}
            Подтвердить
          </Checkbox>
        </>)},
  ]

  const SUB2_LICENSING_FIELDS_CONFIG = [
    {
      dataIndex: 'actionId',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'teamName',
      title: 'Команда',
    },
    {
      dataIndex: 'percent',
      title: 'Процент',
    },
    {
      dataIndex: 'price',
      title: 'Цена заявки',
    }
  ]


  //  Конфиги для таба ГРР
  const GRR_HISTORY_MODAL_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'name',
      title: 'Решение'
    },
    { 
      dataIndex: 'owner',
      title: 'Инициатор'
    },
    {
      dataIndex: 'startNow',
      title: 'запустить разработку сразу',
      className: 'centeredCol',
      render: (_: any, row: any) => (
          <Form.Check id='grrHistoryCheck' type="checkbox" checked={row?.parameters?.startNow}  onChange={()=>{}} />
        )
    },
    {
      dataIndex: 'action',
      title: '',
      className: 'accept--action_col',
      render: (_: any, row: any) => (
        <>
          <Checkbox onChange={approvedHandler(row.id)} checked={approved.find(el => el.id === row.id)?.isAccepted}>
            {/* {approved.find(el => el.id === row.id)?.isAccepted ? 'Подтверждено' : 'Не подтверждено'} */}
            Подтвердить
          </Checkbox>
        </>)},
  ]

  // Конфиги таба добыча
  const MINING_HISTORY_MODAL_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'name',
      title: 'Решение'
    },
    { 
      dataIndex: 'owner',
      title: 'Инициатор'
    },
    {
      dataIndex: 'action',
      title: '',
      className: 'accept--action_col',
      render: (_: any, row: any) => (
        <>
          <Checkbox onChange={approvedHandler(row.id)} checked={approved.find(el => el.id === row.id)?.isAccepted}>
            {/* {approved.find(el => el.id === row.id)?.isAccepted ? 'Подтверждено' : 'Не подтверждено'} */}
            Подтвердить
          </Checkbox>
        </>)},
  ]


  // Конфиги для таба технология
  const TECHNOLOGY_FIELDS_CONFIG = [
    {
      dataIndex: 'parentId',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'parentName',
      title: 'Решение'
    },
  ]

  const SUB_TECHNOLOGY_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol',
    },
    {
      dataIndex: 'name',
      title: 'Решение',
    },
    {
      dataIndex: 'teamName',
      title: 'Инициатор',
    },
    { 
      dataIndex: 'summ',
      title: 'Сумма заявки'
    },
    {
      dataIndex: 'action',
      title: '',
      className: 'accept--action_col',
      render: (_: any, row: any) => (
        <>
          <Checkbox onChange={approvedHandler(row.id)} checked={approved.find(el => el.id === row.id)?.isAccepted}>
            {/* {approved.find(el => el.id === row.id)?.isAccepted ? 'Подтверждено' : 'Не подтверждено'} */}
            Подтвердить
          </Checkbox>
        </>)},
  ];

  /*const TECHNOLOGY_HISTORY_YEARS_MODAL_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol',
    },
    {
      dataIndex: 'year',
      title: 'Год',
    },
    {
      dataIndex: 'amount',
      title: 'Инвестированно',
    },
  ];*/

  const TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol',
    },
    {
      dataIndex: 'userId',
      title: 'Команда',
      render: (_: any, row: any) => (
        <>
          <p>{teams.find((el: any) => el.id === row.userId)?.teamName || ''}</p>
        </>
      ),
    },
    {
      dataIndex: 'percent',
      title: 'Процент',
    },
  ];


  // Конфиги для таба соглашения
  const AGREEMENTS_MACRO_FIELDS_CONFIG = [
      {
        dataIndex: "id",
        title: 'id',
        className: 'hiddenCol'
      },
      {
        dataIndex: 'userId',
        title: 'Команда',
        render: (_: any, row: any) => (
          <>
            <p>{teams.find((el: any) => el.id === row.userId)?.teamName || ''}</p>
          </>
        ),
      },
      {
        dataIndex: "roundNumber",
        title: "Раунд",
        className: 'centeredCol'
      },
      {
        dataIndex: ["parameter", "name"],
        title: "Название параметра",
        className: 'centeredCol'
      },
      {
        dataIndex: "yearFrom",
        title: "Год с",
        className: 'centeredCol'
      },
      {
        dataIndex: "value",
        title: "Значение",
        className: 'centeredCol'
      },
      {
        dataIndex: 'action',
        title: '',
        className: 'accept--action_col',
        render: (_: any, row: any) => (
          <>
            <Checkbox onChange={approvedHandler(row.id)} checked={approved.find(el => el.id === row.id)?.isAccepted}>
              Подтвердить
            </Checkbox>
          </>)},
    ]
  
  const AGREEMENTS_TAX_FIELDS_CONFIG = [
    {
      dataIndex: "id",
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'userId',
      title: 'Команда',
      render: (_: any, row: any) => (
        <>
          <p>{teams.find((el: any) => el.id === row.userId)?.teamName || ''}</p>
        </>
      ),
    },
    {
      dataIndex: "roundNumber",
      title: "Раунд",
      className: 'centeredCol'
    },
    {
      dataIndex: ["parameter", "name"],
      title: "Название параметра",
      className: 'centeredCol'
    },
    {
      dataIndex: "region",
      title: "Регион",
      className: 'centeredCol',
      render: (_: any, row: any) => <p>{countries.find(el => el.id === +row.regionId)?.name}</p>
    },
    {
      dataIndex: "yearFrom",
      title: "Год с",
      className: 'centeredCol'
    },
    {
      dataIndex: "yearTo",
      title: "Год до",
      className: 'centeredCol'
    },
    {
      dataIndex: "value",
      title: "Значение",
      className: 'centeredCol'
    },
    {
      dataIndex: 'action',
      title: '',
      className: 'accept--action_col',
      render: (_: any, row: any) => (
        <>
          <Checkbox onChange={approvedHandler(row.id)} checked={approved.find(el => el.id === row.id)?.isAccepted}>
            Подтвердить
          </Checkbox>
        </>)},
  ]
  
  const AGREEMENTS_BUDGET_FIELDS_CONFIG = [
    {
      dataIndex: "id",
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'userId',
      title: 'Команда',
      render: (_: any, row: any) => (
        <>
          <p>{teams.find((el: any) => el.id === row.userId)?.teamName || ''}</p>
        </>
      ),
    },
    {
      dataIndex: "roundNumber",
      title: "Раунд",
      className: 'centeredCol'
    },
    {
      dataIndex: "region",
      title: "Регион",
      className: 'centeredCol',
      render: (_: any, row: any) => <p>{countries.find(el => el.id === +row.regionId)?.name}</p>
    },
    {
      dataIndex: "yearFrom",
      title: "Год с",
      className: 'centeredCol'
    },
    {
      dataIndex: "yearTo",
      title: "Год до",
      className: 'centeredCol'
    },
    {
      dataIndex: "value",
      title: "Сумма ежегодно",
      className: 'centeredCol'
    },
    {
      dataIndex: 'action',
      title: '',
      className: 'accept--action_col',
      render: (_: any, row: any) => (
        <>
          <Checkbox onChange={approvedHandler(row.id)} checked={approved.find(el => el.id === row.id)?.isAccepted}>
            Подтвердить
          </Checkbox>
        </>)},
  ]

  

  return (
    <div className="accept">
      <Notification success={savedSuccess} error={error} />
      <Tabs
        defaultActiveKey={activeTab}
        onChange={(key) => {
          setActiveTab(key);
        }}
      >
        <Tabs.TabPane tab="Лицензирование" key="1">
          <Table
            dataSource={formattedGroupedData}
            columns={LICENSING_FIELDS_CONFIG}
            rowKey="parentId"
            className="acceptLicensingUpperTable"
            pagination={false}
            expandable={{
              expandedRowRender: (record) => {
                const maxActionId = record?.maxActionId || null;
                return (
                <Table
                  dataSource={record?.actions || []}
                  columns={SUB_LICENSING_FIELDS_CONFIG}
                  rowKey="id"
                  className="acceptLicensingCenterTable"
                  rowClassName={(record, index) =>
                    record.id === maxActionId ? 'maxActionRow' : ''
                  }
                  pagination={false}
                  expandable={{
                    expandedRowRender: (record) => (
                      <Table
                        dataSource={record?.parameters?.items || []}
                        columns={SUB2_LICENSING_FIELDS_CONFIG}
                        rowKey="userId"
                        className="acceptLicensingLowerTable"
                        pagination={false}
                      />
                    ),
                    //rowExpandable: record => record.name !== 'Not Expandable',
                  }}
                />
              )},
              //rowExpandable: record => record.name !== 'Not Expandable',
            }}
          />

          <div className="acceptLicensing--sendBlock">
            <Button variant="outline-primary" onClick={sendAcceptHandler}>
              Отправить
            </Button>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="ГРР" key="2">
          <Table
            dataSource={formattedData}
            columns={GRR_HISTORY_MODAL_FIELDS_CONFIG}
            rowKey="id"
            className="acceptGRRHistoryModalTable"
            pagination={false}
          />
          <div className="acceptLicensing--sendBlock">
            <Button variant="outline-primary" onClick={sendAcceptHandler}>
              Отправить
            </Button>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Добыча" key="3">
          <Table
            dataSource={sortedData}
            columns={MINING_HISTORY_MODAL_FIELDS_CONFIG}
            rowKey="id"
            className="MiningHistoryModalTable"
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  {record.actionType === actionTypes.ChangeMiningLevel && (
                    <Row>
                      <Col xs={6}>
                        <p>Год решения</p>
                        <Form.Control onChange={() => {}} value={record.parameters.year} type="number" disabled={true}/>
                      </Col>
                      <Col xs={4}>
                        <p>Процент</p>
                        <Form.Control
                          type="number"
                          min={1}
                          max={100}
                          value={record.parameters.percent}
                          onChange={() => {}}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  )}

                  {(record.actionType === actionTypes.Liquidation || record.actionType === actionTypes.Stop || record.actionType === actionTypes.ReStart) && (
                    <Row>
                      <Col xs={6}>
                        <p>Год решения</p>
                        <Form.Control onChange={() => {}} value={record.parameters.year} type="number" disabled={true}/>
                      </Col>
                    </Row>
                  )}

                  {record.actionType === actionTypes.Sell && (
                    <Row>
                      <Col xs={3}>
                        <p>Год решения</p>
                        <Form.Control onChange={() => {}} value={record.parameters.year} type="number" disabled={true} />
                      </Col>
                      <Col xs={3}>
                        <p>Сумма продажи, млн $</p>
                        <Form.Control type="number" min={1} value={record.parameters.amount} onChange={() => {}} disabled={true}/>
                      </Col>
                      <Col xs={3}>
                        <p>NPV PF</p>
                        <Form.Control onChange={() => {}} value={record.parameters.npvpf} type="number" disabled={true} />
                      </Col>
                      <Col xs={3}>
                        <p>NPV FC</p>
                        <Form.Control onChange={() => {}} value={record.parameters.vpvfc} type="number" disabled={true} />
                      </Col>
                    </Row>
                  )}
                </>
              ),
              rowExpandable: (record) => record.actionType !== actionTypes.StartDevelopment,
            }}
          />
          <div className="acceptLicensing--sendBlock">
            <Button variant="outline-primary" onClick={sendAcceptHandler}>
              Отправить
            </Button>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Технологии" key="4">
          <Tabs defaultActiveKey={technologiesTab} onChange={(key) => {setTechnologiesTab(key)}}>
            <Tabs.TabPane tab="Новые инвестиции" key="4">
              <TechnologiesTable 
                formattedGroupedData={formattedGroupedData} 
                TECHNOLOGY_FIELDS_CONFIG={TECHNOLOGY_FIELDS_CONFIG} 
                SUB_TECHNOLOGY_FIELDS_CONFIG={SUB_TECHNOLOGY_FIELDS_CONFIG} 
                getYears={getYears} 
                TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG={TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Изменение инвестиции" key="5">
              <TechnologiesTable formattedGroupedData={formattedGroupedData} 
                TECHNOLOGY_FIELDS_CONFIG={TECHNOLOGY_FIELDS_CONFIG} 
                SUB_TECHNOLOGY_FIELDS_CONFIG={SUB_TECHNOLOGY_FIELDS_CONFIG} 
                getYears={getYears} 
                TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG={TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG}
              />
            </Tabs.TabPane>
          </Tabs>


          
          <div className="acceptLicensing--sendBlock">
            <Button variant="outline-primary" onClick={sendAcceptHandler}>
              Отправить
            </Button>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Договоренности" key="5">
          <Tabs defaultActiveKey={agreementsTab} onChange={(key) => {setAgreementsTab(key)}}>
            <Tabs.TabPane tab="Корп. решения" key="1">
              <Table
                dataSource={sortedData}
                columns={AGREEMENTS_MACRO_FIELDS_CONFIG}
                rowKey="id"
                className="AgreementsHistoryModalTable"
                pagination={false}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Налоги" key="2">
              <Table
                dataSource={sortedData}
                columns={AGREEMENTS_TAX_FIELDS_CONFIG}
                rowKey="id"
                className="AgreementsHistoryModalTable"
                pagination={false}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Финансирование регионов" key="3">
              <Table
                dataSource={sortedData}
                columns={AGREEMENTS_BUDGET_FIELDS_CONFIG}
                rowKey="id"
                className="AgreementsHistoryModalTable"
                pagination={false}
              />
            </Tabs.TabPane>
          </Tabs>
          <div className="acceptLicensing--sendBlock">
            <Button variant="outline-primary" onClick={sendAcceptHandler}>
              Отправить
            </Button>
        </div>
        </Tabs.TabPane>
      </Tabs>
      {/*showAlert && !isEmpty(error) && (
        <Alert key={0} variant="warning" className="alert-block">
          {error}
        </Alert>
      )*/}
    </div>
  );
};

export default Accept;


const TechnologiesTable = (props: any) => {
  const {formattedGroupedData, TECHNOLOGY_FIELDS_CONFIG, SUB_TECHNOLOGY_FIELDS_CONFIG, getYears, TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG} = props;
  return (
    <Table
          dataSource={formattedGroupedData}
          columns={TECHNOLOGY_FIELDS_CONFIG}
          rowKey="parentId"
          className="acceptTechnologyUpperTable"
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              const maxActionId = record?.maxActionId || null;
              return (
              <Table
                dataSource={record.actions}
                columns={SUB_TECHNOLOGY_FIELDS_CONFIG}
                rowKey="id"
                className="acceptTechnologyLowerTable"
                pagination={false}
                rowClassName={(record, index) =>
                  record.id === maxActionId ? 'maxActionRow' : ''
                }
                expandable={{
                  expandedRowRender: (record) => {
                    const { minYear, maxYear } = getYears(record.parameters.yearItems);
                    return (
                      <>
                        <Row>
                          <Col xs={6}>
                            <Row>
                              <Col xs={4}>
                                <p>Год начала</p>
                                <Form.Control
                                  onChange={() => {}}
                                  disabled
                                  value={minYear}
                                  type="number"
                                />
                              </Col>
                              <Col xs={4}>
                                <p>Год окончания</p>
                                <Form.Control
                                  onChange={() => {}}
                                  disabled
                                  value={maxYear}
                                  type="number"
                                />
                              </Col>
                              <Col xs={4}>
                                <p>Инвестирование в год</p>
                                <Form.Control
                                  onChange={() => {}}
                                  disabled
                                  value={record.parameters.yearItems[0].amount}
                                  type="number"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={6}>
                            <Table
                              dataSource={record.parameters.teamItems}
                              columns={TECHNOLOGY_HISTORY_TEAMS_MODAL_FIELDS_CONFIG}
                              rowKey="userId"
                              className="LicensingHistoryModalTable"
                              pagination={false}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  },
                }}
              />
            )},
          }}
        />
  )
}