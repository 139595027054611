import {FC, useCallback, useState} from 'react';

import CountriesMap from './pages/countries_map';
import {CountriesPage, Country, defaultCountriesDescrTabId, defaultCountriesPage} from '../../../models/wiki';
import CountryPage from './pages/country_page';

const countries: Record<CountriesPage, Country | undefined> = {
  [CountriesPage.CountriesMap]: undefined,
  [CountriesPage.Valhalla]: {
    title: 'Валгалла',
    subtitle: 'Конституционная монархия',
    tabs: [
      {
        id: defaultCountriesDescrTabId,
        tabTitle: 'Политическая и экономическая ситуация',
        contentTitle: 'Политическая и экономическая ситуация',
        content: (
          <>
            Последние пару веков Королевство Валгалла является конституционной монархией, где титул короля передается по наследству. Король
            Валгаллы управляет страной при поддержке Премьер-министра, возглавляющего Правительство (высший орган исполнительной власти), и
            Парламента (высший орган законодательной власти). Каких-то 20 лет назад большинство мест в Парламенте занимали представители
            социал-демократической партии «Движение профсоюзов». Сегодня 46% мест в Парламенте занимают члены «Движения профсоюзов», а 48% –
            партия либеральных консерваторов «Райт», представляющая интересы буржуазного слоя населения. В стране высокий уровень
            благосостояния и экономическая стабильность. Но даже при высоких экономических показателях, политологи говорят о противостоянии
            рабочего класса и буржуазии.
            <br />
            <br />
            Экономика относится к смешанному типу: рыночная экономика со значительным государственным контролем. Основой экономики является
            нефтегазовая отрасль. На втором месте – рыбная промышленность. Последние несколько лет, в связи с тем, что нефтегазовые
            месторождения располагаются в море, представители рыбной промышленности пытаются оказать давление через экологов, чтобы
            сократить добычу нефти и газа, т.к. это влияет на популяцию рыбы.
            <br />
            <br />В конце 1980-х годов Правительство создало государственный Фонд Благосостояния, куда отчисляются сверхдоходы от
            нефтегазовой промышленности, а фонд дальше инвестирует полученные средства, обеспечивая безусловный доход.
            <br />
            <br />
            Несмотря на то, что в стране широко развита электроэнергетическая сфера и 95% электроэнергии для внутреннего пользования
            вырабатывается гидроэлектростанциями, Валгалла не готова отказаться от нефтегазовой добычи из-за работы Фонда Благосостояния.
          </>
        ),
      },
      {
        id: 2,
        tabTitle: 'История развития нефтегазового сектора',
        contentTitle: 'История развития нефтегазового сектора',
        content: (
          <>
            Небольшие нефтяные и газовые месторождения на мелководном шельфе Валгаллы были открыты в начале 1960-х годов, но из-за слабого 
            развития технологий, на поиски крупного месторождения ушло около 10 лет. Также Страна ведет переговоры об использовании спорных 
            морских территорий на границах с другими странами, где есть потенциально крупные месторождения.
            <br />
            <br />
            В Валгалле прошел пик добычи нефти и газа, в настоящее время страна инвестирует меньше в разведку новых месторождений. В долгосрочном 
            плане страна стремится к отказу от ископаемых источников энергии, при этом продолжает соблюдать ранее заключенные договоренности по 
            поставкам нефти и газа в соседние страны.
            <br />
            <br />
            Государство контролировало и контролирует процессы добычи нефти и газа. Самая крупная нефтегазовая компания страны на 70% 
            принадлежит государству, а управляет компанией Министерство нефти.
            <br />
            <br />
            На сегодняшний день Валгалла является лидером в разработке уникальных технологий в области нефтегазовой добычи и переработки 
            сырья. Благодаря этому Валгалла успешно ведет разработку новых месторождений в арктических морях.
          </>
        ),
      },
      {
        id: 3,
        tabTitle: 'Позиция компании в стране',
        contentTitle: 'Позиция компании в стране',
        content: (
          <>
            Ваша компания присутствует на рынке Валгаллы и владеет рядом месторождений. Для эффективной работы в стране компания должна
            владеть современными технологиями нефтедобычи на шельфе, соблюдать строгий режим промышленной безопасности и иметь достаточные
            средства для инвестиций в добычу в трудных климатический и геологических условиях.
          </>
        ),
      },
      {
        id: 4,
        tabTitle: 'Налоговый режим',
        contentTitle: 'Налоговый режим',
        content: (
          <>
            Добывающие компании, работающие в нефтегазовой отрасли Валгаллы, платят налог на дополнительный доход. Сумма налогов довольна
            высокая, поэтому сейчас многие компании ограниченно присутствуют на рынке Валгаллы. Льготы не предусмотрены, однако власти
            Валгаллы готовы полностью покрыть затраты на разведку и бурение новых месторождений.
          </>
        ),
      },
      {
        id: 5,
        tabTitle: 'Культурные и религиозные особенности',
        contentTitle: 'Культурные и религиозные особенности',
        content: (
          <>
            Толерантность и гендерное равенство: вот два кита, на которых прочно держится культура современной Валгаллы. Сложно найти другую
            страну, где так же трепетно относятся к правам человека и выступают против любой дискриминации. Например, не удивляйтесь, если
            ваш партнер по бизнесу придет на встречу в неформальной одежде или с любимой собакой. Обратите внимание на то, что личные
            качества и взгляды могут сыграть большую роль, чем профессионализм.
            <br />
            <br />
            Еще одна особенность Валгаллы – почтительное отношение к королевской семье. Даже легкая ирония в сторону короля и его
            родственников может быть воспринята негативно. Толерантные валгаллы скорее всего сделают вид, что с пониманием отнеслись к
            «шутке» от иностранца с плохим чувством юмора. Но в дальнейшем это может повлиять на отношение к вам.
          </>
        ),
      },
      {
        id: 6,
        tabTitle: 'ESG / Экология',
        contentTitle: 'ESG / Экология',
        content: (
          <>
            Валгалла придерживается жесткой экологической повестки. Пару лет назад Правительство даже приняло решение постепенно выводить
            активы Фонда Благосостояния из компаний, которые наносят ущерб окружающей среде и размещать больше активов в эко-компаниях.
            Учитывая, что это один из самых богатых фондов в мире, он может ставить некоторые компании перед выбором: менять эко-политику
            или отказываться от инвестиций Фонда. Все компании, работающие в нефтегазовой отрасли Валгаллы, проходят проверку по индексу
            ESG. К 2030 году Валгалла планирует сократить выбросы углеводорода на 50%.
            <br />
            <br />В настоящее время идёт сокращение темпов разработки новых месторождений из-за экологической угрозы для морской фауны и
            сейсмической активности, которая может повлечь разливы нефти.
          </>
        ),
      },
      {
        id: 7,
        tabTitle: 'Министр нефти',
        contentTitle: 'Министр нефти',
        content: (
          <>
            Клаудиа Свенсен, 45 лет.
            <br />
            Замужем, 2 детей.
            <br />
            Член партии «Движение профсоюзов». Вступила в должность 3 года назад.
            <br />
            <br />
            Клаудиа выросла в промышленном регионе Валгаллы. Её родители работали на одном из заводов по переработке рыбы. В семье, кроме 
            Клаудии, было еще двое детей. У родителей не было возможности полноценного участия в жизни детей, поэтому младшие братья Клаудии 
            попали в категорию «трудных подростков», но семья не получала необходимой помощи от социальных служб. В итоге один из братьев попал 
            в криминальную хронику Валгаллы. Конечно, Клаудиа делал все возможное, чтобы помочь своей семье, но что может сделать одна молодая девушка?
            <br />
            <br />
            Свенсен решила, что изменить систему можно только изнутри, поэтому поступила в Университет Валгаллы на факультет «Социологии» 
            и параллельно вступила в молодежное отделение партии «Движение Профсоюзов». Несмотря на показную толерантность, Клаудии несколько 
            раз открыто говорили, что женщине не место в политике. Но у Свенсен были свои планы. При поддержке партии она организовала службу 
            социальной и психологической помощи детям и подросткам из неблагополучных семей, которых оказалось довольно много, вопреки статистическим 
            данным. Затем Клаудиа победила на выборах мэра в столице Валгаллы, Бёрдмане. 
            <br />
            <br />
            Несмотря на принадлежность к разным партиям Клаудиа всегда поддерживала очень хорошие рабочие отношения с главой Парламента, Туве 
            Арнерсоном. Совместная работа над несколькими экологическими проектами, схожие черты характера, а также целеустремленность и упорство в 
            любой ситуации сделали их хорошими друзьями. Ввиду важной роли Парламента в вопросах предоставления компаниям новых акваторий для 
            разведки и утверждения крупных добычных проектов Туве Андерсон и Клаудиа нередко совместно участвуют в переговорах с ключевыми игроками 
            рынка. Сохранение баланса между поддержанием дохода в Фонд Благосостояния, экологической повесткой и сохранением заинтересованности компаний 
            в развитии нефтегазодобычи в стране – ключевой вызов для Валгаллы.
            <br />
            <br />
            Карьера не помешала Свенсен создать семью. И она твердо решила, что не собирается повторять ошибки своих родителей. Сама Клаудиа признавалась, 
            что самым мудрым решением в её браке было разделение декретного отпуска с мужем. Неожиданно это сработало не только в семейных отношениях, 
            но и сформировало политический имидж Свенсон. 
            <br />
            <br />
            Несмотря на семейные заботы и активную политическую карьеру, Клаудиа любит путешествия и перемену мест. Возможно, это одна из причин, 
            по которым она сменила привычную работу в мэрии на малознакомую сферу – Министерство нефти. Но до сих пор госпожа Свенсен сообщает 
            своим сторонникам и просто неравнодушным людям, что самый лучший подарок на её день рождения – это поддержка фонда помощи детям и подросткам. 
            <br />
            <br />
            Очень внимательно относится к экоповестке и ситуации с морскими ресурсами. Повлияла на перенос сроков разработки новых месторождения в 
            связи с ухудшением экологической ситуации в море.
          </>
        ),
      },
    ],
  },
  [CountriesPage.Gardarika]: {
    title: 'Гардарика',
    subtitle: 'Президентско-парламентская республика',
    tabs: [
      {
        id: defaultCountriesDescrTabId,
        tabTitle: 'Политическая и экономическая ситуация',
        contentTitle: 'Политическая и экономическая ситуация',
        content: (
          <>
            Последние 30 лет государство является смешанной президентско-парламентской республикой, которая пришла на смену социалистической
            республике в результате государственного переворота. Президент Гардарики избирается раз в 5 лет. Законодательную власть
            осуществляет Верхний парламент. Члены Верхнего Парламента избираются по смешанной системе. Несмотря на условное идеологическое
            многообразие, правящей партией, члены которой составляют около 80% Верхнего парламента, является Свободная Гардарика.
            <br />
            <br />
            Исполнительная власть – Правительство Гардарики, которое назначается Президентом, с согласия Верхнего парламента. Как и во всех
            органах власти, преимущественно состоит из членов партии Свободна Гардарика.
            <br />
            <br />
            Экономика относится к смешанному типу: рыночная экономика с государственным контролем. Преобладают обрабатывающая (15%) и
            добывающая промышленность (11%). Проблемы страны: быстрорастущая инфляция, социально-экономическое неравенство, плачевная
            демографическая ситуация и высокий уровень безработицы в регионах. Правительство контролирует цены на ГСМ, базовые продукты
            питания. А также вводит различные социальные программы для стимуляции рождаемости и борьбы с безработицей, а иногда принимает и
            непопулярные решения.
          </>
        ),
      },
      {
        id: 2,
        tabTitle: 'История развития нефтегазового сектора',
        contentTitle: 'История развития нефтегазового сектора',
        content: (
          <>
            Первые нефтяные скважины были открыты в 1880 году в юго-восточной части страны. Первый нефтеперерабатывающий завод появился
            через 3 года. К концу XIX века на нефтяной рынок Гардарики начали выходить иностранные партнеры, в том числе США. В начале ХХ
            века произошла череда гражданских войн, которая закончилась революцией и сменой монархии на социалистическую республику, что
            привело к паузе в развитии нефтегазовой индустрии. Затем новое правительство начало активную разработку месторождений по всей
            стране. Но несмотря на богатые месторождения нефти и газа, Гардарика не пускала на рынок зарубежных игроков. К концу ХХ века в
            стране снова произошла смена власти, которая привела к кризису во всех промышленных отраслях. Половина нефтегазовых
            месторождений перешли к частным компаниям, государство потеряло монополию. В Гардарике все месторождения нефти и газа находятся 
            на суше. В нефтяном секторе прошел период крупных открытий, преобладают средние и малые месторождения. Что касается газовых 
            месторождений, то для Гардарики характерно большое количество крупных газовых залежей.
          </>
        ),
      },
      {
        id: 3,
        tabTitle: 'Позиция компании в стране',
        contentTitle: 'Позиция компании в стране',
        content: (
          <>
            Ваша штаб-квартира находится в Гардарике, компания занимает уверенные позиции в разведке и добыче нефти и газа. На нефтегазовом
            рынке Гардарики присутствуют практически все крупные мировые компании, поэтому она не дает никаких адресных привилегий, выступая
            за системные решения. Плюсом является большое количество новых месторождений, но для их разработки необходимо
            высокотехнологичное оборудование, несмотря на то, что месторождения расположены на суше.
          </>
        ),
      },
      {
        id: 4,
        tabTitle: 'Налоговый режим',
        contentTitle: 'Налоговый режим',
        content: (
          <>
            Государство является собственником недр, поэтому добывающие компании, работающие в нефтегазовой отрасли, уплачивают роялти.
            Правительство Гардарики проводит политики по стимулированию добычи на новых месторождениях, поэтому в последние годы вводится
            новый режим налогообложения – налог на дополнительный доход.
          </>
        ),
      },
      {
        id: 5,
        tabTitle: 'Культурные и религиозные особенности',
        contentTitle: 'Культурные и религиозные особенности',
        content: (
          <>
            Культуру Гардарики сложно втиснуть в типичные восточные или западные рамки. Например, в стране есть традиция дарить подарки по
            поводу и без. Вы можете просто упомянуть в разговоре о своем увлечении культурой Древнего Китая, и на следующий день вам пришлют
            редкий экземпляр фарфора. Вас ждут бесконечные неофициальные мероприятия, ужины, обеды, посещения театров и музеев: вы увидите
            все самое лучшее. Только самое лучшее. Такое активное гостеприимство может быстро утомить, но в Гардарике на первом месте стоят
            межличностные отношения. Поэтому потерпите: рано или поздно хозяева покажут вам все достопримечательности, подарят все подарки и
            отведут во все рестораны. После этого, если у вас останутся силы, начнется работа.
            <br />
            <br />В вопросах религии страна позиционирует себя как светское государство. Хотя на телевидении Гардарики существует
            государственный религиозный канал. А президент страны регулярно посещает службы в церкви.
          </>
        ),
      },
      {
        id: 6,
        tabTitle: 'ESG / Экология',
        contentTitle: 'ESG / Экология',
        content: (
          <>
            Правительство страны вынужденно поддерживает зелёную повестку из-за большого количества иностранных партнеров. Это связано с
            тем, что иностранные инвесторы стали обращать внимание на рейтинги и соответствие нормам ESG. Экологическая ситуация не очень
            хорошая, и связано это не только с производственными выбросами, но и с вырубкой лесов, и с проблемой утилизации мусора.
            Международные эко-активисты считают, что Гардарика имитирует выполнение Парижского соглашения, а компании пересматривают системы
            переработки отходов только на бумаге.
            <br />
            <br />
            Правительство Гардарики опубликовало Стратегию устойчивого развития, в которой стоит цель - сократить выбросы парниковых газов
            до 70% к 2030 году. В связи с этим планируется снижение углеродоемкости ВВП на 10% к 2030 году. Но никаких санкций это намерение
            не предусматривает, и носит рекомендательный характер. Компании не заинтересованы в снижении прибыли из-за внедрения новых
            правил. Гардарика, в свою очередь, не заинтересована в потере многолетних партнеров, поэтому реальное решение вопроса повисло в
            воздухе.
          </>
        ),
      },
      {
        id: 7,
        tabTitle: 'Министр энергетики',
        contentTitle: 'Министр энергетики',
        content: (
          <>
            Иван Новиков, 52 года.
            <br />
            Женат, детей нет.
            <br />
            <br />
            Новиков родился в глубинке на севере Гардарики, там же провел своё детство. Отец мальчика был военным, и семья часто переезжала.
            Иван научился быстро адаптироваться к переменам. Когда пришло время поступать в институт, молодой человек колебался: идти по
            стопам отца или в гражданский вуз. Можно сказать, что выбор учебного заведения был случайным – долгие раздумья резко сократили
            количество вариантов. В итоге Новиков окончил Университет Нефти и Газа Гардарики, по специальности «Управление в нефтегазовой
            промышленности».
            <br />
            <br />
            Во времена молодости Новикова в Гардарике существовала практика распределения выпускников на различные государственные
            предприятия. Поэтому Иван начал карьеру в упраздненном сейчас Министерстве природопользования. Страна менялась и развивалась,
            как и карьера Ивана. 15 лет он назад начал руководить модернизацией атомного энергопромышленного комплекса Гардарики. А пару лет
            назад Президент назначил Новикова министром энергетики.
            <br />
            <br />
            Хобби Новикова очень сильно раздражает местных зоозащитников. Иван имеет свое охотхозяйство и занимается разведением редкой
            породы оленей. Несколько раз «зеленые» выпускали его стадо из вольеров. Сам Новиков всегда откровенно говорит о том, что вырос в
            тайге и не видит в законной охоте ничего предосудительного, однако выступает против браконьерства. Он даже принял участие в
            теледебатах с одним из активистов движения «НеОхота». Правда, закончились они испорченным красной краской костюмом Новикова и
            административным арестом активиста.
            <br />
            <br />
            Не забывает Новиков и об отдаленных северных регионах и уделяет много внимания развитию местной нефтегазовой промышленности.
            <br />
            <br />
            <b>Советник министра энергетики</b>
            <br />
            <br />
            Виталий Могучев, 64 года. Женат, четверо детей. 
            <br />
            <br />
            Могучев родился в крупном портовом городе на юге Гардарики, там же провел свое детство. Родители мальчика работали разнорабочими 
            в порту, поэтому семья располагала скромными доходами, которых едва хватало на покупку вещей первой необходимости. 
            <br />  
            <br />          
            Могучев уже с начальной школы начал проявлять неординарные предпринимательские способности, организовав первый «кооператив» 
            из таможенного конфиската, которые периодически приносили домой родители.
            <br />
            <br />
            Окончив среднюю школу и поступив в Институт предпринимательства и торговли, уже на втором курсе Могучев организовал в городе 
            торговую точку по реализации разнообразного таможенного конфиската. Через 3 года число торговых точек перевалило за сотню, 
            и торговый оборот превысил городской бюджет города. По итогам налоговой проверки Могучев был обвинен в неуплате налогов, однако, 
            сотрудничал со следствием, и дело было оперативно прекращено. По словам Могучева, это явилось отправной точкой легализации его бизнеса.
            <br />
            <br />
            За 20 лет Могучев создал крупную бизнес-империю, включающей в себя банковский бизнес, транспортировку и продажу нефти и газа, производство 
            трубной продукции. Могучеву удалось выстроить эффективную коммуникацию с рядом государственных чиновников и лоббировать свои интересы в ТЭК.
            <br />
            <br />
            10 лет назад Могучев решил оставить бизнес, передав свои активы в трастовый фонд под управление своих дальних родственников и начать политическую 
            карьеру, которая также успешно развивалась. После победы на выборах мэра родного города в составе партии «Свободная Гардарика», Могучев через 
            несколько лет был назначен губернатором област. По прошествии 6 лет успешной работы Могучев перешел в качестве сенатора в Верхний парламент, 
            где в составе профильных комитетов курировал различные направления экономики.
            <br />
            <br />
            Несколько лет назад Могучев вышел на почетную пенсию и в настоящий момент является советником министра энергетики Гардарики.
            <br />
            <br />
            За время своей политической карьеры Могучев не раз становился косвенным фигурантом коррупционных расследований в сегментах банковского и 
            нефтегазового секторов, однако, всегда выходил «чистым из воды». Бывшие бизнес-партнеры и политические оппоненты характеризуют Могучева 
            как опытного и «гибкого» управленца, однако, способного принимать жёсткие и своевременные решения.
            <br />
            <br />
            Могучев увлекается парусным спортом, совершил несколько кругосветных путешествий, является опытным яхтсменом.
          </>
        ),
      },
    ],
  },
  [CountriesPage.Assyria]: {
    title: 'Ассирия',
    subtitle: 'Парламентская республика',
    tabs: [
      {
        id: defaultCountriesDescrTabId,
        tabTitle: 'Политическая и экономическая ситуация',
        contentTitle: 'Политическая и экономическая ситуация',
        content: (
          <>
            До начала ХХ века Ассирия была частью Османской империи. Но в 1910 году стала одной из британских колоний, при этом оставаясь
            монархией. В 1935 году получила статус независимого государства. Через 20 лет началась серия военных переворотов, которая
            закончилась в 1970 году приходом к власти партии «Вольная Ассирия» и эпохой однопартийной системы, с диктатурой лидера. С 2002
            по 2010 год тянулась череда военных конфликтов, из-за обвинений мировым сообществом Ассирии в дестабилизации региона. К тому же
            внутри страны постоянно происходили гражданские и межнациональные войны. Сегодня Ассирия – это парламентская республика, во
            главе с Президентом, который избирается каждые 5 лет. В государстве практикуется многопартийность, скорее для успокоения
            граждан. При этом огромное значение имеет религия: ислам и его направления, но любые радикальные движения не поддерживаются.
            Страна стремиться быть светской и соответствовать международно-принятым нормам ведения бизнеса.
            <br />
            <br />
            Основой экономики является добыча нефти. До 2010 года Ассирия находилась под международными санкциями из-за военной активности
            страны. Особенно пострадала нефтяная отрасль из-за введения эмбарго на поставки и недостатка технологий. За десятилетие многое
            изменилось: сегодня эта восточная страна располагает огромными запасами нефти и привлекает большое количество иностранных
            инвесторов. Нефтяными месторождениями интересуются многие международные компании, невзирая на риски и не самую стабильную
            военно-политическую обстановку. Самой большой социально-экономической проблемой в стране является общий уровень бедности,
            несмотря на огромные нефтяные ресурсы.
          </>
        ),
      },
      {
        id: 2,
        tabTitle: 'История развития нефтегазового сектора',
        contentTitle: 'История развития нефтегазового сектора',
        content: (
          <>
            Страна располагает богатыми нефтяными месторождениями, которые находятся на суше. Первая нефть в Ассирии была найдена в 1930
            году. Это месторождение до сих пор считается крупнейшим в стране. Войны и конфликты сильно тормозили развитие отрасли. Иногда
            случалось так, что недавно построенный завод по переработке уничтожали очередные повстанцы. Лучшие инженеры трудились над
            созданием оружия, а не разрабатывали новые технологии для добычи и переработки сырья. Вполне логично, что Ассирия не могла
            полноценно использовать собственные нефтяные ресурсы. В 2010 году государство устроило аукцион на разработку месторождений, в
            результате которого часть прав на крупные месторождения ушла к зарубежным инвесторам.
            <br />
            <br />
            Последние 10 лет в стране активно работают различные нефтяные компании, при этом в Ассирии остаются неохваченные места для новых
            разработок. Государство заинтересовано в инвесторах и развитии нефтедобывающих и производственных технологий.
            <br />
            <br />
            Страна располагает богатыми нефтяными месторождениями, которые находятся на суше. В прошлые годы был открыт ряд крупных месторождений, 
            и геологоразведочные компании, работающие в Ассирии, оценивают высокую вероятность появлениях новых крупных нефтяных активов.
          </>
        ),
      },
      {
        id: 3,
        tabTitle: 'Позиция компании в стране',
        contentTitle: 'Позиция компании в стране',
        content: (
          <>
            Сегодня в нефтяной отрасли страны присутствует большое количество международных компаний, в том числе и ваша. В целом, Ассирия
            открыта для новых инвестиций в нефтяную отрасль и собирается провести череду аукционов на разработку большого количества новых
            месторождений. Геологические условия способствуют низкой себестоимости добычи огромных запасов, что привлекает инвесторов. Тем
            не менее компании стараются трезво оценивать риски: велика вероятность очередного политического или военного конфликта.
          </>
        ),
      },
      {
        id: 4,
        tabTitle: 'Налоговый режим',
        contentTitle: 'Налоговый режим',
        content: (
          <>
            Добывающие компании, работающие в Ассирии, заключают сервисный контракт с государством. Все права на недра остаются в руках
            Правительства Ассирии. Некоторые компании рассказывали о случаях пересмотра условий контракта в одностороннем порядке, с выгодой
            для Ассирии, но конкретного подтверждения у нас нет.
          </>
        ),
      },
      {
        id: 5,
        tabTitle: 'Культурные и религиозные особенности',
        contentTitle: 'Культурные и религиозные особенности',
        content: (
          <>
            Как в любой жаркой стране, в Ассирии все происходит медленно. Когда вы, наконец, о чем-то договорились, совершенно не факт, что
            это произойдет в обещанные сроки. Также не стоит забывать о традициях восточной торговли. Посещение любого базара можно сравнить
            с хорошим театральным представлением. И если вы хотите успешно вести дела в Ассирии, то вам придется стать участником этого
            театра. Европейцы, за редким исключением, не любят торговаться – здесь этот процесс можно отнести к неотъемлемой части культуры
            и бизнеса.
            <br />
            <br />
            Отдельно стоит сказать о религии: уважение, уважение и еще раз уважение. Даже если вам есть, что сказать про ислам или его
            историю – это тот случай, когда лучше слушать, чем говорить. В конце концов, есть нейтральные темы для поддержания беседы. Вы же
            приехали с определенной целью, не так ли? Кстати, немаловажную роль в любом бизнесе здесь играют личные связи, вот чем
            действительно стоит заняться. Но помните о том, что налаживание контактов через совместный ужин с алкоголем будет плохой идеей,
            из-за запрета на употребление спиртного. Так что готовьтесь к долгим разговорам, изучайте культуру Ассирии и избегайте острых
            тем. Хотя, в целом, ассирийцы настроены к иностранцам дружелюбно.
          </>
        ),
      },
      {
        id: 6,
        tabTitle: 'ESG / Экология',
        contentTitle: 'ESG / Экология',
        content: (
          <>
            В связи с военно-политическими событиями, эко-повестка в Ассирии просто игнорируется. С одной стороны – это может быть
            привлекательным фактором, т.к. сократит затраты на зеленую повестку. С другой – не стоит забывать о репутации вашей компании.
            При таком отношении к экологии Ассирия в скором времени может попасть под немилость мирового сообщества, и неизвестно как это
            повлияет на долгосрочные контракты и доход компаний на нефтяном рынке страны.
          </>
        ),
      },
      {
        id: 7,
        tabTitle: 'Министр нефти',
        contentTitle: 'Министр нефти',
        content: (
          <>
            Джафар Аль-Саид, 58 лет.
            <br />
            Женат, 5 детей.
            <br />
            <br />
            Джафар Аль-Саид родился в семье заводчика арабских лошадей, и, казалось бы, будущее было очевидным: продолжить семейные
            традиции. Но у мальчика была не только страсть к лошадям, но и обостренное чувство справедливости. Второе победило, и Джафар
            поступил на юридический факультет Ассирийского Университета, чтобы стать адвокатом. На учебе он познакомился с группой
            студентов, которым был крайне несимпатичен политический режим Ассирии. Особых сил и фантазии у ребят не было, поэтому они пошли
            проверенным путем: печатали листовки и агитировали таких же студентов выходить на митинги против диктатуры. Диктатор, которому и
            так хватало проблем с радикальными исламистами, предложил студентам два варианта: посидеть в тюрьме или уехать из страны.
            Аль-Саид выбрал второй вариант и в 1984 году переехал в Германию, где пытался забыть студенческое прошлое и стать, наконец,
            юристом. Но как только диктатура пала, он вернулся в Ассирию.
            <br />
            <br />В 2010 году Аль-Саид начал работать в юридическом департаменте Министерства нефти. Страна пыталась восстановить экономику
            после кризиса, главным ресурсом и аргументом вновь была нефть. Аль-Саиду очень пригодилось его дипломатические качества и опыт
            работы в Европе. С 2018 года Джафар Аль-Саид возглавляет Министерство нефти.
            <br />
            <br />
            По возвращению на родину Аль-Саид вспомнил об увлечении своей юности – арабских лошадях. К моменту возвращения сына, отец
            Аль-Саида уже давно не занимался коневодством, поэтому Джафар начал свое хобби с нуля. Местные газеты писали, что первых лошадей
            для личной конюшни Аль-Саид получил в подарок от английского бизнесмена, который давно работает в Ассирии. Статья быстро исчезла
            из всех источников. Официально Министр нефти никак не комментировал эту информацию.
            <br />
            <br />В одном из своих последних интервью Аль-Саид много рассказывал о лошадях и нефтяной отрасли, но избегал вопросов на
            политические темы. Со времен юности он старается не принимать участия в политических движениях и говорит, что ему хватает коней
            и нефти, а дальше его полномочия заканчиваются. Лукавит ли опытный юрист или сфера его интересов действительно так ограничена?
          </>
        ),
      },
    ],
  },
  [CountriesPage.Parthia]: {
    title: 'Парфия',
    subtitle: 'Президентская республика',
    tabs: [
      {
        id: defaultCountriesDescrTabId,
        tabTitle: 'Политическая и экономическая ситуация',
        contentTitle: 'Политическая и экономическая ситуация',
        content: (
          <>
            Парфия с древних времен занималась торговлей с восточными странами. В 1880 году страна присоединилась к Гардарике и стала частью
            монархии. После революции в Гардарике, Парфия так и осталась присоединенной частью. В это время здесь развивалось производство
            хлопка. В конце XX века Парфия стала независимой президентской республикой. Президент избирается на 5 лет и управляет
            государством при помощи Парламента и Законодательной палаты, состоящей из 100 депутатов, избранных от каждой области страны.
            Кабинет Министров решает задачи в области образования, здравоохранения, энергетики, торговли, транспорта и других сфер.
            <br />
            <br />
            На сегодня основой экономики Парфии является энергетическая и газовая отрасли. На втором месте – агропромышленный сектор, в том
            числе добыча хлопка. В данный момент государство проводит реформы в газовой отрасли: для упрощения процессов отчетности, гос.
            закупок и объединения государственных предприятий. Проблемой в Парфии является уровень бедности населения, социальное
            неравенство, коррупция. Президент и Правительство приняли решение развивать социальную сферу и сферу образования. Сейчас в
            стране работает несколько государственных программ, направленных на улучшение благосостояния граждан. В том числе увеличена
            минимальная оплата труда. Политическая ситуация в целом стабильна, особенно после того, как 5 лент назад Президент отменил
            обязательные социальные работы на хлопковых производствах. Это вызывало волны недовольства у населения.
          </>
        ),
      },
      {
        id: 2,
        tabTitle: 'История развития нефтегазового сектора',
        contentTitle: 'История развития нефтегазового сектора',
        content: (
          <>
            В 1953 были обнаружены крупные газовые месторождения Парфии, но перерабатывающий завод построили только через 20 лет. В 2001
            году при участии международных компаний был построен крупный газохимический комплекс. Все месторождения Парфии расположены на
            суше и отличаются несложной геологией. Однако добыча, переработка и транспортировка газа ведется на устаревшем оборудовании,
            которое требует замены и существенных инвестиций.
            <br />
            <br />
            В настоящее время в Парфии преобладают газовые месторождения малого и среднего размера, добыча на которых находится на границе рентабельности.
          </>
        ),
      },
      {
        id: 3,
        tabTitle: 'Позиция компании в стране',
        contentTitle: 'Позиция компании в стране',
        content: (
          <>
            Правительство устраивает регулярные аукционы и занимается привлечением иностранных компаний. Наиболее положительно власти
            относятся к новым участникам рынка, приносящим не только инвестиции, но и проверенные технологии. Ваша компания владеет
            небольшим количеством активов на рынке, однако имеет хорошие шансы и потенциал для расширения присутствия.
          </>
        ),
      },
      {
        id: 4,
        tabTitle: 'Налоговый режим',
        contentTitle: 'Налоговый режим',
        content: (
          <>
            Добывающие компании в Парфии заключают соглашения о разделе продукции – это основная форма недропользования в стране. Параметры
            соглашений одинаковы для всех активов в стране, однако конечные параметры являются предметом переговоров с властями.
          </>
        ),
      },
      {
        id: 5,
        tabTitle: 'Культурные и религиозные особенности',
        contentTitle: 'Культурные и религиозные особенности',
        content: (
          <>
            Как в любой азиатской стране, в Парфии все делают неспешно. Местные жители любят «поразмышлять» над любым вопросом – важным или
            не очень. Да, они продемонстрируют вам радушие и гостеприимство, но вы никогда не узнаете об их настоящем отношении к вам лично
            или к ситуации. Либо узнаете постфактум.
            <br />
            <br />
            Личные и родственные связи играют огромную роль. В последние годы ситуация дошла то того, что на государственном уровне было
            принято несколько законов о прозрачности сделок. Люди очень медленно перестраивают свое отношение к этому вопросу. Поэтому до
            сих пор довольно много задач решается «звонком другу», а не официальным обращением в госструктуру или компанию.
            <br />
            <br />
            Государственной религией является ислам. Народ нельзя назвать агрессивно-религиозным, но стоит относиться к их вере с уважением.
          </>
        ),
      },
      {
        id: 6,
        tabTitle: 'ESG / Экология',
        contentTitle: 'ESG / Экология',
        content: (
          <>
            Несмотря на ратификацию Парижского соглашения, экологическая ситуация в стране довольно плачевная. Правительство участвует в
            соглашении ради возможности работы с иностранными инвесторами, которые придерживаются ESG-повестки. Но учитывая то, что Министр
            энергетики прикладывает много усилий для обеспечения прозрачности работы нефтегазовой сферы, можно говорить о том, что Парфия
            планирует соблюдать условия соглашения. Крупные местные компании уже начали проводить исследования систем переработки отходов и
            поиск возможностей и технологий для улучшения экологической ситуации с выбросами.
            <br />
            <br />
            Проблемой является нехватка воды и водоочистных сооружений, частые пожары в степях и загрязнение рек сельскохозяйственными
            химикатами. В конце прошлого века 60% выбросов вредных веществ в регионе приходились на Парфию. Несмотря на наличие мировых
            технологий, позволяющие сократить вредные отходы производства, не все компании могут себе их позволить.
          </>
        ),
      },
      {
        id: 7,
        tabTitle: 'Министр энергетики',
        contentTitle: 'Министр энергетики',
        content: (
          <>
            Эмин Алишеров, 58 лет.
            <br />
            Женат, 3 детей.
            <br />
            <br />
            Отец Эмина был членом Совета министров Парфии, когда она была частью Гардарики. Дядя Эмина работал в Министерстве
            промышленности. Семья планировала для мальчика политическую карьеру если не в правительстве, то точно в высших эшелонах власти.
            Алишеров же увлекался футболом, но как хороший сын своих родителей и племянник высокопоставленного дяди не стал сопротивляться и
            поступил в Университет Парфии на специальность «Управление и экономика». Танцы остались как хобби. После окончания учебы Эмин не
            стал пользоваться протекцией семьи, а начал строить карьеру с должности простого экономиста на одном из предприятий газодобычи в
            Парфии. Через 15 лет Алишеров уже работал управляющим газохимического комплекса Парфии. Злые языки до сих пор говорят, что к
            этому приложил руку отец Эмина. Последние 3 года Алишеров занимает должность Министра энергетики. Он открыто поддерживает
            политику прозрачных сделок и соглашений. Одним из последних решений министра было введение международных стандартов отчетности в
            газовой отрасли, против которых долгое время выступал предыдущий министр и его коллеги.
            <br />
            <br />
            В свободное время, которого не так много, Алишеров поддерживает федерацию футбола Парфии. Сборная страны спонсируется им
            из лично из семейного фонда. Алишеров говорит, что для него важно вывести команду Парфии на международный уровень. Сейчас он
            ведёт переговоры с одним из известных тренеров, который мог бы вывести команду на новые высоты, но пока безрезультатно.
            <br />
            <br />
            На многих встречах Эмин был замечен в сопровождении либо своего отца - Алибека, либо дяди - Асхата. Как отмечал сам Алишеров, 
            они являются внештатными консультантами при Министерстве энергетики. По слухам, Алишеров старший до сих не спускает взгляд со 
            своего отпрыска и нашептывает про интересы семьи.
          </>
        ),
      },
    ],
  },
  [CountriesPage.Srivijaya]: {
    title: 'Шривиджайя',
    subtitle: 'Президентская республика',
    tabs: [
      {
        id: defaultCountriesDescrTabId,
        tabTitle: 'Политическая и экономическая ситуация',
        contentTitle: 'Политическая и экономическая ситуация',
        content: (
          <>
            Последние 20 лет Шривиджайя – президентская республика. Монархия перестала существовать в результате военного переворота.
            Сторонники королевской семьи до сих пор проживают в отдельном регионе и периодически устраивают вооруженные восстания, которые
            подавляет немногочисленная армия. Правительство формирует и возглавляет Президент, который избирается раз в 5 лет.
            Законодательную власть осуществляет Народный Конгресс, на 70% состоящий из членов Демократической партии. В Конгрессе
            обязательно присутствуют представители каждой административно-территориальной единицы страны. Экономика развивается по
            аграрно-индустриальному типу, но развитие не обошлось без иностранных инвестиций. Проблемой является социально-экономическое
            неравенство: при уровне безработицы 8% за чертой бедности проживает около 15% населения. В качестве меры борьбы с бедностью
            государство ввело минимальный уровень оплаты труда и обязательное пенсионное страхование. Относительно стабильная политическая
            ситуация немного омрачается любовью местных жителей решать проблемы «на месте», минуя законодательство. Правительство
            контролирует цены на базовые продукты питания и ГСМ. Также правительство контролирует ситуацию на рынке добычи полезных
            ископаемых, в том числе обязует иностранные компании брать в штат сотрудников местных жителей.
          </>
        ),
      },
      {
        id: 2,
        tabTitle: 'История развития нефтегазового сектора',
        contentTitle: 'История развития нефтегазового сектора',
        content: (
          <>
            Во второй половине 20 века английские компании начали разведку шельфа Шривиджайи. В результате были обнаружены и нефть, 
            и газ. До 1979 года добычей занимались иностранные компании, но в 1980 году, при помощи военных, правительство 
            национализировало все месторождения.Месторождения в Шривиджайе располагаются на шельфе в глубоководной части южных морей. 
            При этом нефтяные месторождения характеризируются в основном малыми размерами, добыча на которых близка к нижнему уровню 
            рентабельности. Газовые месторождения оцениваются как наиболее крупные среди всех нефтегазодобывающих стран.
            <br />
            <br />
            Добыча нефти и газа требует технологий для глубоководной работы. Около 80% добываемых нефти и газа идут на экспорт по всему миру.
          </>
        ),
      },
      {
        id: 3,
        tabTitle: 'Позиция компании в стране',
        contentTitle: 'Позиция компании в стране',
        content: (
          <>
            Правительство неохотно пускает новичков на рынок и предпочитает сотрудничать с проверенными компаниями, порой даже в ущерб новым
            инвестициям и технологиям. Вам повезло, что Ваша компания уже ведет разработку нефтяных и газовых месторождений.
          </>
        ),
      },
      {
        id: 4,
        tabTitle: 'Налоговый режим',
        contentTitle: 'Налоговый режим',
        content: (
          <>
            Добывающие компании, работающие в нефтегазовой отрасли Шривиджайи, работают согласно соглашению о разделе продукции. Условия
            соглашений могут быть пересмотрены, если добывающая компания инвестировала в экономику значительные суммы. Так Правительство
            удерживает текущих игроков нефтегазового рынка, которых не считает за «чужаков».
          </>
        ),
      },
      {
        id: 5,
        tabTitle: 'Культурные и религиозные особенности',
        contentTitle: 'Культурные и религиозные особенности',
        content: (
          <>
            Местная культура отличается вежливостью и сдержанностью. Может показаться, что жители страны не испытывают сильных эмоций, но на
            деле это не так. Это можно сравнить с походом в ресторан: вам подадут красивые и вкусные блюда, но никогда не пустят на кухню.
            Отношение к иностранцам подчеркнуто-уважительное, но на местном языке их называют «чужаками». Например, в конфликтной ситуации,
            при равных условиях, гражданин Шривиджайи будет прав, а иностранец – не очень.
            <br />
            <br />
            Выгодные знакомства в структурах власти могут работать лучше любых законодательных актов.
            <br />
            <br />
            Трепетное отношение к государственным и религиозным праздникам – это аттракцион для туристов и выходные для местных жителей, в
            такие дни ваши деловые встречи будут перенесены, а работа приостановлена.
          </>
        ),
      },
      {
        id: 6,
        tabTitle: 'ESG / Экология',
        contentTitle: 'ESG / Экология',
        content: (
          <>
            Правительство страны много лет не обращало внимания на экологические проблемы, связанные с вырубкой лесов. После того как в
            страну начали активно привлекать иностранных инвесторов, отношение к экологии изменилось на государственном уровне. Местные
            компании обязали пересмотреть системы переработки вредных отходов. Появились документы, регулирующие правонарушения в сфере
            экологии. Но пока все процессы происходят на бумаге. Правительство Шривиджайи заявляет о поддержке зеленой повестки, как с
            экологической точки зрения, так и с экономической - это важно для международных компаний, работающих на рынке страны.
            <br />
            <br />В Рамках соблюдения Парижского соглашения по климату, правительство Шривиджайи заявило о стремлении достичь нетто-нулевых
            выбросов углеводорода к 2070 году. Предполагается, что новые технологии могут увеличить стоимость производства и переработки
            нефти и газа, что повлечет за собой снижение прибыли компаний.
          </>
        ),
      },
      {
        id: 7,
        tabTitle: 'Министр энергетики и природных ресурсов',
        contentTitle: 'Министр энергетики и природных ресурсов',
        content: (
          <>
            Анвар Мунир, 60 лет.
            <br />
            Женат, 3 ребенка.
            <br />
            <br />
            Анвар Мунир родился в бедной семье, в одной из провинций Швириджайи. Его родители и многочисленные братья и сестры занимались
            сборами сахарного тростинка. Анвар еще в детстве понял, что не хочет продолжать семейные традиции и с этим нужно что-то делать.
            Он уговорил родителей отправить его в школу, и единственный из 5 детей в семье окончил её. Дальше было поступление в
            Национальный Университет Швириджайи по специальности «Горное дело». Но всё получилось не сразу: Анвар не мог сдать вступительные
            экзамены целых три года. Но перспектива собирать всю жизнь сахарный тростник пугала гораздо больше, чем очередной отказ в вузе.
            В итоге Мунир поступил и даже получал повышенную стипендию от государства.
            <br />
            <br />
            Надо сказать, что у Мунира всё получалось не с первого раза. Получив диплом, он не мог устроиться по профессии. Пока он ждал
            подходящую вакансию, в качестве подработки начал писать статьи в местную газету. Его талант заинтересовал редактора, и Мунира
            взяли в штат. Через год работы в медиа подходящая вакансия нашлась, и будущий министр энергетики и природных ресурсов уехал
            заниматься разработкой нового газового месторождения.
            <br />
            <br />
            Редкая история успеха бывает без удачного стечения обстоятельств. Через 5 лет Муниру позвонил тот самый редактор и сказал, что
            газета продается. Сумма была не очень внушительной, т.к. издание дышало на ладан, но это были все накопления Мунира. И он
            рискнул. Не оставляя основную работу в нефтегазовой отрасли, он параллельно занимался развитием газеты. Управленческий талант и
            упрямство сделали свое дело: через несколько лет газеты стала самой популярной в Шривиджайе, а Мунир начал получать неплохой
            доход. На какое-то время Мунир полностью погрузился в медиабизнес. Но тема природных ресурсов и энергетики не переставала его
            интересовать. К тому же он стал совладельцем местных авиалиний.
            <br />
            <br />
            Став членом Демократической партии, Мунир с его талантом управлять и организовывать, очень быстро попал в члены Парламента. Его
            уже два раза переизбирали министром энергетики и природных ресурсов.
            <br />
            <br />
            Мунир не только успешно ведет бизнес, интересуется новыми авиационными технологиями и занимает должность министра, но и
            развивает детскую образовательную программу в регионах Швириджайи.
            <br />
            <br />
            На деловых встречах Анвар Мунир зачастую присутствует с заместителем министра энергетики и природных ресурсов по вопросам 
            лицензирования и недропользования Набижоном Гаязом, который длительное время работал в иностранных нефтегазовых корпорациях и имеет 
            богатый опыт в оценке участков и геологоразведке.
            <br />
            <br />
            Местные жители называют Мунира «Крысой», т.к. в местной мифологии это животное символизирует живой ум и ловкость.
          </>
        ),
      },
    ],
  },
};

const Countries: FC = () => {
  const [activeCountriesPage, setActiveCountriesPage] = useState<CountriesPage>(defaultCountriesPage);

  const handleSetActiveCountriesPage = useCallback((value: any) => {
    setActiveCountriesPage(value);
  }, []);

  let activeContent;
  switch (activeCountriesPage) {
    case CountriesPage.CountriesMap:
      activeContent = <CountriesMap setActiveCountriesPage={handleSetActiveCountriesPage} />;
      break;
    case CountriesPage.Valhalla:
    case CountriesPage.Gardarika:
    case CountriesPage.Assyria:
    case CountriesPage.Parthia:
    case CountriesPage.Srivijaya:
      activeContent = <CountryPage country={countries[activeCountriesPage]!} setActiveCountriesPage={handleSetActiveCountriesPage} />;
      break;
  }

  return <div>{activeContent}</div>;
};

export default Countries;
