import {combineReducers} from 'redux';

import appReducer from './appSlice';
import Main from '../pages/Main/redux/MainSlice';
import LicensingReducer from '../pages/Licensing/redux/LicensingSlice';
import GRRReducer from '../pages/GRR/redux/GRRSlice';
import Mining from '../pages/Mining/redux/MiningSlice';
import Technologies from '../pages/Technologies/redux/TechnologiesSlice';
import Users from '../pages/Users/redux/UsersSlice';
import Management from '../pages/Management/redux/ManagementSlice';
import AddNews from '../pages/AddNews/redux/AddNewsSlice';
import Accept from '../pages/Accept/redux/AcceptSlice';
import LicensingHistory from '../pages/LicensingHistory/redux/LicensingHistorySlice';
import GRRHistory from '../pages/GRRHistory/redux/GRRHistorySlice';
import MiningHistory from '../pages/MiningHistory/redux/MiningHistorySlice';
import WikiReducer from '../pages/Wiki/redux/WikiSlice';
import AddReport from '../pages/AddReport/redux/AddReportSlice';
import TechnologiesHistory from '../pages/TechnologiesHistory/redux/TechnologiesHistorySlice';
import ReportsGraphCompany from '../pages/ReportsGraphCompany/redux/ReportsGraphCompanySlice';
import ReportsGraphRegions from '../pages/ReportsGraphRegions/redux/ReportsGraphRegionsSlice';
import Agreements from '../pages/Agreements/redux/AgreementsSlice';
import ReportsCompany from '../pages/ReportsCompany/redux/ReportsCompanySlice';
import NewsHistory from '../pages/NewsHistory/redux/NewsHistorySlice';
import ReportsRegions from '../pages/ReportsRegions/redux/ReportsRegionsSlice';

// Reducers
const rootReducer = combineReducers({
  app: appReducer,
  licensing: LicensingReducer,
  grr: GRRReducer,
  mining: Mining,
  technologies: Technologies,
  users: Users,
  management: Management,
  news: Main,
  add_news: AddNews,
  accept: Accept,
  licensing_history: LicensingHistory,
  grr_history: GRRHistory,
  mining_history: MiningHistory,
  wiki: WikiReducer,
  add_report: AddReport,
  technologies_history: TechnologiesHistory,
  reports_graph_company: ReportsGraphCompany,
  reports_graph_regions: ReportsGraphRegions,
  agreements: Agreements,
  reports_company: ReportsCompany,
  news_history: NewsHistory,
  reports_regions: ReportsRegions,
});

export default rootReducer;
