import { takeLatest, fork, put, call} from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { fetchStart, fetchSucces, fetchError,
    getCommonRateStart, getCommonRateSuccess, getCommonRateError,
    getRegionRateStart, getRegionRateSuccess, getRegionRateError } from './MainSlice';

function* fetchData (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'News',
            payload: action.payload
          });
        yield put(fetchSucces(response))
    } catch (error) {
        console.log(error)
        yield put(fetchError())
    }
}

function* getCommonRate () {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'CommonParameters',
            payload: {}
          });
        yield put(getCommonRateSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getCommonRateError())
    }
}

function* getRegionRate (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'RegionParameters',
            payload: action.payload
          });
        yield put(getRegionRateSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getRegionRateError())
    }
}

function* fetchDataWatcher () {
    yield takeLatest(fetchStart.type, fetchData)
}

function* getCommonRateWatcher () {
    yield takeLatest(getCommonRateStart.type, getCommonRate)
}

function* getRegionRateWatcher () {
    yield takeLatest(getRegionRateStart.type, getRegionRate)
}


export const MainSagas = [fork(fetchDataWatcher), fork(getCommonRateWatcher), fork(getRegionRateWatcher)];
//не забываем добавлять сагу в рут- сагу