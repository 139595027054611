import { takeLatest, fork, put, call} from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../utils';
import {  getUsersStart, getUsersSuccess, getUsersError, 
          loginStart, loginSuccess, loginError, logoutStart,
          getStatusStart, getStatusRoundSuccess, getStatusNoRoundSuccess, getStatusError } from './appSlice';
import { setAuthData, resetAuthData } from '../utils/apiUtils';

function* getUsers () {
  try {
    const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
      apiMethod: 'Users',
      payload: {
        }
      });
    yield put(getUsersSuccess(response))
    } catch (error) {
    yield put(getUsersError(error))
  }
}

function* getStatus () {
  try {
    const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
      apiMethod: 'CurrentInfo',
      payload: {
        }
      });
      if (response.result.currentRound) {
        const startOn = new Date();   /*response.result.currentRound.startOn*/
        const timer = startOn.setSeconds(startOn.getSeconds() + response.result.timeLeft);
        const roundNumber = response.result.currentRound.number;
        const year = response.result.currentRound.year;
        const result = { timer, roundNumber, year }
        yield put(getStatusRoundSuccess(result))
      } else {
        yield put(getStatusNoRoundSuccess())
      }
    } catch (error) {
    yield put(getStatusError(error))
  }
}

function* login (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.login, {
          apiMethod: 'Login',
          payload: action.payload
        });
      yield setAuthData(response);
      yield put(loginSuccess(response))
      yield getUsers();
      yield getStatus();
      
  } catch (error) {
    yield put(loginError(error))
  }
}

function* logout () {
  try {
    yield* call(HTTPAsyncAPIMethodWrapper.post, {
      apiMethod: 'Logout',
      payload: {}
    });
    yield resetAuthData()
  } catch (error) {
    console.log(error)
  } 
}

function* usersWatcher () {
  yield takeLatest(getUsersStart.type, getUsers)
}

function* loginWatcher () {
  yield takeLatest(loginStart.type, login)
}

function* logoutWatcher () {
  yield takeLatest(logoutStart, logout)
}

function* getStatusWatcher () {
  yield takeLatest(getStatusStart, getStatus)
}


export const appSagas = [fork(usersWatcher), fork(loginWatcher), fork(logoutWatcher), fork(getStatusWatcher)];
//не забываем добавлять сагу в рут- сагу


