import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  fetchNewsStart, fetchNewsSuccess, fetchNewsError,
  addNewsStart, addNewsSucces, addNewsError,
  fetchTechnologiesStart, fetchTechnologiesSuccess, fetchTechnologiesError,
  deleteNewsStart, deleteNewsSuccess, deleteNewsError,
  updateNewsStart, updateNewsSuccess, updateNewsError,
 } from './AddNewsSlice';

 function* fetchNewsData (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'admin/news',
          payload: action.payload
        });
      yield put(fetchNewsSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(fetchNewsError(error))
  }
}


function* addNews (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
            apiMethod: 'admin/news',
            payload: action.payload.request
          });
        yield put(addNewsSucces(response))
        yield put(fetchNewsStart(action.payload.fetchDataParams))
    } catch (error) {
        console.log(error)
        yield put(addNewsError(error))
    }
}

function* fetchTechnologies (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'Techs',
          payload: action.payload
        });
      yield put(fetchTechnologiesSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(fetchTechnologiesError(error))
  }
}

function* deleteNews (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.delete, {
          apiMethod: 'admin/news',
          payload: action.payload.request
        });
      yield put(deleteNewsSuccess(response))
      yield put(fetchNewsStart(action.payload.fetchDataParams))
  } catch (error) {
      console.log(error)
      yield put(deleteNewsError(error))
  }
}

function* updateNews (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
        apiMethod: 'admin/news',
        payload: action.payload.request
      });
    yield put(updateNewsSuccess(response))
    yield put(fetchNewsStart(action.payload.fetchDataParams))
} catch (error) {
    console.log(error)
    yield put(updateNewsError(error))
}
}

function* fetchNewsWatcher () {
  yield takeLatest(fetchNewsStart.type, fetchNewsData)
}

function* addNewsWatcher () {
    yield takeLatest(addNewsStart.type, addNews)
}

function* fetchTechnologiesWatcher () {
  yield takeLatest(fetchTechnologiesStart.type, fetchTechnologies)
}

function* deleteNewsWatcher () {
  yield takeLatest(deleteNewsStart.type, deleteNews)
}

function* updateNewsWatcher () {
  yield takeLatest(updateNewsStart.type, updateNews)
}


export const AddNewsSagas = [fork(addNewsWatcher), fork(fetchTechnologiesWatcher), fork(fetchNewsWatcher), fork(deleteNewsWatcher), fork(updateNewsWatcher)];
//не забываем добавлять сагу в рут- сагу