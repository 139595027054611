import React from "react";
import { Modal } from "react-bootstrap";
import "./Loader.css";
import { LoadingOutlined } from '@ant-design/icons';

export default function Loader({ isLoading }) {
  return (
    <Modal
      backdrop="static"
      centered
      show={isLoading}
      size="lg"
      dialogAs={() => (
          <div className="loader">
              <LoadingOutlined />
          </div>
      )}
    />
  );
}
