import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const LICENSING_HISTORY_STORE_PATH = 'licensing_history';

const LicensingHistorySlice = createSlice({
    name: LICENSING_HISTORY_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE,
        sendedSuccess: false,
    },
    reducers: {
        deleteLicensingItemStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        deleteLicensingItemSucces (store, action) {
            return {...store, status: APP_STATE.IDLE, error: null}
        },
        deleteLicensingItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },


        updateLicensingItemStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        updateLicensingItemSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, sendedSuccess: true, error: null}
        },
        updateLicensingItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        closeModalSlice (store) {
            return {...store, sendedSuccess: false}
        },

    }
})

export const { 
    deleteLicensingItemStart, deleteLicensingItemSucces, deleteLicensingItemError, 
    updateLicensingItemStart, updateLicensingItemSuccess, updateLicensingItemError,
    closeModalSlice
 } = LicensingHistorySlice.actions;
export default LicensingHistorySlice.reducer;
//не забываем редюсер добавить в рут- редюсер

/*export function isLoadingSelector(state: any) {
    return (
      get(state, `${LICENSING_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.data`) || [];
}

export function historyDataSelector(state: any) {
    return get(state, `${LICENSING_STORE_PATH}.history_data`) || [];
}*/

export function errorSelector(state: any) {
    return get(state, `${LICENSING_HISTORY_STORE_PATH}.error`);
}

export function sendedSuccessSelector(state: any) {
    return get(state, `${LICENSING_HISTORY_STORE_PATH}.sendedSuccess`);
}