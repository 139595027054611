import { takeLatest, fork, put, call} from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
    fetchAgreementsStart, fetchAgreementsSucces, fetchAgreementsError, 
    fetchAgreementsParamsStart, fetchAgreementsParamsSucces, fetchAgreementsParamsError, 
    sendAgreementsOrderStart, sendAgreementsOrderSuccess, sendAgreementsOrderError,
    deleteAgreementsItemStart, deleteAgreementsItemSuccess, deleteAgreementsItemError,
    updateAgreementsItemStart, updateAgreementsItemSuccess, updateAgreementsItemError
 } from './AgreementsSlice';

function* fetchAgreementsData (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'Agreements',
            payload: action.payload
          });
        yield put(fetchAgreementsSucces(response))
    } catch (error) {
        console.log(error)
        yield put(fetchAgreementsError(error))
    }
}

function* fetchAgreementsParams (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'AgreementParameters',
          payload: action.payload
        });
      yield put(fetchAgreementsParamsSucces(response))
  } catch (error) {
      console.log(error)
      yield put(fetchAgreementsParamsError(error))
  }
}

function* sendAgreements (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
          apiMethod: 'Agreements',
          payload: action.payload.request
        });
      yield put(sendAgreementsOrderSuccess(response))
      yield put(fetchAgreementsStart(action.payload.fetchDataParams))
  } catch (error) {
      console.log(error)
      yield put(sendAgreementsOrderError(error))
  }
}

function* deleteAgreementsItem (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.delete, {
          apiMethod: 'Agreements',
          payload: action.payload.request
        });
      yield put(deleteAgreementsItemSuccess(response))
      yield put(fetchAgreementsStart(action.payload.fetchDataParams))
  } catch (error) {
      console.log(error)
      yield put(deleteAgreementsItemError(error))
  }
}

function* updateAgreementsItem (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
        apiMethod: 'Agreements',
        payload: action.payload.request
      });
    yield put(updateAgreementsItemSuccess(response))
    yield put(fetchAgreementsStart(action.payload.fetchDataParams))
} catch (error) {
    console.log(error)
    yield put(updateAgreementsItemError(error))
}
}

function* fetchDataWatcher () {
    yield takeLatest(fetchAgreementsStart.type, fetchAgreementsData)
}

function* fetchParamsWatcher () {
  yield takeLatest(fetchAgreementsParamsStart.type, fetchAgreementsParams)
}

function* sendAgreementsOrderWatcher () {
    yield takeLatest(sendAgreementsOrderStart.type, sendAgreements)
}

function* deleteAgreementsItemWatcher () {
  yield takeLatest(deleteAgreementsItemStart.type, deleteAgreementsItem)
}

function* updateAgreementsItemWatcher () {
  yield takeLatest(updateAgreementsItemStart.type, updateAgreementsItem)
}

export const AgreementsSagas = [fork(fetchDataWatcher), fork(fetchParamsWatcher), fork(sendAgreementsOrderWatcher), fork(deleteAgreementsItemWatcher), fork(updateAgreementsItemWatcher)];
//не забываем добавлять сагу в рут- сагу