import {ReactNode} from 'react';

export enum PanelTab {
  World = 'world',
  Countries = 'countries',
  Companies = 'companies',
  Taxes = 'taxes',
}
export const defaultPanelTab = PanelTab.World;

export enum CountriesPage {
  CountriesMap = 'CountriesMap',
  Valhalla = 'Valhalla',
  Gardarika = 'Gardarika',
  Assyria = 'Assyria',
  Parthia = 'Parthia',
  Srivijaya = 'Srivijaya',
}
export const defaultCountriesPage = CountriesPage.CountriesMap;

interface CountryTab {
  id: number;
  tabTitle: string;
  contentTitle: string;
  content: ReactNode;
}

export interface Country {
  title: string;
  subtitle: string;
  tabs: CountryTab[];
}

export const defaultCountriesDescrTabId = 1;

interface CompanyTab {
  id: number;
  tabTitle: string;
  contentTitle: string;
  contentSubtitle: string;
  content: ReactNode;
}

export interface Companies {
  defaultTabs: CompanyTab[];
  companiesTabs: CompanyTab[];
  charactersTabs: CompanyTab[];
}

interface TaxesTab {
  id: number;
  tabTitle: string;
  contentTitle: string;
  contentSubtitle: string;
  content: ReactNode;
}

export interface Taxes {
  defaultTabs: TaxesTab[];
  taxesTabs: TaxesTab[];
}
