import {Radio, RadioChangeEvent} from 'antd';
import cn from 'classnames';
import React, {FC, useState} from 'react';
import s from './wiki.module.css';
import {defaultPanelTab, PanelTab} from './models/wiki';
import World from './components/PanelTabs/world';
import Countries from './components/PanelTabs/countries';
import Companies from './components/PanelTabs/companies';
import Taxes from './components/PanelTabs/taxes';

const titleByTab: Record<PanelTab, string> = {
  [PanelTab.World]: 'Мир',
  [PanelTab.Countries]: 'Страны',
  [PanelTab.Companies]: 'Компании и персонажи',
  [PanelTab.Taxes]: 'Налоги',
};

const contentByTab: Record<PanelTab, FC> = {
  [PanelTab.World]: World,
  [PanelTab.Countries]: Countries,
  [PanelTab.Companies]: Companies,
  [PanelTab.Taxes]: Taxes,
};

const Wiki: FC = () => {
  const [activePanelTab, setActivePanelTab] = useState<PanelTab>(defaultPanelTab);

  const onChange = (e: RadioChangeEvent) => {
    setActivePanelTab(e.target.value);
  };

  const ActiveContent = contentByTab[activePanelTab];

  return (
    <div>
      <Radio.Group onChange={onChange} value={activePanelTab} className="countryPanel--group">
        {Object.values(PanelTab).map((tab) => (
          <Radio.Button key={tab} value={tab} className={cn('countryPanel--element', s.panelButton)}>
            {titleByTab[tab]}
          </Radio.Button>
        ))}
      </Radio.Group>

      <div className={s.contentBlock}>
        <ActiveContent />
      </div>
    </div>
  );
};

export default Wiki;
