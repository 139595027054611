import React, { ReactElement, useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { newGameStart, editGameStart } from "../redux/ManagementSlice";

const NewGameModal = (props: any): ReactElement => {  
  const dispatch = useDispatch();
  const { showModal, setShowModal, row, setRow } = props;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [showApprove, setShowApprove] = useState(false);


  const sendGameHandler = () => {
    if (!row) dispatch(newGameStart({name, description}));
    if (row) dispatch(editGameStart({id: row.id, name, description}));
    setName('');
    setDescription('');
    setShowApprove(false);
    setShowModal(false);
    setRow(null);
  }

  useEffect(()=>{
    if (row) {
      setName(row.name);
      setDescription(row.description);
    } 
  }, [row])

  const closeApproveModal = () => {
    setShowModal(true);
    setShowApprove(false)
  }

  const changeNameHandler = (e: React.BaseSyntheticEvent) => {
    setName(e.target.value)
  }

  const changeDescriptionHandler = (e: React.BaseSyntheticEvent) => {
    setDescription(e.target.value)
  }

  const approveHandler = () => {
    setShowApprove(true);
    setShowModal(false)
  }

  const closeModal = () => {
    setName('');
    setDescription('');
    setShowModal(false);
    setRow(null);
  }
  

return (
  <>
    <Modal show={showModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{row ? 'Редактирование игры' : 'Создание новой игры'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row className="management--block__modalRow">
                    <Col xs={4}>
                        <p>Название</p>
                    </Col>
                    <Col xs={6}>
                        <Form.Control
                            onChange={changeNameHandler}
                            //onBlur={singlePriceValidator}
                            value={name}
                            type="text"
                        />
                    </Col>
                </Row>
                <Row className="management--block__modalRow">
                    <Col xs={4}>
                        <p>Описание</p>
                    </Col>
                    <Col xs={6}>
                        <Form.Control
                            onChange={changeDescriptionHandler}
                            //onBlur={singlePriceValidator}
                            value={description}
                            type="text"
                        />
                    </Col>
                </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={approveHandler} >
            Сохранить
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Отменить
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showApprove} onHide={closeApproveModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{row ? 'Вы действительно хотите изменить игру?' : 'Вы действительно хотите начать новую игру?'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={sendGameHandler} >
            Да
          </Button>
          <Button variant="secondary" onClick={closeApproveModal}>
            Нет
          </Button>
        </Modal.Footer>
      </Modal>
  </>
);}

export default NewGameModal;