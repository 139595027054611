import React, { ReactElement } from "react";
import CountryPanel from "../../../components/CountryPanel/CountryPanel";


export default function LicensingHeader(props: any): ReactElement {

  const { setRegionId } = props;

  return (
      <div className="licensing--header">
        <div className="d-flex licensing--header__countryBlock">
        <CountryPanel setRegionId={setRegionId}/>
        {/*<Button variant="success" className="licensing--header__endRoundButton">
          <CaretRightOutlined />
          Завершить раунд
        </Button>*/}
        </div>
      </div>
  );
}
