import cx from "classnames";
import React, { useMemo, useState } from "react";
import { Accordion, Col, ListGroup, useAccordionButton } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import "./SideMenu.css";
//import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';
import { filterMenuItems } from "./SideMenu.utils";

/**
 * Настройка включающая или выключающая фильтрацию
 * меню по настройкам пользователя с бека
 */
const isMenuFiltered = true;

export default function SideMenu(props) {
  const { isMenuOpened, sideMenuItems, menudata } = props;
  const { pathname, search }  = useLocation();
  const { push } = useHistory();
  const [activeKey, setActiveKey] = useState(null);
  
  const availableMenuItems = useMemo(
    () =>
      filterMenuItems({
        menudata,
        sideMenuItems,
        isFiltered: isMenuFiltered
      }),
    [menudata, sideMenuItems]
  );
  function handleClickFactory(path) {
    return function() {
      push(path);
    };
  }

  function AccordionToggler(props) {
    const { eventKey, item } = props;
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (activeKey === eventKey) {
        setActiveKey(null);
      } else {
        setActiveKey(eventKey);
      }
    });
    //const icon = activeKey === eventKey ? <CaretDownOutlined /> : <CaretRightOutlined />;

    return (
      <button
        type="button"
        className="sideMenu__item__accordion_toggle"
        onClick={decoratedOnClick}
      >
        <ListGroup.Item className="sideMenu__item">
          <div className="sideMenu__item__icon">
            {item.icon}
          </div>
          <div className="sideMenuItem__item__title">{item.title}</div>
          {/*<div className="sideMenu__item__icon">
            {icon}
          </div>*/}
        </ListGroup.Item>
      </button>
    );
  }

  return (
    <StyledCol
      active={isMenuOpened ? "true" : undefined}
      className="sideMenu"
    >
      <ListGroup className="sideMenu" variant="flush">
        <Accordion defaultActiveKey={['0']} alwaysopen={'true'}>
          {availableMenuItems
              .map((item, index) => (
            <React.Fragment key={index}>
              {Array.isArray(item.subItems) ? (
                <>
                  <AccordionToggler eventKey={index} item={item} />
                    <>
                      {item.subItems.map((subItem, index) => (
                        <ListGroup.Item
                          key={index}
                          className="sideMenu__sub__item"
                          onClick={handleClickFactory(subItem.path)}
                        >
                          {subItem.icon && <div className="sideMenu__item__icon">
                            {subItem.icon}
                          </div>}
                          <div
                            className={cx({
                              sideMenu__item__active: pathname + search === subItem.path
                            })}
                          >
                            {subItem.title}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </>
                </>
              ) : (
                <ListGroup.Item
                  className="sideMenu__item"
                  eventKey={item.path}
                  onClick={handleClickFactory(item.path)}
                >
                  {item.icon && <div className="sideMenu__item__icon">
                    {item.icon}
                  </div>}
                  <span
                    className={cx({
                      sideMenu__item__active: pathname + search === item.path
                    })}
                  >
                    {item.title}
                  </span>
                </ListGroup.Item>
              )}
            </React.Fragment>
          ))}
        </Accordion>
      </ListGroup>
    </StyledCol>
  );
}

const StyledCol = styled(Col)`
  overflow-x: hidden;
  transition: 0.2s ease-out;
  max-width: ${({ active }) => !active && 0};
  padding: 0;
`;
