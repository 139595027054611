import routes from "../routes";
import { 
  ContainerOutlined,
  OrderedListOutlined, 
  TeamOutlined, 
  DeploymentUnitOutlined, 
  ApartmentOutlined, 
  FormOutlined, 
  FileTextOutlined,
  SnippetsOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  FileProtectOutlined,
  PlayCircleOutlined,
  CheckCircleOutlined,
  GlobalOutlined,
  LikeOutlined,
  CopyOutlined,
} from '@ant-design/icons';

export const sideMenuItems = [
  {
    ...routes.news,
    icon: <ContainerOutlined />
  },
  {
    title: "Панель управления",
    subItems: [
      {
        ...routes.licensing,
        icon: <FileProtectOutlined />
      },
      {
        ...routes.grr,
        icon: <PlayCircleOutlined />
      },
      {
        ...routes.mining,
        icon: <DeploymentUnitOutlined />
      },
      {
        ...routes.technology,
        icon: <ApartmentOutlined />,
      },
      {
        ...routes.agreements,
        icon: <LikeOutlined />,
      },
    ]
  },
  {
    title: "Аналитика",
    subItems: [
      {
        ...routes.wiki,
        icon: <GlobalOutlined />
      },
      {
        ...routes.reportsGraphCompany,
        icon: <BarChartOutlined />
      },
      {
        ...routes.reportsGraphRegions,
        icon: <AreaChartOutlined />
      },
      {
        ...routes.reportsCompany,
        icon: <FileTextOutlined />
      },
      {
        ...routes.reportsRegions,
        icon: <SnippetsOutlined />
      },
    ]
  },
  {
    title: "Администрирование",
    subItems: [
      {
        ...routes.management,
        icon: <OrderedListOutlined />
      },
      {
        ...routes.accept,
        icon: <CheckCircleOutlined />
      },
      /*{
        ...routes.addReport,
        icon: <FundOutlined />
      },*/
      {
        ...routes.users,
        icon: <TeamOutlined />
      },
    ]
  },
  {
    title: "Новости",
    subItems: [
      {
        ...routes.addNews,
        title: "Мировые новости",
        path: "/addNews?type=2",
        icon: <FormOutlined />
      },
      {
        ...routes.addNews,
        title: "Новости компании",
        path: "/addNews?type=3",
        icon: <CopyOutlined />
      },
      /*{
        ...routes.addNews,
        title: "Новости технологий",
        path: "/addNews?type=4",
        icon: <SnippetsOutlined />
      },*/
    ]
  },
];
