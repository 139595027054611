import { Select } from 'antd';
import React, { ReactElement, useState, useMemo, useEffect } from 'react';
//import cpu from "../../../assets/img/cpu.png";
//import newsIcon2 from "../../../assets/img/news_icon_2.jpg";
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {  fetchTechnologiesDescriptionStart, 
          technologiesDescriptionSelector, 
          fetchTechnologiesNewsStart,
          clearTechnologies } from "../redux/TechnologiesSlice";
import TechModal from './techModal';


const AvailableTab = (props: any) : ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { data } = props;
    const [selected, setSelected] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedTech, setSelectedTech] = useState<any>(null);

    const description = useSelector(technologiesDescriptionSelector);
    //const news = useSelector(technologiesNewsSelector);

    useEffect(()=>{
      if (selectedTech) {
        dispatch(fetchTechnologiesDescriptionStart({id: selectedTech.descriptionId}));
        dispatch(fetchTechnologiesNewsStart({techId: selectedTech.id}))
        return () => {dispatch(clearTechnologies())}
      }
    }, [dispatch, selectedTech])

    const handleChange = (row: { value: string; label: React.ReactNode }) => {
        setSelected(+row.value);
        setSelectedTech(data.find((el: any) => el.id === +row.value))
      };

    const showModalHandler = () => {
        setShowModal(true);
    }  

    const selectData = [
        {
            id: '0',
            name: 'Выберите',
            descriptionId: null

        }, 
        ...data.map((el: any) => ({id: el?.id, name: el?.name, descriptionId: el?.descriptionId}))
      ]

      const toHistoryHandler = () => {
        history.push("/technology_history");
      }
  
return (
    <>
        <Row>
            <Col xs={7} className='technologies--available'>
                <h4>Выберите технологию для инвестирования:</h4>
                <Select
                    labelInValue
                    defaultValue={{ value: '0', label: selectData[0].name }}
                    className='technologies--available__select'
                    onChange={handleChange}
                >
                    { 
                        selectData.map(el => (
                        <Select.Option key={el.id} value={el.id} >{el.name}</Select.Option>        
                        ))
                    }   
                </Select>
                
                {selectedTech &&
                <>
                  <div className='technologies--available__descriptionBlock'>
                      <h5>Бриф по технологии:</h5>
                      <p>{description?.brief || ''}</p>
                  </div>
                  <div className='technologies--available__descriptionBlock'>
                      <h5>Суть технологии:</h5>
                      <p>{description?.essence || ''}</p>
                  </div>
                  <div className='technologies--available__descriptionBlock'>
                      <h5>Параметры технологии:</h5>
                      <pre>{description?.effects || ''}</pre>
                  </div>
                </>}
            </Col>
            <Col xs={5} className='technologies--available'>
                <Row>
                    <Col xs={6}>
                        <Button variant="success" disabled={!selected} onClick={showModalHandler}>
                            Инвестировать в технологию
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button style={{backgroundColor: '#5F8AB6', border: 'none'}} onClick={toHistoryHandler}>
                            Принятые решения
                        </Button>
                    </Col>
                </Row>
                {/*selectedTech &&
                <>
                  <div className="d-flex technologies--available--item__header">
                      <img src={cpu} className="technologies--available--icon" alt=""/>
                      <h5>Новости по технологии</h5>
                  </div>
                  {news && news.map((el: any) => (
                      <div className="technologies--available--item" key={el.id}>
                      <div className="d-flex">
                          <img src={newsIcon2} className="technologies--available--icon" alt=""/>
                          <h5>{el.title}</h5>
                      </div>
                      <p>{el.body}</p>  
                  </div>
                ))}
                  </>*/}
            </Col>
        </Row>
        
        <TechModal showModal={showModal} setShowModal={setShowModal} row={selectedTech} isNew={true} />
    </>
)}

export default AvailableTab;