import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import View from './GRR.view';
import { IRouterProps } from "../../constants/interfaces";
import {fetchGrrTableStart, dataSelector, errorSelector} from './redux/GRRSlice';

export default function GRR(routerProps: IRouterProps) {
    const dispatch = useDispatch();
    const error = useSelector(errorSelector);
    const data = useSelector(dataSelector);

    const [regionId, setRegionId] = useState(0);
    const [fieldType, setFieldType] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        dispatch(fetchGrrTableStart({regionId, fieldType}));
      }, [dispatch, regionId, fieldType]);
    

    return (
        <View 
            {...routerProps}
            data={data}
            setRegionId={setRegionId}
            setFieldType={setFieldType}
            selectedRowKeys={selectedRowKeys} 
            setSelectedRowKeys={setSelectedRowKeys}
            error={error}
        />
    )
}
