import superagent from "superagent";
import { resetAuthData } from './apiUtils';

const HTTPAsync = {
  post: (url, body) =>
    new Promise((resolve, reject) => {
      superagent
        .post(url)
        .send(body)
        .set('Authorization', `Bearer ${localStorage.getItem('sozvezdie_game_token')}`)
        .set("Accept", "application/json")
        .end((err, response) => {
          if (err) {
            if (response.statusCode === 401) resetAuthData(); 
            reject(response.body || response.text);
            return;
          }
            const payload = response.body || response.text;
            resolve(payload);
        });
    }),

  get: (url, params) =>
    new Promise((resolve, reject) => {
      //const token = localStorage.getItem('sozvezdie_game_token');
      superagent
        .get(url)
        .query(params)
        .set('Authorization', `Bearer ${localStorage.getItem('sozvezdie_game_token')}`)
        .set("Accept", "application/json")
        .end((err, response) => {
          if (err) {
            if (response.statusCode === 401) resetAuthData();
            reject(response.body || response.text);
            return;
          }

          const payload = response.body || response.text;
          resolve(payload);
        });
    }),

    put: (url, body) =>
    new Promise((resolve, reject) => {
      superagent
        .put(url)
        .send(body)
        .set('Authorization', `Bearer ${localStorage.getItem('sozvezdie_game_token')}`)
        .set("Accept", "application/json")
        .end((err, response) => {
          if (err) {
            if (response.statusCode === 401) resetAuthData();
            reject(response.body || response.text);
            return;
          }
            const payload = response.body || response.text;
            resolve(payload);
        });
    }),

    delete: (url, params) =>
    new Promise((resolve, reject) => {
      superagent
        .delete(url)
        .query(params)
        .set('Authorization', `Bearer ${localStorage.getItem('sozvezdie_game_token')}`)
        .set("Accept", "application/json")
        .end((err, response) => {
          if (err) {
            if (response.statusCode === 401) resetAuthData();
            reject(response.body || response.text);
            return;
          }
            const payload = response.body || response.text;
            resolve(payload);
        });
    }),

    login: (url, body) =>
    new Promise((resolve, reject) => {
      superagent
        .post(url)
        .send(body)
        .set("Accept", "application/json")
        .end((err, response) => {
          if (err) {
            reject(response.body || response.text);
            return;
          }
            const payload = response.body || response.text;
            resolve(payload);
        });
    }),

    file_send: (url, formData) =>
    new Promise((resolve, reject) => {
      superagent
        .post(url)
        .send(formData)
        .set('Authorization', `Bearer ${localStorage.getItem('sozvezdie_game_token')}`)
        .end((err, response) => {
          if (err) {
            reject(response.body || response.text);
            return;
          }
            const payload = response.body || response.text;
            resolve(payload);
        });
    }),

    file_get: (url) =>
    new Promise((resolve, reject) => {
      superagent
        .get(url)
        //.query(params)
        .set('Authorization', `Bearer ${localStorage.getItem('sozvezdie_game_token')}`)
        .set("Accept", "application/json")
        .responseType('blob')
        .end((err, response) => {
          if (err) {
            if (response.statusCode === 401) resetAuthData();
            reject('Отчет не найден');
            return;
          }
          
          let filename = response.headers['content-disposition']
            .split(';')
            .find((n) => n.includes('filename='))
            .replace('filename=', '')
            .replaceAll('"', '')
            .trim(); 

          const fileURL = URL.createObjectURL(response.body)
          if (fileURL) {
              const tempLink = document.createElement('a');
              tempLink.style.display = 'none';
              tempLink.href = fileURL;
              tempLink.setAttribute('download', filename || 'file.xlsm');
              tempLink.setAttribute('target', '_blank');
              document.body.appendChild(tempLink);
              tempLink.click();
              document.body.removeChild(tempLink);
              URL.revokeObjectURL(fileURL);
          }
          resolve({});
        });
    }),

    file_view: (url) =>
    new Promise((resolve, reject) => {
      superagent
        .get(url)
        //.query(params)
        .set('Authorization', `Bearer ${localStorage.getItem('sozvezdie_game_token')}`)
        .set("Accept", "application/json")
        .responseType('blob')
        .end((err, response) => {
          if (err) {
            if (response.statusCode === 401) resetAuthData();
            reject('Отчет не найден');
            return;
          }
          
          const fileURL = URL.createObjectURL(response.body)
          resolve(fileURL);
        });
    }),
};

export default HTTPAsync;