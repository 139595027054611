import { all } from "redux-saga/effects";

import { appSagas } from "./appSaga";
import { MainSagas } from "../pages/Main/redux/MainSaga";
import { LicensingSagas } from "../pages/Licensing/redux/LicensingSaga";
import { GRRSagas } from "../pages/GRR/redux/GRRSaga";
import { MiningSagas } from "../pages/Mining/redux/MiningSaga";
import { TechnologiesSagas } from "../pages/Technologies/redux/TechnologiesSaga";
import { UsersSagas } from "../pages/Users/redux/UsersSaga";
import { ManagementSagas } from "../pages/Management/redux/ManagementSaga";
import { AddNewsSagas } from "../pages/AddNews/redux/AddNewsSaga";
import { AcceptSagas } from "../pages/Accept/redux/AcceptSaga";
import { LicensingHistorySagas } from "../pages/LicensingHistory/redux/LicensingHistorySaga";
import { GRRHistorySagas } from "../pages/GRRHistory/redux/GRRHistorySaga";
import { MiningHistorySagas } from "../pages/MiningHistory/redux/MiningHistorySaga";
import { AddReportSagas } from "../pages/AddReport/redux/AddReportSaga";
import { TechnologiesHistorySagas } from "../pages/TechnologiesHistory/redux/TechnologiesHistorySaga";
import { ReportsGraphCompanySagas } from "../pages/ReportsGraphCompany/redux/ReportsGraphCompanySaga";
import { ReportsGraphRegionsSagas } from "../pages/ReportsGraphRegions/redux/ReportsGraphRegionsSaga";
import { AgreementsSagas } from "../pages/Agreements/redux/AgreementsSaga";
import { ReportsCompanySagas } from "../pages/ReportsCompany/redux/ReportsCompanySaga";
import { NewsHistorySagas } from "../pages/NewsHistory/redux/NewsHistorySaga";
import { ReportsRegionsSagas } from "../pages/ReportsRegions/redux/ReportsRegionsSaga";

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...MainSagas,
    ...LicensingSagas,
    ...GRRSagas,
    ...MiningSagas,
    ...TechnologiesSagas,
    ...ManagementSagas,
    ...AddNewsSagas,
    ...AcceptSagas,
    ...LicensingHistorySagas,
    ...GRRHistorySagas,
    ...MiningHistorySagas,
    ...AddReportSagas,
    ...TechnologiesHistorySagas,
    ...ReportsGraphCompanySagas,
    ...ReportsGraphRegionsSagas,
    ...AgreementsSagas,
    ...ReportsCompanySagas,
    ...UsersSagas,
    ...NewsHistorySagas,
    ...ReportsRegionsSagas,
  ]);
}
