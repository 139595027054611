import React, { ReactElement } from "react";
import { Row, Col } from 'react-bootstrap';
import Loader from "../../components/Loader";
import GRRTable from "./components/GRRTable";
import GRRHeader from './components/GRRHeader';
import GRRActionPanel from './components/GRRActionPanel';
import './components/grr.css'
import GRRHistoryPanel from './components/GRRHistoryPanel';
import Notification from "../../components/Notification";

export default function View(props: any): ReactElement {
  const {
    isLoading,
    data,
    setRegionId,
    setFieldType,
    error,
    selectedRowKeys, 
    setSelectedRowKeys
  } = props;

  return (
    <>
      <GRRHeader 
        setRegionId={setRegionId}
        setFieldType={setFieldType}
      />

      {/* TODO: move to separate component? */}
      <div className="assets-content">
        <Row className="grr--header__stageBlock">
          <Col xs={9}>
            <Row className="justify-content-between align-items-center">
              <Col xs={6}>
                <h4>Выберите актив для старта ГРР</h4>
              </Col>
              <Col xs={1}>
                {/*<LicenseTypePanel setFieldType={setFieldType} />*/}
              </Col>
              <Col xs={3} className="grr--header__historyButton">
                <GRRHistoryPanel />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={9}>
            <GRRTable 
              data={data}
              selectedRowKeys={selectedRowKeys} 
              setSelectedRowKeys={setSelectedRowKeys}
            />
          </Col>
          <Col sm={3}>
            <GRRActionPanel selectedRowKeys={selectedRowKeys} data={data}/>
          </Col>
        </Row>

      </div>

      <Loader isLoading={isLoading} />
      <Notification success={false} error={error} />
    </>
  );
}
