import {Button} from 'antd';
import {FC, useCallback, useState} from 'react';
import {Taxes as ITaxes} from '../../../models/wiki';
import VerticalTabs from '../../vertical_tabs';
import s from './index.module.css';
import assyriaSchemeImg from '../../../../../assets/img/assyria_scheme.png';
import parthiaAndSrivijayaSchemeImg from '../../../../../assets/img/parthia_and_srivijaya_scheme.png';

enum TaxesId {
  Modes = 1,

  Valhalla = 2,
  Gardarika = 3,
  Assyria = 4,
  ParthiaAndSrivijaya = 5,
}
const defaultTaxesId = TaxesId.Modes;

const taxes: ITaxes = {
  defaultTabs: [
    {
      id: TaxesId.Modes,
      tabTitle: '',
      contentTitle: 'Налоговые режимы',
      contentSubtitle: '',
      content: (
        <>
          Состав налогов и механизмы их расчетов варьируется от страны к стране, они определяются моделью недропользования, сложившейся в
          каждом отдельном регионе. Налоговые системы в мире делятся на:
          <br />
          <ul>
            <li>концессионные, в рамках которого компании получают право на разработку недр и выплачивают комплекс платежей государству</li>
            <li>контрактные, который основан на разделе продукции между компанией и государством как собственником недр.</li>
          </ul>
        </>
      ),
    },
  ],
  taxesTabs: [
    {
      id: TaxesId.Valhalla,
      tabTitle: 'Валгалла',
      contentTitle: 'Валгалла',
      contentSubtitle: 'Налог на дополнительный доход',
      content: (
        <>
          Система государственного регулирования нефтегазодобычи Валгаллы основана на государственной собственности и активном влиянии на
          отрасль. Согласно законодательству, государству принадлежат все права собственности на минеральные ресурсы континентального шельфа
          и право распоряжаться добытыми ресурсами. К добыче нефти и газа на шельфе в Валгалле допускаются государственные и иностранные
          компании, при этом власти ведут жесткий контроль за отраслью. Парламент занимается вопросами предоставление новых акваторий для
          разведки, а также утверждает крупные добычные проекты. Министерство нефти выдает разрешения на производство ГРР и добычу
          углеводородов. В целом государство делает все, чтобы получить максимальный доход в бюджет при сохранении заинтересованности
          компаний в развитии нефтегазодобычи в стране.
          <br />
          <br />
          Законодательство по налогообложению нефтегазовой отрасли впервые появилось в Валгалле в период выдачи первых лицензий на
          проведение ГРР на континентальном шельфе. Целью издания законов стало распространение юридической базы на шельф, который не входит
          в территориальные границы страны, а также введение специального налогового режима для геологоразведки и добычи углеводородов.
          <br />
          <br />
          Ранее компании также выплачивали роялти, однако со временем такой налог потерял свое практическое значение, т.к. его размер
          зависел от глубины моря в местах разработки месторождения. По мере освоения шельфа глубина на новых участках моря неизбежно
          увеличивалась и вскоре выплаты роялти, привязанного к глубинам, полностью прекратились.
          <br />
          <br />
          Режим налогообложения в Валгалле является одним из самых жестких в мире. Он характеризуется крайне высоким уровнем предельной
          ставки налога на прибыль – 78%. В Валгалле к нефтегазовым компаниям применятся не только общий налог на прибыль (22%), но и
          специальный налог (56%), которым дополнительно облагаются доходы от добычи и транспортировки углеводородов на континентальном
          шельфе. Такие высокие ставки налогов компенсируются налоговыми льготами, в частности компенсациями расходов на геологоразведочные
          работы.
          <br />
          <br />
          Конструкция налоговой системы в автоматическом режиме стимулирует нефтегазовые компании для разведки и разработки новых морских
          месторождений. Целью такой системы является привлечение иностранного капитала в нефтегазодобывающую отрасль страны, а также
          формирование специального фонда для поддержания экономики Валгаллы, когда нефтегазовые доходы в будущем начнут сокращаться.
          <br />
          <br />
          При этом важно отметить, что высокие ставки НДД приводят к негибкости новых достаточно дорогих проектов Валгаллы в арктических
          условиях, т.к. даже минимальные изменения в налоговом бремени могут привести к отказу от реализации проектов.
        </>
      ),
    },
    {
      id: TaxesId.Gardarika,
      tabTitle: 'Гардарика',
      contentTitle: 'Гардарика',
      contentSubtitle: 'Роялти & Налог на дополнительный доход',
      content: (
        <>
          В стране присутствуют концессионные платежи 2 типов: взимаемые с валовых объемов добычи и те, налоговой базой которых являются
          сверхдоходы. Для новых месторождений (на стадиях bluefield и greenfield) применяется налог на дополнительный доход, в то время как
          для зрелых активов используется роялти.
          <div className={s.contentInternalTitle}>Роялти</div>
          Налоговый режим представляет собой регулярные выплаты за право добычи, которые исчисляются с единицы продукции. Ставка роялти
          рассчитывается по скользящим шкалам, которые корректирую установленную государством базовую ставку налога, изменение мировых цен
          на углеводороды и другие параметры, отражающие технологические, геологические, географические и экономические особенности добычи.
          <br />
          <br />
          Такая конструкция налога в Гардарике предусматривает возможность применения пониженной ставки роялти для определенных
          месторождений, что говорит не только о гибкости системы, но и предоставляет поле для лоббирования адресных льгот.
          <div className={s.contentInternalTitle}>Налог на дополнительный доход</div>
          Налог на дополнительный доход в отличие от роялти взимается с финансового результата (дохода от продаж углеводородного сырья за
          вычетом расходов на добычу и логистику).
          <br />
          <br />
          Ставка налога на дополнительный доход в Гардарике составляет 50%., базой для расчета является разница между доходами от продажи
          углеводородов и суммой расходов на добычу. Расходы включают в себя транспортные, операционные и капитальные затраты, налоговые
          отчисления и убыток прошлых лет, проиндексированный на 16,3%. При этом рассчитываемый налог на дополнительный доход всегда больше
          или равен нормативно установленной величины.
          <br />
          <br />
          Применение налога на дополнительный доход не отменяет роялти – фактически, компания уплачивает оба налога, но при этом ставка
          роялти существенно понижается.{' '}
          <span className={s.contentItalic}>
            В Гардарике установлен льготный период с начала эксплуатации месторождения, когда роялти уменьшается на определенный
            коэффициент: до 6 года (включительно) – 0,4, далее равномерный рост коэффициента до 1, начиная с 9 года эксплуатации.
          </span>
          <br />
          <br />
          Кроме того, при налоге на дополнительный доход действует льготная ставка экспортной пошлины.
          <br />
          <br />
          Таким образом, рентабельность в случае налога на дополнительный доход оказывается выше, чем при уплате роялти по полной ставке.
          Данный налоговый режим применяется для стимулирования разработки новых месторождений нефти.
          <br />
          <br />
          Все вышесказанное характерно для нефтяных месторождений Гардарики, в случае с газовыми активами налог на дополнительный доход не
          является экономически целесообразным и не применяется. Это связано отсутствием льготных ставок по роялти для газа - фактически
          компания будет попадать под двойное налогообложение.
          <div className={s.contentInternalTitle}>Экспортная пошлина</div>
          Часть стоимости вывозимого за границу углеводородного сырья, переходящая от компании-экспортера в пользу государства. При помощи
          такого механизма государство изымает часть доходов нефтегазовых компаний и перенаправляет на нужды внутри страны.
          <br />
          <br />
          Ставка экспортной пошлины носит прогрессивный характер по нефти, зависящий от мировых цен (на практике используется одна формула
          расчета ввиду высоких цен) и фиксированный для природного газа.{' '}
          <span className={s.contentItalic}>
            В игре предусмотрено, что все добытые в игре УВС реализуются на мировом рынке (экспортируются) в полном объеме, поэтому
            экспортная пошлина рассчитывается с каждой добытой тонны нефти (другими словами базой расчета служит годовая выручка).
          </span>
          <br />
          <br />
          Ставка экспортной пошлины отличается по типу углеводородного сырья, тем самым позволяя стимулировать выпуск той или иной продукции
          в стране. В Гардарике действуют льготные коэффициенты по экспортной пошлине с 2019 года - равномерное уменьшение с 1 до 0 к 2024
          году.
          <div className={s.contentInternalTitle}>Прочие налоги</div>
          Помимо налогов, специфичных для нефтегазовой отрасли, компании в Гардарике уплачивают налог на прибыль и имущество, по ставкам 20%
          (от EBIT) и 2,2% (от CAPEX), соответственно.
        </>
      ),
    },
    {
      id: TaxesId.Assyria,
      tabTitle: 'Ассирия',
      contentTitle: 'Ассирия',
      contentSubtitle: 'Сервисный контракт',
      content: (
        <>
          Сервисный контракт характерен для таких стран как Ассирия, т.к. обладая необходимым капиталом, страна нуждается только в
          квалифицированных услугах для развития нефтегазовой отрасли. При сервисном контракте компания-подрядчик не получает никакой
          собственности в проекте. Все углеводороды принадлежат государству, компания получает только платежи за оказанные услуги по
          разведке и добыче в размере оговоренного тарифа на единицу продукции. Кроме того, компании может быть предоставлено право на
          покупку добытой ею продукции (с дисконтом от рыночной цены).
          <br />
          <br />
          Как правило, компания самостоятельно предоставляет капитал для разведки и разработки запасов. Если ГРР увенчались успехом
          государство дает возможность компенсировать эти издержки из средств, полученных от продажи нефти или газа, а также выплачивает
          подрядчику вознаграждение за единицу добытой продукции. Механизм расчета доли, причитающейся компании-подрядчику, схож с точки
          зрения экономики на раздел продукции в СРП. Таким образом на первом этапе определяется предельный объем - база для расчета дохода
          компании, исходя из доли государства, которая в Ассирии составляет 50%.
          <br />
          <br />В пределах рассчитанного объема государство компенсируют затраты на разведку и разработку месторождения, а также перечисляет
          компании фиксированное вознаграждение в соответствии с объемами добычи. Вознаграждение подрядчика рассчитывается по скользящей
          шкале (задаваемой R-фактором – отношением суммарных доходов к суммарным издержкам). В Ассирии такое вознаграждение составляет
          5-10$ за баррель нефти (45-90$ за 1 тыс. м3).
          <br />
          <br />
          При этом часть вознаграждения, в Ассирии – это 25%, отходит государству, оставшиеся средства, представляющее прибыль компании,
          подлежат налогообложению, как и любой другой корпоративный доход в общем случае. В Ассирии государство изымает 35% суммы в
          качестве налог на прибыль.
          <br />
          <br />
          Таким образом, контроль за разработкой месторождений остается полностью за государством, при этом страна получает доступ к
          передовым инженерному и технологическому опыту и компетенциям. При этом компания-подрядчик получает фиксированный доход и
          возмещение затрат, фактически нивелируя макроэкономические / ценовые риски.
          <br />
          <br />
          Схематично сервисный контракт, действующий в Ассирии, выглядит следующим образом:
          <div className={s.contentScheme}>
            <img src={assyriaSchemeImg} alt={'Assyria scheme'} />
          </div>
          Как видно из схемы государство нанимает компанию-подрядчика и инвестора для разведки и добычи углеводородного сырья. По сервисному
          контракту компания передает часть добычи государству, оставшаяся часть формирует базу для расчета вознаграждения и возмещения
          затрат инвестору (компании-подрядчику).
          <br />
          <br />В Ассирии применяется тип сервисного контракта с риском, поэтому государство компенсирует затраты на разведку и разработку
          месторождений. В то же время государство изымает свою долю из вознаграждения инвестора, а компания в свою очередь с оставшейся
          суммы дополнительно уплачивает налог на прибыль.
          <br />
          <br />
          Таким образом формируется доход инвестора, состоящий из двух статей: непосредственно вознаграждения за услуги и платы (т.е.
          возмещения затрат) за риск.
        </>
      ),
    },
    {
      id: TaxesId.ParthiaAndSrivijaya,
      tabTitle: 'Парфия и Шривиджайя',
      contentTitle: 'Парфия и Шривиджайя',
      contentSubtitle: 'Соглашение о разделе продукции - СРП',
      content: (
        <>
          Шривиджайя была первой страной, добывающей углеводородное сырье, в которой стало широко применяться соглашение о разделе 
          продукции как юридический документ, разрешающий зарубежным нефтегазодобывающим предприятиям (Инвесторам/Операторам) заниматься 
          добычей на ее территории. Соглашение о разделе продукции были приняты взамен соглашений, предоставляющих исключительные лицензии (концессии).
          <br />
          <br />
          Лицензии, как правило, предоставляют права собственности, в то время как СРП, по своей сути, носят договорной характер. В
          соответствии с принятым законодательством национальные нефтегазовые компании в Шривиджайе и Парфии:
          <br />
          <ul>
            <li>могут сотрудничать с инвесторами/операторами в форме «соглашения о разделе продукции»;</li>
            <li>сроки и условия такого соглашения регулируются государством;</li>
            <li>соглашение о разделе продукции вступает в силу с момента его одобрения Президентом и парламентом.</li>
          </ul>
          Соглашение о разделе продукции – это контрактный тип налоговой системы, который подразумевает наличие долгосрочного договора между 
          инвестором (недропользователем/оператором) и государством (национальной нефте- и/или газодобывающей компанией). По условиям договора 
          управление проектом находится в руках государственной компании, которая утверждает бюджет оператора, его программу работ, затраты, план 
          закупок и персонал, а оператор при этом обеспечивает финансирование, осуществляет производственную деятельность и несет связанные с этим 
          риски. В обмен на это оператор получает долю продукции в качестве компенсации за помощью правительству страны в освоении ресурсов.
          <br />
          <br />
          По аналогии с лицензиями, СРП предусматривает на начальном этапе проведение ГРР (сейсморазведку и ПРБ - поисково-разведочное бурение). 
          В СРП могут быть включены различные дополнительные условия, которые отсутствуют в лицензиях, в частности: требования по объему работ и 
          инвестиций, государственное участие, обязательные поставки на внутренний рынок и связанное с этим ценообразование, порядок урегулирования 
          споров и стабилизации правовых и фискальных условий и пр.
          <br />
          <br />
          В момент передачи прав на разработку месторождений государство присваивает экономическую ренту с помощью разового платежа (бонуса, роялти) 
          за право разработки месторождения. Бонус могут взиматься при различных этапах реализации проекта, как при начале добычи, так и в момент 
          достижения определенного уровня добычи. Роялти рассчитывается как фиксированный процент от выручки (валового дохода) компании-Инвестора. 
          Этот платеж обеспечивает ранний и гарантированный доход государству. Величина роялти в Шривиджайе и Парфии составляет 10% стоимости добытых углеводородов.
          <br />
          <br />
          После вычета роялти, добыча распределяется на две составляющие: «компенсационное» и «прибыльное» углеводородное сырье. Оператор возмещает 
          свои затраты за счет согласованного объема продукции. Возмещаемые затраты определяются также, как и для целей налогообложения. Далее исходя 
          из возмещаемых затрат и цен на продукцию рассчитывается количество «компенсационной» добычи, которая покрывает расходы оператора. В Шривиджайе 
          и Парфии «компенсационная нефть» составляет 45%.
          <br />
          <br />
          После вычета «компенсационной» продукции оставшаяся часть представляет собой «прибыльную» добычу. «Прибыльная» часть подлежит разделу 
          между государством и оператором согласно условиям СРП. В Шривиджайе и Парфии применяется раздел продукции, основанный на распределении 
          по скользящей шкале. Скользящая шкала в других странах устанавливается исходя из объема, дебитов, внутренней нормы прибыли и других 
          факторов. В свою очередь в Шривиджайе и Парфии применяется так называемый R-фактор (отношение совокупных доходов к совокупным затратам), 
          таким образом доля оператора составляет 20% + корректирующий объем, зависящий от R-фактора.
          <br />
          <br />
          После вычета «компенсационной» продукции и раздела «прибыльной» добычи, оператор облагается стандартным налогом на доходы юридических 
          лиц или повышенным в соответствии с решением государства. В Шривиджайе и Парфии применяется стандартный налог на доходы и установлен в размере 20%.
          <br />
          <br />
          Кроме того, оператор должен ежегодно выплачивать различные платежи и бонусы. Это могут быть арендная плата, бонусы за открытие, 
          добычу, подписание и прочие. Такие платежи в Шривиджайе и Парфии могут составлять 15-20%. При этом, в общем случае, импортные пошлины 
          на ввезенное оператором оборудование не взимаются. Однако все ввезенное оборудование переходит в собственность государства, как 
          только пересекает границу. В Шривиджайе и Парфии ввезенное оборудование остается в собственности оператора инвестора.
          <br />
          <br />
          Ввиду того, что раздел продукции осуществляется в натуральной форме, необходимо пересчитывать возмещаемые затраты в соответствующее 
          количество нефти. Важным вопросом в таком случае становится определение рыночной цены углеводородного сырья. В общем случае рыночные 
          цены трактуются по-разному для СРП в разных странах (это может быть специальная «стандартная» цена, установленная гос. органом или цена, 
          зафиксированная национальной нефтяной или газовой компанией и т.д.). Для Шривиджайи и Парфии такими ценами являются мировые цены на 
          нефть и газ, деноминированные в долларах.
          <br />
          <br />
          В Парфии и Шривиджайе действует следующая схема соглашения о разделе продукции:
          <div className={s.contentScheme}>
            <img src={parthiaAndSrivijayaSchemeImg} alt={'Parthia and Srivijaya scheme'} />
          </div>
          Как видно из схемы, инвестор (недропользователь) заключает соглашение, по которому берет на себя риск геологоразведки и покрывает издержки 
          только в случае успеха. Затраты, связанные с разведкой и добычей, покрываются до определенного уровня за счет «компенсационной» добычи. 
          Остающаяся или «прибыльная» добыча делится между государством м инвестором согласно условиям соглашения. Полученная доля инвестора при этом 
          облагается налогом, также из нее выплачиваются различные платежи и бонусы (например, платежи при подписании СРП – своего рода аванс на развитие 
          социальной и прочей инфраструктуры и др.), установленные соглашением, поэтому часть средств также отходит государству.
          <br />
          <br />
          Существует множество вариантов условий СРП и устоявшихся моделей, однако в Парфии и Шривиджайе государственные органы согласны 
          обсуждать доли государства/инвестора и влияние отдельных коэффициентов в рамках соглашений о разделе продукции.
        </>
      ),
    },
  ],
};

const Taxes: FC = () => {
  const [activeTaxesId, setActiveTaxesId] = useState<TaxesId>(defaultTaxesId);

  const onChange = useCallback((value: any) => {
    setActiveTaxesId(value);
  }, []);

  const handleBackToModes = () => {
    setActiveTaxesId(defaultTaxesId);
  };

  const {defaultTabs, taxesTabs} = taxes;
  const activeTaxesTab = [...defaultTabs, ...taxesTabs].find((item) => item.id === activeTaxesId)!;

  return (
    <div className={s.block}>
      <div className={s.tabsWrap}>
        <div>
          <VerticalTabs tabs={taxesTabs} value={activeTaxesId} onChange={onChange} selected={activeTaxesId}/>
        </div>

        <div className={s.buttons}>
          <Button className={s.backButton} onClick={handleBackToModes}>
            Вернуться к налоговым режимам
          </Button>
        </div>
      </div>

      <div>
        <div className={s.contentTitleBlock}>
          <div className={s.contentTitle}>{activeTaxesTab.contentTitle}</div>
          {activeTaxesTab.contentSubtitle ? <div className={s.contentSubtitle}>{activeTaxesTab.contentSubtitle}</div> : null}
        </div>

        <div className={s.content}>{activeTaxesTab.content}</div>
      </div>
    </div>
  );
};

export default Taxes;
