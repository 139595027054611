import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
const MAIN_STORE_PATH = 'news';

const MainSlice = createSlice({
    name: MAIN_STORE_PATH,
    initialState: {
        error: null as null | string,
        status: APP_STATE.IDLE
    },
    reducers: {
        fetchStart (store, action) {
            return {...store, status: APP_STATE.LOADING}
        },
        fetchSucces (store, action) {
            return {...store, data: action.payload, status: APP_STATE.IDLE, error: null}
        },
        fetchError (store) {
            return {...store, status: APP_STATE.IDLE, error: 'Ошибка сервера'}
        },

        getCommonRateStart (store) {
            return {...store, status: APP_STATE.LOADING}
        },
        getCommonRateSuccess (store, action) {
            return {...store, common_rate: action.payload, status: APP_STATE.IDLE, error: null}
        },
        getCommonRateError (store) {
            return {...store, status: APP_STATE.IDLE, error: 'Ошибка сервера'}
        },

        getRegionRateStart (store, action) {
            return {...store, status: APP_STATE.LOADING}
        },
        getRegionRateSuccess (store, action) {
            return {...store, region_rate: action.payload[0], status: APP_STATE.IDLE, error: null}
        },
        getRegionRateError (store) {
            return {...store, status: APP_STATE.IDLE, error: 'Ошибка сервера'}
        },

    }
})

export const {  fetchStart, fetchSucces, fetchError, 
                getCommonRateStart, getCommonRateSuccess, getCommonRateError,
                getRegionRateStart, getRegionRateSuccess, getRegionRateError
            } = MainSlice.actions;
export default MainSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${MAIN_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${MAIN_STORE_PATH}.data`) || [];
}

export function commonRateSelector(state: any) {
    return get(state, `${MAIN_STORE_PATH}.common_rate`) || [];
}

export function regionRateSelector(state: any) {
    return get(state, `${MAIN_STORE_PATH}.region_rate`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${MAIN_STORE_PATH}.error`);
}