import React, { ReactElement } from "react";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import UsersTable from "./components/UsersTable";
import './components/users.css'

export default function View(props: any): ReactElement {
  const {
    isLoading,
    data,
    error
  } = props;

  return (
    <>
      <UsersTable data={data}/>
      <Loader isLoading={isLoading} />
      <Notification success={false} error={error} />
    </>
  );
}
