import React, { ReactElement, useState, useMemo, useEffect } from "react";
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, isEmpty } from 'lodash';
import { Table, Tabs, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { actionTypes } from "../../../constants";
import { teamsSelector, userSelector, getUsersStart } from "../../../store/appSlice";
import { sendLicenseOrderStart, sendedSuccessSelector, closeModalSlice } from "../redux/LicensingSlice";

export default function LicensingActionPanel ({selectedRowKeys, tableData}: any): ReactElement {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");


  //данные для единичной заявки
  const [singlePrice, setSinglePrice] = useState<number | string>('');

  //данные для командной заявки
  const [fullPrice, setFullPrice] = useState<number | string>('');
  const [percentValue, setPercentValue] = useState<number | string>(1);
  const [myPercentValue, setMyPercentValue] = useState<number | string>(99);
  const [selectedComandId, setSelectedComandId] = useState(-1)
  const [comandsTableData, setComandsTableData] = useState<any[]>([]);
  const [startGRR, setStartGRR] = useState(false);

  useEffect(() => {
    if (showModal) {
      setSinglePrice(selectedRow.spl)
      setFullPrice(selectedRow.spl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])


  const teams = useSelector(teamsSelector);
  const sendedSuccess = useSelector(sendedSuccessSelector);

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
  }, [dispatch, teams])

  useEffect(()=> {
    if (sendedSuccess) {
      handleCancel();
      dispatch(closeModalSlice());
    }
  }, [sendedSuccess])

  const user = useSelector(userSelector);
  const myUserId = user?.id || 0;
  const assetId = isEmpty(selectedRowKeys) ? null : selectedRowKeys[0]
  const selectedRow = tableData.find((el: any) => el.id === selectedRowKeys[0])

  const handleCancel = () => {
    setSinglePrice('');
    setPercentValue(1);
    setMyPercentValue(99);
    setSelectedComandId(-1);
    setComandsTableData([]);
    setStartGRR(false);
    setShowModal(false);
  };

  const maxPercent = useMemo(()=> {
    if (isEmpty(comandsTableData)) return 100 - +myPercentValue;
    let max = 100 - +myPercentValue;
    comandsTableData.forEach(el => max = max - el.percent);
    return max;
  }, [comandsTableData, myPercentValue])

  const requestData = useMemo(()=> {
    const items = comandsTableData.map(el => ({userId: el.userId, percent: el.percent, price: el.price}))
    const myItem = {
      userId: myUserId,
      percent: activeTab === "1" ? 100 : myPercentValue,
      price: activeTab === "1" ? +singlePrice : fullPrice !== '' ? +((+fullPrice*+myPercentValue/100).toFixed(2)) : 0
    }
    items.unshift(myItem)
    return {
      assetId,
      actionType: actionTypes.LicenseOrder,
      items,
      startGrrNow: startGRR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comandsTableData, percentValue, singlePrice, fullPrice, myPercentValue, startGRR])

  const usedComands = useMemo(()=> {
    return [myUserId, ...comandsTableData.map(el => el.userId)]
  }, [comandsTableData, myUserId])

  const sendLicenseOrderHandler = () => {
    dispatch(sendLicenseOrderStart(requestData));
    //handleCancel();
  }

  const singlePriceHandler = (e: React.BaseSyntheticEvent) => {
    setSinglePrice(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''));
  }

  const fullPriceHandler = (e: React.BaseSyntheticEvent) => {
    setFullPrice(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''));
    const newData = cloneDeep(comandsTableData);
    newData.forEach((el: any) => el.price = +((+e.target.value*el.percent/100).toFixed(2)));
    setComandsTableData(newData);
  }

  /*const fullPriceValidator = () => {
    const newVal = fullPrice < selectedRow?.spl ? selectedRow.spl : fullPrice
    setFullPrice(newVal);
  }*/

  const changeComandSelectHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedComandId(+e.target.value)
  }

  const myPercentHandler = (e: React.BaseSyntheticEvent) => {
    setMyPercentValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const myPercentValidator = () => {
    let newValue = myPercentValue;
    if (myPercentValue < 1) newValue = 1;
    if (myPercentValue > 100) newValue = 100;
    setMyPercentValue(newValue);
  }

  const onPercentChange = (e: React.BaseSyntheticEvent) => {
    setPercentValue(e.target.value === '' ? '' : +e.target.value.replace(/[^\d]+/g,''))
  }

  const percentValidator = () => {
    let newValue = percentValue;
    if (percentValue < 1) newValue = 1;
    if (percentValue > maxPercent) newValue = maxPercent;
    setPercentValue(newValue);
  }

  const addComandHandler = () => {
    const newItem = {
      id: isEmpty(comandsTableData) ? 0 : comandsTableData[comandsTableData.length - 1].id +1,
      userId: selectedComandId,
      name: teams.find((el: any) => el.id === selectedComandId)!.teamName,
      percent: percentValue,
      price: fullPrice !== '' ? +((+fullPrice*+percentValue/100).toFixed(2)) : 0,
    }
    setSelectedComandId(-1);
    setPercentValue(maxPercent - +percentValue);
    setComandsTableData([...comandsTableData, newItem])
  }

  const checkboxHandler = (e: CheckboxChangeEvent) => {
      setStartGRR(e.target.checked);
  }

  const removehandler = (row: any) => {
    const newComands = comandsTableData.filter(el => el.id !== row.id);
    setComandsTableData(newComands);
    
  }

  const LICENSING_MODAL_FIELDS_CONFIG = [
    {
      dataIndex: 'id',
      title: 'id',
      className: 'hiddenCol'
    },
    {
      dataIndex: 'name',
      title: 'Команда'
    },
    { 
      dataIndex: 'percent',
      title: 'Доля участия, %'
    },
    {
      dataIndex: 'price',
      title: 'Цена заявки, млн.$'
    },
    {
      dataIndex: 'action',
      title: '',
      render: (_: any, row: any) => (
        <Button variant="info" onClick={() => removehandler(row)}>
          Удалить
        </Button>)},
    
  ];

  return (
      <div className="licensing--actionButtons">
        <p>Выберите действие:</p>
        <Button variant="success" disabled={!assetId} onClick={()=>setShowModal(true)}>
          Подать заявку
        </Button>

        <Modal show={showModal} onHide={handleCancel} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Параметры заявки</Modal.Title>
        </Modal.Header>
        <Modal.Body>



        <Tabs defaultActiveKey={activeTab} onChange={key=>{setActiveTab(key)}} >

          <Tabs.TabPane tab="Индивидуальная заявка" key="1">
            <Row className="licensing--actionModal--title">
              <Col xs={6}>
                <p>Название актива</p>
                <Form.Control value={selectedRow?.name || ''} disabled={true} />
              </Col>
              <Col xs={6}>
                <p>Стартовый платеж, млн.$</p>
                <Form.Control value={selectedRow?.spl || ''} disabled={true}/>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p>Цена заявки, млн.$</p>
                <Form.Control
                  onChange={singlePriceHandler}
                  //onBlur={singlePriceValidator}
                  value={singlePrice}
                  type="number"
                  min={selectedRow?.spl || 0}
                  isInvalid={singlePrice < selectedRow?.spl}
                />
              </Col>
              <Col xs={6}>
                <Checkbox onChange={checkboxHandler} checked={startGRR}>
                  Начать ГРР сразу
                </Checkbox>
              </Col>
            </Row>
          </Tabs.TabPane>


          <Tabs.TabPane tab="Коллективная заявка" key="2">
          <Row className="licensing--actionModal--title">
            <Col xs={4}>
              <p>Название актива</p>
              <Form.Control value={selectedRow?.name || ''} disabled={true} />
            </Col>
            <Col xs={4}>
              <p>Общая стоимость, млн.$</p>
              <Form.Control 
                  onChange={fullPriceHandler}
                  //onBlur={fullPriceValidator}
                  type="number"
                  value={fullPrice}
                  isInvalid={fullPrice < selectedRow?.spl} />
            </Col>
            <Col xs={4}>
              <p>Стартовый платеж, млн.$</p>
              <Form.Control value={selectedRow?.spl || ''} disabled={true}/>
            </Col>
          </Row>
          <Row className="licensing--actionModal--myTeam">
            <Col xs={3}>
              <p className="licensing--actionModal--myTeam__title">Моя команда</p>
            </Col>
            <Col xs={3}>
              <p>Доля участия, %</p>
              <Form.Control 
                  onChange={myPercentHandler}
                  onBlur={myPercentValidator}
                  min={1}
                  max={100}
                  value={myPercentValue}
                  type="number"/>
            </Col>
            <Col xs={3}>
              <p>Цена заявки, млн.$</p>
              <Form.Control 
                  onChange={()=>{}}
                  disabled={true}
                  value={fullPrice !== '' ? +((+fullPrice*+myPercentValue/100).toFixed(2)) : 0}/>
            </Col>
            <Col xs={3}>
                <Checkbox onChange={checkboxHandler} checked={startGRR}>
                  Начать ГРР сразу
                </Checkbox>
              </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <p>Команда</p>
              <Form.Select value={selectedComandId} onChange={changeComandSelectHandler}>
                  <option value={-1} key={-1}>Выберите</option>
                  {teams.map((el: any) => (<option value={el.id} key={el.id} disabled={usedComands.includes(el.id)}>{el.teamName}</option>))}
              </Form.Select>
            </Col>
            <Col xs={3}>
              <p>Доля участия, %</p>
              <Form.Control 
                  onChange={onPercentChange}
                  onBlur={percentValidator}
                  min={1}
                  max={maxPercent}
                  value={percentValue}
                  type="number"/>
            </Col>
            <Col xs={3}>
              <p>Цена заявки, млн.$</p>
              <Form.Control 
                  onChange={()=>{}}
                  disabled={true}
                  value={fullPrice !== '' ? +((+fullPrice*+percentValue/100).toFixed(2)) : 0}/>
            </Col>
            <Col xs={3}>
              <Button variant="success" onClick={addComandHandler} disabled={!percentValue || selectedComandId < 0} className="licensing--modal__addButton">
                Добавить команду
              </Button>
            </Col>
          </Row>
          <Row>
          <Table 
            dataSource={comandsTableData} 
            columns={LICENSING_MODAL_FIELDS_CONFIG} 
            rowKey='id'
            className='licensingModalTable'
            pagination={false}
          />
          </Row>
          </Tabs.TabPane>
        </Tabs>



          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={sendLicenseOrderHandler} disabled={fullPrice < selectedRow?.spl || singlePrice < selectedRow?.spl || (activeTab=== '2' && usedComands.length < 2)}>
            Подать заявку
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
  );
}