import React from "react";
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IData } from "./Technologies.interfaces";
export const TECHNOLOGIES_STORE_PATH: string = "technologies";

const types = ['', 'Улучшение', 'Диверсификация'];
const invests = ['', 'Индивидуальная', 'Совместная'];

export function ACTIVED_FIELDS_CONFIG({teams, user}: any): ColumnsType<IData> { 
    return ([
    {
      dataIndex: "id",
      title: "ID",
      className: 'hiddenCol'
    },
    {
      dataIndex: "name",
      title: "Название",
      className: 'centeredCol'
    },
    {
      dataIndex: "type",
      title: "Тип",
      className: 'centeredCol',
      render: (_: any, row: any) => (<p>{types[+row.type]}</p>)
    },
    {
      dataIndex: "invest",
      title: "Тип вложений",
      className: 'centeredCol',
      render: (_: any, row: any) => (<p>{invests[+row.invest]}</p>)
    },
    {
      dataIndex: "oper",
      title: "Оператор",
      align: "center",
      render: (_: any, row: any) => (
        +row.ownerId === user.teamId ? 
        <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p> 
        :
        <div className="d-flex">
          <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p>
          <Tooltip placement="right" title='Принимать решения по данному активу может только его оператор' >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        )
    },
    /*{
        dataIndex: "techstatus",
        title: "Статус",
        className: 'centeredCol'
      },*/
      {
        dataIndex: "invcur",
        title: "Проинвестировано к текущему раунду",
        className: 'centeredCol th--mlnd',
      },
      {
        dataIndex: "as",
        title: "Доля участия",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "invpl",
        title: "Запланированно инвестиций",
        className: 'centeredCol th--mlnd'
      },
      {
        dataIndex: "teaopcur",
        title: "Эффект на добычу",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "tecapexcur",
        title: "Эффект на CAPEX",
        className: 'centeredCol th--mlnd'
      },
      {
        dataIndex: "teopexcur",
        title: "Эффект на OPEX",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "teapacur",
        title: "Эффект на налог СО2",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "tefcfcur",
        title: "Эффект на FCF",
        className: 'centeredCol th--mlnd'
      },
      /*{
        dataIndex: "invtot",
        title: "Проинвестировано всего, млн. $",
        className: 'centeredCol'
      },
      {
        dataIndex: "techry",
        title: "Год реализации",
        className: 'centeredCol'
      },*/
  ])};

  

  export function IMPLEMENTED_FIELDS_CONFIG({teams, user}: any): ColumnsType<IData> { 
    return ([
    {
      dataIndex: "id",
      title: "ID",
      className: 'hiddenCol'
    },
    {
      dataIndex: "name",
      title: "Название",
      className: 'centeredCol'
    },
    {
      dataIndex: "type",
      title: "Тип",
      className: 'centeredCol',
      render: (_: any, row: any) => (<p>{types[+row.type]}</p>)
    },
    {
      dataIndex: "invest",
      title: "Тип вложений",
      className: 'centeredCol',
      render: (_: any, row: any) => (<p>{invests[+row.invest]}</p>)
    },
    {
      dataIndex: "oper",
      title: "Оператор",
      align: "center",
      render: (_: any, row: any) => (
        +row.ownerId === user.teamId ? 
        <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p> 
        :
        <div className="d-flex">
          <p>{teams.find((el: any) => +el.teamId === +row.ownerId)?.teamName || ''}</p>
          <Tooltip placement="right" title='Принимать решения по данному активу может только его оператор' >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        )
    },
    /*{
        dataIndex: "techstatus",
        title: "Статус",
        className: 'centeredCol'
      },
      {
        dataIndex: "invcur",
        title: "Проинвестировано к текущему раунду, млн. $",
        className: 'centeredCol'
      },
      {
        dataIndex: "invpl",
        title: "Запланированно инвестиций, млн. $",
        className: 'centeredCol'
      },*/
      {
        dataIndex: "teaop",
        title: "Эффект на добычу",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "tecapex",
        title: "Эффект на CAPEX",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "teopex",
        title: "Эффект на OPEX",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "teapa",
        title: "Эффект на штрафы",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "tefcf",
        title: "Эффект на FCF",
        className: 'centeredCol th--mlnd'
      },
      {
        dataIndex: "invtot",
        title: "Проинвестировано всего",
        className: 'centeredCol th--mlnd'
      },
      {
        dataIndex: "as",
        title: "Доля участия",
        className: 'centeredCol th--percent'
      },
      {
        dataIndex: "techry",
        title: "Год реализации",
        className: 'centeredCol'
      },
  ])};