import React, { useState, useEffect, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newsImages } from '../../constants';
import newsIcon1 from "../../assets/img/news_icon_1.jpg";
import newsIcon2 from "../../assets/img/news_icon_2.jpg";
import { Image, Row, Col, Button } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useHistory } from "react-router";
import "./Main.css";
import CountryPanel from "../../components/CountryPanel/CountryPanel";
import { fetchStart, dataSelector, getCommonRateStart, commonRateSelector, getRegionRateStart, regionRateSelector } from "./redux/MainSlice";

export default function Main(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const data = useSelector(dataSelector);
  const commonRate = useSelector(commonRateSelector);
  const regionRate = useSelector(regionRateSelector);
  const [regionId, setRegionId] = useState(0);

  useEffect(()=> {
    dispatch(fetchStart({regionId}))
    dispatch(getCommonRateStart());
    if (regionId !== 0) dispatch(getRegionRateStart({regionId}));
  }, [dispatch, regionId])
  
  const mainNews = data.filter((el: any) => el.newsType === 1);
  const wordNews = data.filter((el: any) => el.newsType === 2);
  const techNews = data.filter((el: any) => el.newsType === 3);
  const imageId = mainNews.length > 0 ? +mainNews[0].image : null;
  const mainImage = imageId ? newsImages.find((el: any) => el.id === imageId)?.src : null;

  const goToHistoryHandler = () => {
    history.push("/news_history");
  }


  return (
    <Row className="mainPage--Wrapper">
      <Row style={{ padding: '0'}}>
        <Col>
          <CountryPanel setRegionId={setRegionId}/>
        </Col>
        <Col style={{ padding: '0'}}>
          <Button variant="primary" style={{backgroundColor: '#5F8AB6', border: 'none'}} onClick={goToHistoryHandler} >
            Архив новостей
          </Button>
        </Col>
      </Row>
      <Row className="mainPage--block mainPage--mainNews">
        <Col xs={9} className="mainPage--mainNewsBlock">
        {mainImage && <Image src={mainImage} thumbnail className="mainPage--image"/>}
        {!isEmpty(mainNews) ? mainNews.map((el: any) => (
            <div key={el.id}>
              <div className="d-flex">
                <img src={newsIcon2} className="mainPage--icon" alt=""/>
                <h5>{el.title}</h5>
              </div>
              <p>{el.body}</p>
            </div>
          )) : <p className="mainPage--emptyNews">Новостей нет</p>}
      </Col>
      <Col xs={3}>
        {/*<Image src={news2} thumbnail className="mainPage--image"/>*/}
        {regionId === 0 && 
            <table className="news-table">
	            <thead>
		            <tr>
			            <th className="left-th">Название</th>
                  <th></th>
			            <th className="text-right">Цена</th>
		            </tr>
	            </thead>
	            <tbody>
                <tr>
			            <td className="bold-td left-td">Нефть Brent</td>
                  <td className="small-td">$/bbl</td>
			            <td className="text-right">{commonRate?.oilBrent}</td>
		            </tr>
                <tr>
			            <td className="bold-td left-td">Нефть Urals</td>
                  <td className="small-td">$/bbl</td>
			            <td className="text-right">{commonRate?.oilUrals}</td>
		            </tr>
                <tr>
			            <td className="bold-td  left-td">KUN</td>
                  <td className="small-td">$</td>
			            <td className="text-right">{commonRate?.kun}</td>
		            </tr>
                <tr>
			            <td className="bold-td  left-td">VAR</td>
                  <td className="small-td">$</td>
			            <td className="text-right">{commonRate?.var}</td>
		            </tr>
                <tr>
			            <td className="bold-td  left-td">MNA</td>
                  <td className="small-td">$</td>
			            <td className="text-right">{commonRate?.mna}</td>
		            </tr>
                <tr>
			            <td className="bold-td  left-td">DNA</td>
                  <td className="small-td">$</td>
			            <td className="text-right">{commonRate?.dna}</td>
		            </tr>
                <tr >
			            <td className="bold-td  left-td lower-td">DHM</td>
                  <td className="small-td lower-td">$</td>
			            <td className="text-right lower-td">{commonRate?.dhm}</td>
		            </tr>
	            </tbody>
            </table>
        }
        {regionId !== 0 && 
          <table className="news-table">
          <thead>
            <tr >
              <th className="left-th">Название</th>
              <th></th>
              <th className="text-right">Цена</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {regionId === 1 &&<td className="bold-td left-td">DNA</td>}
              {regionId === 2 &&<td className="bold-td left-td">KUN</td>}
              {regionId === 3 &&<td className="bold-td left-td">DHM</td>}
              {regionId === 4 &&<td className="bold-td left-td">VAR</td>}
              {regionId === 5 &&<td className="bold-td left-td">MNA</td>}
              <td className="small-td">$</td>
              <td className="text-right">{regionRate?.currencyRate}</td>
            </tr>
            <tr>
			            <td className="bold-td left-td">Инфляция</td>
                  <td className="small-td">%</td>
			            <td className="text-right">{regionRate?.inflation}</td>
		            </tr>
            <tr>
              <td className="bold-td left-td">ГАЗ</td>
              <td className="small-td">$/тнэ.</td>
              <td className="text-right">{regionRate?.gasPrice || '0'}</td>
            </tr>
            <tr>
              <td className="bold-td left-td">ШТРАФ CO2</td>
              <td className="small-td">$/тнэ.</td>
              <td className="text-right">{regionRate?.cO2Fines}</td>
            </tr>
            <tr>
              <td className="bold-td left-td">ЭНЕРГОБАЛАНС</td>
            </tr>
            <tr>
              <td className="left-td">ГАЗ</td>
              <td className="small-td">%</td>
              <td className="text-right">{regionRate?.gas || '0'}</td>
            </tr>
            <tr>
              <td className="left-td">НЕФТЬ</td>
              <td className="small-td">%</td>
              <td className="text-right">{regionRate?.oil || '0'}</td>
            </tr>
            <tr>
              <td className="left-td">АТОМ</td>
              <td className="small-td">%</td>
              <td className="text-right">{regionRate?.atom || '0'}</td>
            </tr>
            <tr>
              <td className="left-td">ВИЭ</td>
              <td className="small-td">%</td>
              <td className="text-right">{regionRate?.vie || '0'}</td>
            </tr>
            <tr>
              <td className="left-td">ПРОЧИЕ</td>
              <td className="small-td">%</td>
              <td className="text-right">{regionRate?.other || '0'}</td>
            </tr>
            <tr>
              <td className="bold-td left-td">ДОБЫЧА</td>
            </tr>
            <tr>
              <td className="left-td">НЕФТЬ</td>
              <td className="small-td">млн. тнэ.</td>
              <td className="text-right">{regionRate?.oilProduction || '0'}</td>
            </tr>
            <tr>
              <td className="lower-td left-td">ГАЗ</td>
              <td className="small-td lower-td">млн. тнэ.</td>
              <td className="text-right lower-td">{regionRate?.gasProduction || '0'}</td>
            </tr>
          </tbody>
        </table>
          }
      </Col>
    </Row>
    <Row className="mainPage--block mainPage--news">
      <Col sm={8}>
        <div className="d-flex mainPage--item__header">
          <img src={newsIcon1} className="mainPage--icon2" alt=""/>
          <h3>Мировые новости</h3>
        </div>
        {!isEmpty(wordNews) ? wordNews.map((el: any) => (
          <div className="mainPage--item" key={el.id}>
            <div className="d-flex">
              <img src={newsIcon2} className="mainPage--icon" alt=""/>
              <h5>{el.title}</h5>
            </div>
            <p>{el.body}</p>  
          </div>
        )) : <p className="mainPage--emptyNews">Новостей нет</p> }
      </Col>
      <Col sm={4}>
        <div className="d-flex mainPage--item__header">
          <img src={newsIcon1} className="mainPage--icon2" alt=""/>
          <h3>Новости компании</h3>
        </div>
        {!isEmpty(techNews) ? techNews.map((el: any) => (
          <div className="mainPage--item" key={el.id}>
            <div className="d-flex">
              <img src={newsIcon2} className="mainPage--icon" alt=""/>
              <h5>{el.title}</h5>
            </div>
            <p>{el.body}</p>  
          </div>
        )) : <p className="mainPage--emptyNews">Новостей нет</p>}
      </Col>
      </Row>
    </Row>
  );
}
