import {FC} from 'react';
import s from './index.module.css';

const World: FC = () => {
  return (
    <div className={s.block}>
      <div className={s.mainTitle}>Информация о мире</div>

      <div className={s.group}>
        <div className={s.title}>Цель</div>
        <div className={s.content}>
          Основная цель, стоящая перед вашей командой, – стать компанией номер один по рыночной капитализации в сравнении с другими игровыми
          компаниями. Для достижения данной цели вы сможете использовать широкий набор различных действий.
        </div>
      </div>

      <div className={s.group}>
        <div className={s.title}>Игровой мир</div>
        <div className={s.content}>
        Действие происходит в восстановленной историками будущего реальности, где наравне с известными странами, историей, организациями, 
          валютами и прочими атрибутами существуют другие государства, территории, компании и прочие объекты мира. Так, например, расчеты в 
          мире симуляции происходят в долларовом эквиваленте, при этом у каждой страны есть свои валюты: <br/>
          •	куна в Гардарике<br/>
          •	денарий в Валгалле<br/>
          •	драхма в Парфии<br/>
          •	мина в Ассирии<br/>
          •	вара в Шривиджайе<br/>
          В мире будут происходить разнообразные события, которые, возможно, будут оказывать влияние на деятельность вашей компании.
          Разразившийся кризис финансовой системы, забастовки рабочих и выступления «зеленых», техногенные и природные катаклизмы – это то,
          к чему вы должны быть готовы в ходе управления компанией. Следите за новостями! <br /> 
          Умелые действия вашей команды позволят пройти сложные периоды с
          минимальными потерями, а, быть может, и использовать открывшиеся возможности. Для этого вам доступен широкий арсенал
          управленческих решений, влияние которых будет отражаться в отчетах вашей компании.
        </div>
      </div>

      <div className={s.group}>
        <div className={s.title}>Действия</div>
        <div className={s.content}>
          В ходе управления компанией вы сможете:
          <br />
          <br />
          <div className={s.businessWrap}>
            <div>
              <div>В части текущего бизнеса:</div>
              <ul>
                <li>Приобретать лицензий и получать доступа к новым активам</li>
                <li>Проводить геологоразведочные работы на новых месторождениях</li>
                <li>Разрабатывать и управлять добычей на текущих активах</li>
                <li>Повышать эффективность текущего бизнеса</li>
                <li>Приобретать новые добычные активы и др.</li>
              </ul>
            </div>
            <div>
              <div>В части развития бизнеса:</div>
              <ul>
                <li>Финансировать НИОКР и внедрять инноваций</li>
                <li>Инвестировать в новые бизнесы (заниматься диверсификацией)</li>
                <li>Приобретать новые технологические активы и др.</li>
              </ul>
            </div>
          </div>
          <br />
          Кроме того, вы будете взаимодействовать с другими участниками: представителями органов власти в разных странах, бизнесменами в
          смежных отраслях, другими энергетическими компаниями, акционерами и советом директоров.
          <br />
          <br />
          Более подробно с персонажами и компаниями в игре Вы можете ознакомиться в разделе «Игровые компании и персонажи».
          <br />
          <br />
          <div>
            <div>В части взаимодействия с другими участниками вы будете:</div>
            <ul>
              <li>Совместно участвовать в лицензионных торгах и управлять активами</li>
              <li>Совместно осуществлять НИОКР и технологическое развитие</li>
              <li>Совместно инвестировать в новые бизнесы</li>
              <li>Вести переговоры с представителями органов власти о налоговых режимах, штрафах и других вопросах</li>
              <li>Взаимодействовать с акционерами, советом директоров по различным вопросам (отчеты, дивиденды и др.)</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={s.group}>
        <div className={s.title}>Компания</div>
        <div className={s.content}>
          Бизнес вашей компании сосредоточен в направлении добычи углеводородного сырья (Upstream), вопросы логистики, переработки и сбыта
          продукции не находятся в сфере вашей непосредственной ответственности. Кроме текущего бизнеса, существуют возможности для
          диверсификации деятельности и развития в области энергетики. <br /> 
          Штаб-квартира Вашей компании находится в Гардарике, вы владеете лицензиями и осуществляете добычу нефти и газа в нескольких регионах мира. 
          Каждая из стран отличается своим экономическим, политическим устройством и условиями добычи углеводородов. 
          Более подробно о странах и регионах присутствия Вы можете ознакомиться на вкладке "Страны".
        </div>
      </div>
    </div>
  );
};

export default World;
