import React, { useState, useEffect } from 'react';
import { isEmpty } from "lodash";
import history from "../../store/history";
import { parse } from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Modal, Image } from "react-bootstrap";
import './addNews.css';
import { errorSelector, addNewsStart, fetchTechnologiesStart, technologiesSelector, fetchNewsStart, dataSelector, clearTechnologies, updateNewsStart, deleteNewsStart } from "./redux/AddNewsSlice";
import { teamsSelector, getUsersStart } from "../../store/appSlice";
import Notification from '../../components/Notification';
import { countries, newsImages } from "../../constants";

const AddNews = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [regionId, setRegionId] = useState(0);
  const [roundId, setRoundId] = useState(0);
  const [newsType, setNewsType] = useState(1);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [selectedComandId, setSelectedComandId] = useState(-1);
  const [techId, setTechId] = useState(-1);
  const [generalNews, setGeneralNews] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageId, setImageId] = useState(null);
  const error = useSelector(errorSelector);
  const [filter, setFilter] = useState<{round: number, team: number, region: number}>({round: 0, team: 0, region: 0});

  const teams = useSelector(teamsSelector);
  const data = useSelector(dataSelector);
  const { type } = parse(window.location.search);
  const requestNewsType = type === '2' ? ['1', '2'] : type;

  useEffect(()=>{
    if (isEmpty(teams)) dispatch(getUsersStart());
    dispatch(fetchNewsStart({...filter, newsType: requestNewsType}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, teams]);

  

  useEffect(()=>{
    if (type && '' + newsType !== type) {
      setNewsType(+type);
      dispatch(fetchNewsStart({round: 0, team: 0, region: 0, newsType: requestNewsType}));
      dispatch(clearTechnologies());
      clearFilter();
    }
    if (!type) history.replace('/addNews?type=2')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const mainImage = imageId ? newsImages.find((el: any) => el.id === +imageId)?.src : null;

  const clearFilter = () => {
    setFilter({round: 0, team: 0, region: 0})
  }

  const editRow = (row: any) => {
    return function () {
      dispatch(clearTechnologies());
      setRoundId(row.roundNumber || 0);
      setRegionId(row.regionId || 0);
      setTitle(row.title || '');
      setBody(row.body || '');
      setSelectedComandId(row.userId || -1);
      setTechId(row.techId || -1);
      setGeneralNews(row.newsType === 1 ? true : false);
      setImageId(row.image || null);
      setSelectedNewsId(row.id || null);
      setShowModal(true);
    }
  }

  const closeModal = () => {
    dispatch(clearTechnologies());
    setRoundId(0);
    setRegionId(0);
    setTitle('');
    setBody('');
    setSelectedComandId(-1);
    setTechId(-1);
    setGeneralNews(false);
    setImageId(null);
    setSelectedNewsId(0);
    setShowModal(false);
  }

  

  const deleteRow = (id: any) => {
    return function () {
      dispatch(deleteNewsStart({request: {id}, fetchDataParams: { ...filter, newsType: requestNewsType}}));
    }
  }

  const technologies = useSelector(technologiesSelector);
  useEffect(()=>{
    if (newsType === 4 && selectedComandId > 0) dispatch(fetchTechnologiesStart({techUserId: selectedComandId}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsType, selectedComandId]);
  
  const addNewsHandler = () => {
    let request : any = {
      roundNumber: roundId,
      regionId,
      newsType: generalNews ? 1 : newsType,
      title,
      body,
      image: imageId,
      userId: (selectedComandId > 0 && (newsType === 4 || newsType === 3)) ? selectedComandId : null,
      techId: (techId > 0 && newsType === 4) ? techId : null
    };
    if (selectedNewsId) request.id = selectedNewsId;
    closeModal();
    if (!selectedNewsId) dispatch(addNewsStart({request, fetchDataParams: { ...filter, newsType: requestNewsType}}));
    if (selectedNewsId) dispatch(updateNewsStart({request, fetchDataParams: { ...filter, newsType: requestNewsType}}));
    //dispatch(addNewsStart(payload))
  }

  const getNews = () => {
    dispatch(fetchNewsStart({...filter, newsType: requestNewsType}))
  }

  const changeComandSelectHandler = (e: React.BaseSyntheticEvent) => {
    setSelectedComandId(+e.target.value)
  }

  const changeTechSelectHandler = (e: React.BaseSyntheticEvent) => {
    setTechId(+e.target.value)
  }

  const filterHandler = (e: React.BaseSyntheticEvent, name: string) => {
    const newFilter = {...filter};
    if (name === 'round') newFilter.round = +e.target.value.replace(/[^0-9.]/g, '');
    if (name === 'team') newFilter.team = +e.target.value;
    if (name === 'region') newFilter.region = +e.target.value;
    setFilter(newFilter)
  }

  const changeImage = (el: any) => {
    setImageId(el.id);
    setShowImageModal(false);
  }

  return (
    <div className='addNews'>
      <Row>
        <Col xs={2}>
          <Button variant="secondary" onClick={() => setShowModal(true)}>
              Создать новость
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <h5>Фильтры</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <p>Номер раунда</p>
          <Form.Control value={filter.round || ''} onChange={(e) => filterHandler(e, 'round')} />
        </Col>
        {type !== '2' && <Col xs={2}>
          <p>Команда</p>
          <Form.Select value={filter.team || ''} onChange={(e) => filterHandler(e, 'team')}>
            <option value={'0'} key={'0'}>Выберите</option>
            {teams.map((el: any) => (<option value={el.id} key={el.id} >{el.teamName}</option>))}
          </Form.Select>
        </Col>}
        <Col xs={2}>
          <p>Регион</p>
          <Form.Select value={filter.region} onChange={(e) => filterHandler(e, 'region')}>
            {countries.map((el: any) => (<option value={el.id} key={el.id} >{el.name}</option>))}
          </Form.Select>
        </Col>
        <Col xs={2}>
          <Button variant="secondary" onClick={getNews} className="addNews-getNewsButton">
              Применить
          </Button>
        </Col>
      </Row>
      {data && !isEmpty(data) &&
        data.map((el: any) => (
          <Row>
            <Col xs={10}>
              <div className="addNews--newsViewElement">
                {el.newsType === 1 && <p className='newsHistory--roundNumber'>Главная новость</p>}
                <h5>{el.title}</h5>
                {el.image && <Image src={newsImages.find((unit: any) => unit.id === +el.image)?.src} thumbnail className="addNews--ImageBlock" />}
                <p>{el.body}</p>
                {el.image && <p style={{clear: 'right'}}>{''}</p>}
              </div>
            </Col>
            <Col xs={2}>
              <Button variant="primary" onClick={editRow(el)} className="addNews--newsViewElement__button">
                  Изменить
              </Button>
              <Button variant="primary" onClick={deleteRow(el.id)} className="addNews--newsViewElement__button">
                  Удалить
              </Button>
            </Col>
        </Row>
        ))
        }


      <Modal show={showModal} onHide={closeModal} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Новости</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='addNews--form'>
          <Row>
            <Col xs={2}>
              <p>Заголовок новости</p>
            </Col>
            <Col xs={10}>
              <Form.Control type="text" as="textarea" rows={2} value={title} onChange={(e)=>setTitle(e.target.value)}/>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>
              <p>Текст новости</p>
            </Col>
            <Col xs={10}>
              <Form.Control type="text" as="textarea" rows={8} value={body} onChange={(e)=>setBody(e.target.value)}/>
            </Col>
          </Row>
          { (newsType === 1 || newsType === 2) &&
          <Row>
            <Col xs={2}>
              <p>Главная новость</p>
            </Col>
            <Col xs={10}>
              <Form.Check checked={generalNews} onChange={(e)=>setGeneralNews(!generalNews)} className='addNews--generalNews__checkbox' />
            </Col>
          </Row>}
          { (newsType === 1 || newsType === 2) &&
          <Row>
            <Col xs={2}>
              <p>Изображение</p>
            </Col>
            <Col xs={10}>
              {mainImage && <Image src={mainImage} thumbnail className="addNews--genegalImage" onClick={()=>setShowImageModal(true)} />}
              {!mainImage && <Button variant="primary" onClick={()=>setShowImageModal(true)}>
                Добавить
              </Button>}
            </Col>
          </Row>}
          <Row>
            <Col xs={2}>
              <p>Регион</p>
            </Col>
            <Col xs={10}>
              <Form.Select value={regionId} onChange={(e: React.BaseSyntheticEvent) => setRegionId(e.target.value)}>
                {countries.map((el: any) => (<option value={el.id} key={el.id} >{el.name}</option>))}
              </Form.Select>
            </Col>
          </Row>
          {/*<Row>
            <Col xs={2}>
              <p>Тип новости</p>
            </Col>
            <Col xs={10}>
              <Radio.Group onChange={newsTypeHandler} defaultValue={0} className='addNews--newsTypeGroup'>
                  {newsTypes.map(el => (
                      <Radio.Button key={el.id} value={el.id} className='addNews--newsTypeElement'>
                      {el.name}
                      </Radio.Button>
                  ))}
              </Radio.Group>
            </Col>
          </Row>*/}
          <Row>
            <Col xs={2}>
              <p>Раунд</p>
            </Col>
            <Col xs={10}>
              <Form.Control type="number" value={roundId || ''} onChange={(e)=>setRoundId(+e.target.value.replace(/[^0-9.]/g, ''))}/>
            </Col>
          </Row>
          { (newsType === 4 || newsType === 3) &&
          <Row>
            <Col xs={2}>
              <p>Команда</p>
            </Col>
            <Col xs={10}>
              <Form.Select value={selectedComandId} onChange={changeComandSelectHandler}>
                  <option value={-1} key={-1}>Выберите</option>
                  {teams.map((el: any) => (<option value={el.id} key={el.id}>{el.teamName}</option>))}
              </Form.Select>
            </Col>
          </Row>}
          { newsType === 4 &&
          <Row>
            <Col xs={2}>
              <p>Технология</p>
            </Col>
            <Col xs={10}>
              <Form.Select value={techId} onChange={changeTechSelectHandler}>
                  <option value={-1} key={-1}>Выберите</option>
                  {technologies.map((el: any) => (<option value={el.id} key={el.id}>{el.name}</option>))}
              </Form.Select>
            </Col>
          </Row>}
          
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={addNewsHandler} >
            Сохранить
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showImageModal} onHide={()=>setShowImageModal(false)} size='xl' dialogClassName="addNews--ImageModal">
        <Modal.Header closeButton>
          <Modal.Title>Изображение</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {newsImages.map((el: any) => (
              <Image key={el.id} src={el.src} thumbnail className="addNews--modalImages" onClick={()=>changeImage(el)} />
            ))}
        </Modal.Body>
      </Modal>
        
        <Notification success={false} error={error} />
    </div>
  )
};

export default AddNews;