import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import Notification from '../../../components/Notification';
import {    errorSelector, 
            endRoundStart, 
            startRoundStart, 
            postPreparationRoundStart, 
            getPreparationRoundStart, 
            exportRoundStart, 
            importRoundStart, 
            preparationSelector,
            savedSelector,
            fileSendSelector } from "../redux/ManagementSlice";


const RoundTab = () => {
  const dispatch = useDispatch();
  //const [showAlert, setShowAlert] = useState(false);
  const [fileName, setFileName] = useState('');
  const error = useSelector(errorSelector);
  const preparationStatus = useSelector(preparationSelector);
  const savedSuccess = useSelector(savedSelector);
  const fileSend = useSelector(fileSendSelector);
  const statusInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(()=>{
    if (fileSend && !statusInterval.current) {
      setTimeout(()=> 
        statusInterval.current = setInterval(() => dispatch(getPreparationRoundStart({isLast: true})), 1500)  // это интервал запроса
      , 800) // это пауза до первого запроса
    }
    if (!fileSend && statusInterval.current) clearInterval(statusInterval.current)
  }, [fileSend])

  const endRoundHandler = () => {
    dispatch(endRoundStart())
  }

  const startRoundHandler = () => {
    dispatch(startRoundStart())
  }

  const exportRoundHandler = () => {
    dispatch(exportRoundStart({}))
  }

  const sendFileHandler = (e: React.BaseSyntheticEvent) => {
    setFileName(e.target.files[0].name);
    dispatch(postPreparationRoundStart({files: e.target.files, data: {}}));
    e.target.value = '';
  }

  const getFileHandler = () => {
    dispatch(importRoundStart())
  }

  const getPreparationStatus = () => {
    dispatch(getPreparationRoundStart({isLast: true}))
  }
   
  return (
    <div className='management'>
        <div className='management--block'>
            <Row>
              <Col xs={12}>
                <p>1. Загрузите исходный эксель файл обработку (может занять некоторое время)</p>
                <Row>
                  <Col xs={3}>
                    <Form.Label htmlFor="management--block--fileUpload" className="btn btn-outline-warning">
                      Загрузить файл
                    </Form.Label>
                    <Form.Control 
                      id="management--block--fileUpload" 
                      type="file" 
                      accept=".xls*"
                      className="management--block__hidden" 
                      placeholder="Загрузить файл" 
                      onChange={sendFileHandler}
                    />
                  </Col>
                  <Col xs={4}>
                    <p>{fileName}</p>
                  </Col>
                </Row>
                </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p>2. Проверьте статус импорта данных из загруженного файла</p>
              </Col>
              <Col xs="auto">
                  <Button variant="outline-primary" className="management--block__statusUpdate" onClick={getPreparationStatus}>Обновить статус</Button>
              </Col>
              <Col xs="auto">
                  <Form.Control type="text" disabled 
                    value={preparationStatus?.id ? 
                      (preparationStatus?.isActive ? 'Идет обработка...' : 
                        (preparationStatus?.isSuccess ? 'Успешно' : 'Ошибка')) : ''} 
                    onChange={()=>{}}/>
              </Col>
              <Col xs="auto">
                {fileSend && <Spinner animation="border" variant="info" />}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p>Текст ошибки</p>
              </Col>
              <Col xs={12}>
                <Form.Control type="text" as="textarea" rows={8} disabled value={preparationStatus?.error ? preparationStatus?.error.toString() : ''} onChange={()=>{}}/>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p>3. Начните раунд, когда подготовка успешно завершена</p>
                <Button variant="outline-primary" onClick={startRoundHandler}>Начало раунда</Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p>4. Завершите раунд, либо раунд завершится автоматически по тайммеру</p>
                <Button variant="outline-warning" onClick={endRoundHandler}>Конец раунда</Button>
                <p>5. Согласуйте заявки команд</p>
                <p>6. Экспортируйте согласованные данные в эксель файл (может занять некоторое время)</p>
                <Button variant="outline-secondary" onClick={exportRoundHandler}>Экспорт раунда</Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p>7. Проверьте статус экспорта данных в эксель</p>
              </Col>
              <Col xs="auto">
                  <Button variant="outline-primary" className="management--block__statusUpdate" onClick={getPreparationStatus}>Обновить статус</Button>
              </Col>
              <Col xs="auto">
                <Form.Control type="text" disabled 
                    value={preparationStatus?.id ? 
                      (preparationStatus?.isActive ? 'Идет обработка...' : 
                        (preparationStatus?.isSuccess ? 'Успешно' : 'Ошибка')) : ''} 
                    onChange={()=>{}}/>
              </Col>
              <Col xs="auto">
                {fileSend && <Spinner animation="border" variant="info" />}
              </Col>
              <Col xs={12}>
                <p>Текст ошибки</p>
                <Form.Control type="text" as="textarea" rows={8} disabled value={preparationStatus?.error ? preparationStatus?.error.toString() : ''} onChange={()=>{}}/>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p>8. Скачайте результирующий файл</p>
                <Button variant="outline-warning" onClick={getFileHandler}>Выгрузить файл</Button>
              </Col>
            </Row>
        </div>

        <Notification success={savedSuccess} error={error} />
    </div>
  )
};

export default RoundTab;