import { takeLatest, fork, put, call} from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
    fetchGrrTableStart, fetchGrrTableSuccess, fetchGrrTableError, 
    saveGrrItemStart, saveGrrItemSuccess, saveGrrItemError,
 } from './GRRSlice';

function* fetchGrrData (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'AssetGrr',
            payload: action.payload
          });
        yield put(fetchGrrTableSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(fetchGrrTableError(error))
    }
}

function* saveGrrItem (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
          apiMethod: 'GrrActions',
          payload: action.payload
        });
      yield put(saveGrrItemSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(saveGrrItemError(error))
  }
}


function* fetchGrrDataWatcher () {
    yield takeLatest(fetchGrrTableStart.type, fetchGrrData)
}

function* saveGrrItemWatcher () {
  yield takeLatest(saveGrrItemStart.type, saveGrrItem)
}


export const GRRSagas = [fork(fetchGrrDataWatcher), fork(saveGrrItemWatcher)];
//не забываем добавлять сагу в рут- сагу