import { takeLatest, fork, put, call} from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  fetchTechnologiesTableStart, fetchTechnologiesTableSuccess, fetchTechnologiesTableError,
  saveTechnologiesItemStart, saveTechnologiesItemSuccess, saveTechnologiesItemError,
  fetchTechnologiesDescriptionStart, fetchTechnologiesDescriptionSuccess, fetchTechnologiesDescriptionError,
  fetchTechnologiesNewsStart, fetchTechnologiesNewsSuccess, fetchTechnologiesNewsError
 } from './TechnologiesSlice';

function* fetchTechnologiesTableData (action: any) {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
            apiMethod: 'Techs',
            payload: action.payload
          });
        yield put(fetchTechnologiesTableSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(fetchTechnologiesTableError(error))
    }
}

function* fetchTechnologiesDescription (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.getById, {
          apiMethod: 'TechDescriptions',
          payload: action.payload
        });
      yield put(fetchTechnologiesDescriptionSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(fetchTechnologiesDescriptionError(error))
  }
}

function* fetchTechnologiesNews (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'News',
          payload: action.payload
        });
      yield put(fetchTechnologiesNewsSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(fetchTechnologiesNewsError(error))
  }
}

function* saveTechnologiesItem (action: any) {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
          apiMethod: 'TechActions',
          payload: action.payload
        });
      yield put(saveTechnologiesItemSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(saveTechnologiesItemError(error))
  }
}


function* fetchTechnologiesDataWatcher () {
    yield takeLatest(fetchTechnologiesTableStart.type, fetchTechnologiesTableData)
}

function* saveTechnologiesItemWatcher () {
  yield takeLatest(saveTechnologiesItemStart.type, saveTechnologiesItem)
}

function* fetchTechnologiesDescriptionWatcher () {
  yield takeLatest(fetchTechnologiesDescriptionStart.type, fetchTechnologiesDescription)
}

function* fetchTechnologiesNewsWatcher () {
  yield takeLatest(fetchTechnologiesNewsStart.type, fetchTechnologiesNews)
}

export const TechnologiesSagas = [
  fork(fetchTechnologiesDataWatcher), 
  fork(saveTechnologiesItemWatcher), 
  fork(fetchTechnologiesDescriptionWatcher),
  fork(fetchTechnologiesNewsWatcher)
];
//не забываем добавлять сагу в рут- сагу