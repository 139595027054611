import React, { ReactElement } from "react";
import { Row, Col } from 'react-bootstrap';
//import LicenseTypePanel from "../../../components/LicenseTypePanel/LicenseTypePanel";
//import AgreementsHistoryPanel from "./AgreementsHistoryPanel";
import CountrySelect from "../../../components/CountrySelect/CountrySelect";


export default function AgreementsHeader(props: any): ReactElement {

  const { setRegionId, activeTab } = props;

  return (
    <Row className="agreements--header__stageBlock">
    <Col xs={9}>
      <Row className="justify-content-between align-items-center">
        <Col xs={4}>
          <h4>{activeTab === 1 ? 'Выберите решения' : 'Выберите договоренности'}</h4>
        </Col>
        {/*<Col xs={1}>
          <LicenseTypePanel setFieldType={setFieldType} />
        </Col>*/}
        <Col xs={2}>
          {activeTab !== 1 && <CountrySelect setRegionId={setRegionId} />}
        </Col>
        <Col xs={3} className="agreements--header__historyButton">
          {/*<AgreementsHistoryPanel />*/}
        </Col>
      </Row>
    </Col>
    </Row>
  );
}
