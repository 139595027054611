import { takeLatest, fork, put, call } from 'typed-redux-saga';
import { HTTPAsyncAPIMethodWrapper } from '../../../utils';
import { 
  startRoundStart, startRoundSucces, startRoundError, 
  endRoundStart, endRoundSuccess, endRoundError,
  postPreparationRoundStart, postPreparationRoundSuccess, postPreparationRoundError,
  getPreparationRoundStart, getPreparationRoundSuccess, getPreparationRoundError,
  exportRoundStart, exportRoundSuccess, exportRoundError,
  importRoundStart, importRoundSuccess, importRoundError,
  getGamesStart, getGamesSuccess, getGamesError,
  newGameStart, newGameSuccess, newGameError,
  editGameStart, editGameSuccess, editGameError,
 } from './ManagementSlice';
 import { getStatusStart } from '../../../store/appSlice';

function* startRound () {
    try {
          const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
            apiMethod: 'RoundStart',
            payload: {}
          });
        yield put(startRoundSucces(response))
        yield put(getStatusStart())
    } catch (error) {
        console.log(error)
        yield put(startRoundError(error))
    }
}

function* endRound () {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
          apiMethod: 'RoundEnd',
          payload: {}
        });
      yield put(endRoundSuccess(response))
      yield put(getStatusStart())
  } catch (error) {
      console.log(error)
      yield put(endRoundError(error))
  }
}

function* postPreparationRound (action: any) {
    try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.filesend, {
          apiMethod: 'RoundPreparation',
          payload: action.payload
        });
      yield put(postPreparationRoundSuccess(response))
      //yield put(getPreparationRoundStart({isLast: true}))
  } catch (error) {
      console.log(error)
      yield put(postPreparationRoundError(error))
  }
}

function* getPreparationRound (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
        apiMethod: 'RoundPreparation',
        payload: action.payload
      });
    yield put(getPreparationRoundSuccess(response))
} catch (error) {
    console.log(error)
    yield put(getPreparationRoundError(error))
}
}

function* exportRound (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
        apiMethod: 'RoundExport',
        payload: action.payload
      });
    yield put(exportRoundSuccess(response))
} catch (error) {
    console.log(error)
    yield put(exportRoundError(error))
}
}

function* importRound (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.fileget, {
        apiMethod: 'RoundExport',
        payload: action.payload
      });
    yield put(importRoundSuccess(response))
} catch (error) {
    console.log(error)
    yield put(importRoundError(error))
}
}


function* getGames () {
  try {
        const response = yield* call(HTTPAsyncAPIMethodWrapper.get, {
          apiMethod: 'Games',
          payload: {}
        });
      yield put(getGamesSuccess(response))
  } catch (error) {
      console.log(error)
      yield put(getGamesError(error))
  }
}


function* newGame (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.post, {
        apiMethod: 'Games',
        payload: action.payload
      });
    yield put(newGameSuccess(response))
    yield put(getGamesStart())
} catch (error) {
    console.log(error)
    yield put(newGameError(error))
}
}

function* editGame (action: any) {
  try {
      const response = yield* call(HTTPAsyncAPIMethodWrapper.put, {
        apiMethod: 'Games',
        payload: action.payload
      });
    yield put(editGameSuccess(response))
    yield put(getGamesStart())
} catch (error) {
    console.log(error)
    yield put(editGameError(error))
}
}


function* startRoundWatcher () {
    yield takeLatest(startRoundStart.type, startRound)
}

function* endRoundWatcher () {
    yield takeLatest(endRoundStart.type, endRound)
}

function* postPreparationRoundWatcher () {
    yield takeLatest(postPreparationRoundStart.type, postPreparationRound)
}

function* getPreparationRoundWatcher () {
  yield takeLatest(getPreparationRoundStart.type, getPreparationRound)
}

function* exportRoundWatcher () {
  yield takeLatest(exportRoundStart.type, exportRound)
}

function* importRoundWatcher () {
  yield takeLatest(importRoundStart.type, importRound)
}

function* getGamesWatcher () {
  yield takeLatest(getGamesStart.type, getGames)
}

function* newGameWatcher () {
  yield takeLatest(newGameStart.type, newGame)
}

function* editGameWatcher () {
  yield takeLatest(editGameStart.type, editGame)
}


export const ManagementSagas = [
    fork(startRoundWatcher), 
    fork(endRoundWatcher), 
    fork(postPreparationRoundWatcher), 
    fork(getPreparationRoundWatcher), 
    fork(exportRoundWatcher),
    fork(importRoundWatcher),
    fork(getGamesWatcher),
    fork(newGameWatcher),
    fork(editGameWatcher),
  ];
//не забываем добавлять сагу в рут- сагу