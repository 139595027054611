import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Route, Router, Switch } from "react-router-dom";
import BaseWrapper from "./components/BaseWrapper";
import Loader from "./components/Loader";
import ErrorBoundary from "./components/ErrorBoundary";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import routes from "./routes";
import {
  appInitStart,
  getStatusStart,
  //APP_STORE_PATH,
  //isLoadingSelectorFactory,
  menuDataSelector,
  userSelector,
  timerSelector
} from "./store/appSlice";
import history from "./store/history";

export default function AppContainer() {
  
  //const isLoading = useSelector(isLoadingSelectorFactory(APP_STORE_PATH));
  const isLoading = false;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appInitStart());
  }, [dispatch]);

  
  const user = useSelector(userSelector);
  const menuData = useSelector(menuDataSelector);
  const timeStamp = useSelector(timerSelector); // он нужен в BaseWrapper чтобы он обновлялся с изменением timeStamp

  const refreshHeader = () => {
    // TODO: начинает спамить запросами на сервер
    // появилось после привязки таймера к текущему времени
    //dispatch(getStatusStart());
    //console.log('refreshHeader');
  }

  return (
    <Router history={history}>
      <Loader isLoading={isLoading} />
      <BaseWrapper user={user} menudata={menuData} timeStamp={timeStamp}> 
        <Switch>
          <Route exact path="/login" component={Login} />
          {Object.values(routes).map(route => (
            <Route
              key={route.path}
              path={route.path}
              exact={true}
              render={props => {
                const { location } = props;
                const Component = route.component;
                if (user) {
                  refreshHeader();  //тут обновляется таймер/раунд/год при переходе между разделами
                  return (
                    <ErrorBoundary>
                      <Component {...route} {...props} />
                    </ErrorBoundary>
                  )
                }

                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: location }
                    }}
                  />
                  );
              }}
            />
          ))}
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </BaseWrapper>
    </Router>
  );
}
