import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Notification from '../../components/Notification';
//import { Row, Col } from 'react-bootstrap';
import { fetchDataStart, dataSelector, errorSelector } from "./redux/ReportsGraphCompanySlice";
import './reportsGraphCompany.css';
import { isEmpty } from 'lodash';
//import CountrySelect from '../../components/CountrySelect/CountrySelect';
//import ProductTypeSelect from '../../components/ProductTypeSelect/ProductSelect';
import ReportsGraph from './components/ReportsGraph';

const ReportsGraphCompany = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const data = useSelector(dataSelector);
  //const [regionId, setRegionId] = useState(0);
  //const [productType, setPropductType] = useState(0);

  /*useEffect(()=> {
    dispatch(fetchDataStart({regionId, productType}))
  }, [dispatch, regionId, productType])*/

  useEffect(()=> {
    dispatch(fetchDataStart())
  }, [dispatch])
  
  
  return (
    <>
      {/*<Row>
        <Col xs={1} style={{minWidth: '150px'}}>
          <CountrySelect setRegionId={setRegionId} />
        </Col>
        <Col xs={1} style={{minWidth: '150px'}}>
          <ProductTypeSelect setPropductType={setPropductType} />
        </Col>
      </Row>*/}
      <Notification success={false} error={error} />
      {!isEmpty(data) && <ReportsGraph data={data} />}
    </>
  )
};

export default ReportsGraphCompany;