import React, { ReactElement } from "react";
import { Row, Col } from 'react-bootstrap';
import Loader from "../../components/Loader";
import AgreementsTabs from "./components/AgreementsTabs";
import AgreementsHeader from './components/AgreementsHeader';
import AgreementsActionPanel from './components/AgreementsActionPanel';
import Notification from '../../components/Notification';
import './components/agreements.css'


export default function View(props: any): ReactElement {
  const {
    //title, 
    //extraLinks,
    isLoading,
    data,
    selectedRowKeys, 
    setSelectedRowKeys,
    error,
    setFieldType,
    setRegionId,
    activeTab,
    setActiveTab,
    getAgreementsParams,
    params,
    fetchDataParams,
    showEditModal,
    setShowEditModal,
    showDeleteModal,
    editHandler,
    removehandler,
    closeModalHandler,
    editedRow
  } = props;

  return (
    <>
      <div className="assets-content">
        <AgreementsHeader setFieldType={setFieldType} setRegionId={setRegionId} activeTab={activeTab}/>
        <Notification success={false} error={error} />
        <Row>
          <Col sm={9}>
            <AgreementsTabs  
              data={data}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              editHandler={editHandler}
              removehandler={removehandler}
          />
          </Col>
          <Col sm={3}>
            <AgreementsActionPanel 
              getAgreementsParams={getAgreementsParams}
              activeTab={activeTab}
              params={params}
              fetchDataParams={fetchDataParams}
              showEditModal={showEditModal}
              setShowEditModal={setShowEditModal}
              showDeleteModal={showDeleteModal}
              closeModalHandler={closeModalHandler}
              editedRow={editedRow}
            />
          </Col>
        </Row>
      </div>

      <Loader isLoading={isLoading} />
    </>
  );
}
