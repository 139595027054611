import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
import { MINING_STORE_PATH } from "./../Mining.constants";

const MiningSlice = createSlice({
    name: MINING_STORE_PATH,
    initialState: {
        error: null,
        status: APP_STATE.IDLE,
        data: [],
        sendedSuccess: false,
    },
    reducers: {
        fetchMiningTableStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchMiningTableSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, data: action.payload, error: null}
        },
        fetchMiningTableError (store, action) {
            return {...store, status: APP_STATE.IDLE, data: [],
                error: 
                action.payload.errors ? action.payload.errors.toString() : 
                action.payload ? action.payload : 
                'Ошибка сервера'}
        },


        saveMiningItemStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        saveMiningItemSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, sendedSuccess: true, error: null}
        },
        saveMiningItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        closeModalSlice (store) {
            return {...store, sendedSuccess: false}
        },
    }
})

export const { fetchMiningTableStart, fetchMiningTableSuccess, fetchMiningTableError, 
    saveMiningItemStart, saveMiningItemSuccess, saveMiningItemError,
    closeModalSlice } = MiningSlice.actions;
export default MiningSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${MINING_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function dataSelector(state: any) {
    return get(state, `${MINING_STORE_PATH}.data`) || [];
}

export function errorSelector(state: any) {
    return get(state, `${MINING_STORE_PATH}.error`);
}

export function sendedSuccessSelector(state: any) {
    return get(state, `${MINING_STORE_PATH}.sendedSuccess`);
}