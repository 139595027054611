import React, { ReactElement } from "react";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import TechnologiesHeader from './components/TechnologiesHeader';
import TechnologiesTabs from './components/TechnologiesTabs';
import './components/technologies.css'

export default function View(props: any): ReactElement {
  const {
    isLoading,
    data,
    error
  } = props;

  return (
    <>
      <TechnologiesHeader />
      <TechnologiesTabs data={data}/>
      <Loader isLoading={isLoading} />
      <Notification success={false} error={error} />
    </>
  );
}
