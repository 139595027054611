import history from "../store/history";

/**
 * Функция удаляет данные авторизации и перенаправляет на страницу входа
 */
export function setAuthData(response) {
  localStorage.setItem('sozvezdie_game_token', response.result.token);
  localStorage.setItem('sozvezdie_game_role', response.result.role);
  localStorage.setItem('sozvezdie_game_user', JSON.stringify(response.result.user));
  history.push("/");
}


export function resetAuthData() {
  localStorage.removeItem('sozvezdie_game_token');
  localStorage.removeItem('sozvezdie_game_role');
  localStorage.removeItem('sozvezdie_game_user');

  history.go("/login");
}

