import { useSelector } from 'react-redux'
import View from './Technologies.view';
import { IRouterProps } from "../../constants/interfaces";
import {technologiesDataSelector, errorSelector} from './redux/TechnologiesSlice';

export default function Technologies(routerProps: IRouterProps) {
    
    const error = useSelector(errorSelector);
    const data = useSelector(technologiesDataSelector);
    
    return (
        <View 
            {...routerProps}
            data={data}
            error={error}
        />
    )
}
