import React, { ReactElement } from "react";
import CountryPanel from "../../../components/CountryPanel/CountryPanel";


export default function MiningHeader(props: any): ReactElement {
  const {setRegionId } = props;

  return (
      <div className="mining--header">
        <CountryPanel setRegionId={setRegionId} />
      </div>
  );
}
