import React from "react";
import { Select } from 'antd';
import { stage } from '../../constants';
//import type { RadioChangeEvent } from 'antd';
//import { Radio } from 'antd';
import './licenseTypePanel.css';

const { Option } = Select;

export default function LicenseTypePanel(props: any) {

    const { setFieldType } = props;

    const handleChange = (row: { value: string; label: React.ReactNode }) => {
        if (setFieldType) setFieldType(+row.value)
      };
    
    /*const onChange = (e: RadioChangeEvent) => {
      if (setFieldType) setFieldType(+e.target.value);
    };*/

  return (
    <div className="stagePanel">
    {/*<p>Стадия:</p>*/}
    <Select
        labelInValue
        defaultValue={{ value: '0', label: stage[0].title }}
        style={{ width: 120 }}
        onChange={handleChange}
    >
        { 
            stage.map(el => (
                <Option key={el.id} value={el.id}>{el.title}</Option>        
            ))
        }   
    </Select>
    {/*<Radio.Group onChange={onChange} defaultValue={0} className='stagePanel--group'>
        {stage.map(el=>(
            <Radio.Button key={el.id} value={el.id} className='stagePanel--element'>
              {el.title}
            </Radio.Button>      
        ))}
        </Radio.Group>*/}
    </div>
  );
}
