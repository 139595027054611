import React, { useState, useEffect, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRouterProps } from "../../constants/interfaces";
import {
  fetchStart,
  dataSelector,
  errorSelector,
  isLoadingSelector
} from "./redux/LicensingSlice";

import View from "./Licensing.view";

export default function PageOneList(routerProps: IRouterProps): ReactElement {
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [regionId, setRegionId] = useState(0);
  const [fieldType, setFieldType] = useState(0);

  const isLoading : boolean = useSelector(isLoadingSelector);
  const error : boolean = useSelector(errorSelector);
  const data = useSelector(dataSelector);

  useEffect(() => {
    dispatch(fetchStart({regionId, fieldType}));
  }, [dispatch, regionId, fieldType]);


  return (
    <View
      {...routerProps}
      data={data}
      isLoading={isLoading}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      error={error}
      setFieldType={setFieldType}
      setRegionId={setRegionId}
    />
  );
}
