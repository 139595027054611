import { createSlice } from '@reduxjs/toolkit';
import { APP_STATE } from "../../../constants";
import { get } from "lodash";
import { TECHNOLOGIES_STORE_PATH } from "./../Technologies.constants";

const TechnologiesSlice = createSlice({
    name: TECHNOLOGIES_STORE_PATH,
    initialState: {
        error: null,
        status: APP_STATE.IDLE,
        data: [],
        sendedSuccess: false,
    },
    reducers: {
        fetchTechnologiesTableStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchTechnologiesTableSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, data: action.payload, error: null}
        },
        fetchTechnologiesTableError (store, action) {
            return {...store, status: APP_STATE.IDLE, data: [],
                error: 
                action.payload.errors ? action.payload.errors.toString() : 
                action.payload ? action.payload : 
                'Ошибка сервера'}
        },


        fetchTechnologiesDescriptionStart (store, action) {
            return {...store, status: APP_STATE.LOADING, description: null, error: null}
        },
        fetchTechnologiesDescriptionSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, description: action.payload, error: null}
        },
        fetchTechnologiesDescriptionError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: 
                action.payload.errors ? action.payload.errors.toString() : 
                action.payload ? action.payload : 
                'Ошибка сервера'}
        },


        fetchTechnologiesNewsStart (store, action) {
            return {...store, status: APP_STATE.LOADING, error: null}
        },
        fetchTechnologiesNewsSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, news: action.payload, error: null}
        },
        fetchTechnologiesNewsError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: 
                action.payload.errors ? action.payload.errors.toString() : 
                action.payload ? action.payload : 
                'Ошибка сервера'}
        },


        saveTechnologiesItemStart (state, action) {
            return{...state, status: APP_STATE.LOADING, error: null}
        },
        saveTechnologiesItemSuccess (store, action) {
            return {...store, status: APP_STATE.IDLE, sendedSuccess: true, error: null}
        },
        saveTechnologiesItemError (store, action) {
            return {...store, status: APP_STATE.IDLE, error: action.payload?.errors?.toString() || 'Ошибка сервера'}
        },

        clearTechnologies (state) {
            return{...state, status: APP_STATE.LOADING, error: null, news: null, description: null}
        },
        closeModalSlice (store) {
            return {...store, sendedSuccess: false}
        },
    }
})

export const {  fetchTechnologiesTableStart, fetchTechnologiesTableSuccess, fetchTechnologiesTableError,
                saveTechnologiesItemStart, saveTechnologiesItemSuccess, saveTechnologiesItemError,
                fetchTechnologiesDescriptionStart, fetchTechnologiesDescriptionSuccess, fetchTechnologiesDescriptionError,
                fetchTechnologiesNewsStart, fetchTechnologiesNewsSuccess, fetchTechnologiesNewsError, 
                clearTechnologies, closeModalSlice } = TechnologiesSlice.actions;
export default TechnologiesSlice.reducer;
//не забываем редюсер добавить в рут- редюсер

export function isLoadingSelector(state: any) {
    return (
      get(state, `${TECHNOLOGIES_STORE_PATH}.status`) === APP_STATE.LOADING
    );
}

export function technologiesDataSelector(state: any) {
    return get(state, `${TECHNOLOGIES_STORE_PATH}.data`) || [];
}

export function technologiesDescriptionSelector(state: any) {
    return get(state, `${TECHNOLOGIES_STORE_PATH}.description`)
}

export function technologiesNewsSelector(state: any) {
    return get(state, `${TECHNOLOGIES_STORE_PATH}.news`)
}

export function errorSelector(state: any) {
    return get(state, `${TECHNOLOGIES_STORE_PATH}.error`);
}

export function sendedSuccessSelector(state: any) {
    return get(state, `${TECHNOLOGIES_STORE_PATH}.sendedSuccess`);
}